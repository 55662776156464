var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: { title: "Datensatz zuordnen" }
      }),
      _c("div", { staticClass: "card__layout" }, [
        _c(
          "div",
          { staticClass: "card__item" },
          [
            _c("span", { staticClass: "box__title" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.title) +
                  ": " +
                  _vm._s(_vm.formatName) +
                  " Datensatz zuordnen "
              )
            ]),
            _c("br"),
            _c("br"),
            _vm.fileName
              ? _c("div", { staticClass: "info-header" }, [
                  _c("div", [_vm._v("Dateiname")]),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(_vm.fileName))])
                ])
              : _vm._e(),
            _vm.foreignName
              ? _c("div", { staticClass: "info-header" }, [
                  _c("div", [_vm._v("Kundenname")]),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(_vm.foreignName))])
                ])
              : _vm._e(),
            _vm.foreignVorname
              ? _c("div", { staticClass: "info-header" }, [
                  _c("div", [_vm._v("Kundenvorname")]),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(_vm.foreignVorname))])
                ])
              : _vm._e(),
            _vm.foreignBirthName
              ? _c("div", { staticClass: "info-header" }, [
                  _c("div", [_vm._v("Kundengeburtsname")]),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(_vm.foreignBirthName))])
                ])
              : _vm._e(),
            _vm.foreignAddress
              ? _c("div", { staticClass: "info-header" }, [
                  _c("div", [_vm._v("Kundenanschrift")]),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(_vm.foreignAddress))])
                ])
              : _vm._e(),
            _vm.foreignBirthDate
              ? _c("div", { staticClass: "info-header" }, [
                  _c("div", [_vm._v("Kundengeburtsdatum")]),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(_vm.foreignBirthDate))])
                ])
              : _vm._e(),
            _c("InputToggleSwitch", {
              attrs: {
                label: _vm.formatName + " Versicherungsnummer übernehmen",
                inLineLabel: ""
              },
              model: {
                value: _vm.transferInsuranceNumber,
                callback: function($$v) {
                  _vm.transferInsuranceNumber = $$v
                },
                expression: "transferInsuranceNumber"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "card__item" },
          [
            _c("InputField", {
              attrs: { label: "MSC Interne Nummer" },
              model: {
                value: _vm.mscInternalId,
                callback: function($$v) {
                  _vm.mscInternalId = $$v
                },
                expression: "mscInternalId"
              }
            }),
            _c(
              "BaseButton",
              {
                attrs: { isSecondary: "" },
                on: { click: _vm.searchInsurances }
              },
              [_vm._v(" Versicherungssuche ")]
            ),
            _c(
              "BaseButton",
              {
                attrs: {
                  isPrimary: "",
                  disabled: _vm.assignMahnungAllowed || !_vm.mscInternalId
                },
                on: { click: _vm.buttonKundengesellZuordnen }
              },
              [
                _vm._v(
                  " " + _vm._s(_vm.formatName) + " KundenGesellNr. zuordnen "
                )
              ]
            ),
            _c("hr"),
            _c(
              "BaseButton",
              {
                attrs: { isPrimary: "" },
                on: { click: _vm.buttonNewCustomer }
              },
              [_vm._v(" Neuer Kunde ")]
            ),
            _c("InputField", {
              attrs: { label: "MSC Kundenname" },
              model: {
                value: _vm.customerNameSearch,
                callback: function($$v) {
                  _vm.customerNameSearch = $$v
                },
                expression: "customerNameSearch"
              }
            }),
            _c(
              "BaseButton",
              { attrs: { isPrimary: "" }, on: { click: _vm.searchCustomer } },
              [_vm._v(" Suchen ")]
            )
          ],
          1
        ),
        _c("div", { staticClass: "card__item" }, [
          _vm.cause
            ? _c("div", { staticClass: "info-header" }, [
                _c("div", [_vm._v("Fehlerursache")]),
                _vm._v(" "),
                _c("div", [_vm._v(_vm._s(_vm.cause))])
              ])
            : _vm._e(),
          _vm.damageNumber
            ? _c("div", { staticClass: "info-header" }, [
                _c("div", [_vm._v("Schaden-Nr.")]),
                _vm._v(" "),
                _c("div", [_vm._v(_vm._s(_vm.damageNumber))])
              ])
            : _vm._e(),
          _vm.customerGesellId
            ? _c("div", { staticClass: "info-header" }, [
                _c("div", [_vm._v("KundenGesellNr.")]),
                _vm._v(" "),
                _c("div", [_vm._v(_vm._s(_vm.customerGesellId))])
              ])
            : _vm._e(),
          _vm.foreignPersonId
            ? _c("div", { staticClass: "info-header" }, [
                _c("div", [_vm._v("FremdPersonNr.")]),
                _vm._v(" "),
                _c("div", [_vm._v(_vm._s(_vm.foreignPersonId))])
              ])
            : _vm._e(),
          _vm.sparteNr
            ? _c("div", { staticClass: "info-header" }, [
                _c("div", [_vm._v("Sparten-Nr.")]),
                _vm._v(" "),
                _c("div", [_vm._v(_vm._s(_vm.sparteNr))])
              ])
            : _vm._e(),
          _vm.vtgSparte
            ? _c("div", { staticClass: "info-header" }, [
                _c("div", [_vm._v("VTG-Sparte")]),
                _vm._v(" "),
                _c("div", [_vm._v(_vm._s(_vm.vtgSparte))])
              ])
            : _vm._e(),
          _vm.insuranceId
            ? _c("div", { staticClass: "info-header" }, [
                _c("div", [_vm._v("Versicherungsnummer")]),
                _vm._v(" "),
                _c("div", [_vm._v(_vm._s(_vm.insuranceId))])
              ])
            : _vm._e()
        ])
      ]),
      _vm.insuranceRows.length || _vm.customerRows.length
        ? _c(
            "div",
            { staticClass: "box__container" },
            [
              _vm.insuranceRows.length
                ? _c("BigTable", {
                    attrs: {
                      title: "SmartMSC Vorschläge (Versicherungen)",
                      headers: _vm.insuranceHeaders,
                      rows: _vm.insuranceRows,
                      rowsPerPage: 10
                    },
                    on: {
                      "click-kundennr": _vm.openCustomerNewTab,
                      "action-CONTRACT": _vm.actionContract,
                      "action-UNITE": _vm.actionUnite
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "datensatzZuordnen",
                          fn: function(row) {
                            return [
                              !_vm.assignMahnungAllowed
                                ? _c("PhArrowFatRight", {
                                    staticClass: "clickable",
                                    attrs: { size: 24 },
                                    on: {
                                      click: function($event) {
                                        return _vm.kundengesellZuordnen(
                                          undefined,
                                          row.id
                                        )
                                      }
                                    }
                                  })
                                : _vm._e()
                            ]
                          }
                        },
                        {
                          key: "street",
                          fn: function(row) {
                            return [
                              _c(
                                "div",
                                { class: { green: row.street.green } },
                                [_vm._v(" " + _vm._s(row.street.text) + " ")]
                              )
                            ]
                          }
                        },
                        {
                          key: "plz",
                          fn: function(row) {
                            return [
                              _c("div", { class: { green: row.plz.green } }, [
                                _vm._v(" " + _vm._s(row.plz.text) + " ")
                              ])
                            ]
                          }
                        },
                        {
                          key: "ort",
                          fn: function(row) {
                            return [
                              _c("div", { class: { green: row.ort.green } }, [
                                _vm._v(" " + _vm._s(row.ort.text) + " ")
                              ])
                            ]
                          }
                        },
                        {
                          key: "birthdate",
                          fn: function(row) {
                            return [
                              _c(
                                "div",
                                { class: { green: row.birthdate.green } },
                                [_vm._v(" " + _vm._s(row.birthdate.text) + " ")]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      281205544
                    )
                  })
                : _vm._e(),
              _vm.customerRows.length
                ? _c("BigTable", {
                    attrs: {
                      title: "SmartMSC Vorschläge (Kunden)",
                      headers: _vm.customerHeaders,
                      rows: _vm.customerRows,
                      rowsPerPage: 10,
                      rowId: "kundennr"
                    },
                    on: {
                      "click-kundennr": _vm.openCustomerNewTab,
                      "action-CONTRACT": _vm.actionContract
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "datensatzZuordnen",
                          fn: function(row) {
                            return [
                              _vm.customerGesellId
                                ? _c("PhArrowFatRight", {
                                    staticClass: "clickable",
                                    attrs: { size: 24 },
                                    on: {
                                      click: function($event) {
                                        return _vm.kundengesellZuordnen(
                                          row.kundennr
                                        )
                                      }
                                    }
                                  })
                                : _vm._e()
                            ]
                          }
                        },
                        {
                          key: "name",
                          fn: function(row) {
                            return [
                              _c("div", { class: { green: row.name.green } }, [
                                _vm._v(" " + _vm._s(row.name.text) + " ")
                              ])
                            ]
                          }
                        },
                        {
                          key: "vorname",
                          fn: function(row) {
                            return [
                              _c(
                                "div",
                                { class: { green: row.vorname.green } },
                                [_vm._v(" " + _vm._s(row.vorname.text) + " ")]
                              )
                            ]
                          }
                        },
                        {
                          key: "street",
                          fn: function(row) {
                            return [
                              _c(
                                "div",
                                { class: { green: row.street.green } },
                                [_vm._v(" " + _vm._s(row.street.text) + " ")]
                              )
                            ]
                          }
                        },
                        {
                          key: "plz",
                          fn: function(row) {
                            return [
                              _c("div", { class: { green: row.plz.green } }, [
                                _vm._v(" " + _vm._s(row.plz.text) + " ")
                              ])
                            ]
                          }
                        },
                        {
                          key: "ort",
                          fn: function(row) {
                            return [
                              _c("div", { class: { green: row.ort.green } }, [
                                _vm._v(" " + _vm._s(row.ort.text) + " ")
                              ])
                            ]
                          }
                        },
                        {
                          key: "birthdate",
                          fn: function(row) {
                            return [
                              _c(
                                "div",
                                { class: { green: row.birthdate.green } },
                                [_vm._v(" " + _vm._s(row.birthdate.text) + " ")]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      3118669032
                    )
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm.uniteInsurance
        ? _c("UniteContracts", {
            attrs: { insurance: _vm.uniteInsurance },
            on: {
              close: function($event) {
                _vm.uniteInsurance = null
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }