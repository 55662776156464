<template>
  <div class="persoenliche-daten-view">
    <PageHeaderTitleNavigation
      title="Person"
      :subtitle="ansprechpartner.name"
    />

    <HinweiseUndFehler :errors="warnings" :hints="hints" />
    <StepperForm
      ref="stepperForm"
      stepType="tab"
      stepperName="customer-data"
      :stepperMediator="getStepperMediator"
      :selectedStepKey="selectedStepKey"
      :selectedSubstepKey="selectedSubstepKey"
      :hasFertig="false"
      :key="selectedStepKey"
      @set-step="setStepByKey"
      @set-substep="setSubstepByKey"
    >
      <template #contentTemplate>
        <div v-if="selectedStepKey === 'stammdaten'" class="box__container">
          <div class="box__title">Persönliche Daten</div>

          <InputToggleSwitch
            v-if="isContactsPersonView && hasSyncUser"
            v-model="ansprechpartner.syncActive"
            label="Synchronisation"
            @change="addAnsprechpartnerDataEdited('ansprechpartner')"
          />

          <InputField
            v-if="!isContactsPersonView"
            label="Unternummer"
            isComponentHalfSize
            v-model="ansprechpartner.unternr"
            :disabled="true"
            @change="addAnsprechpartnerDataEdited('ansprechpartner')"
          />
          <InputField
            v-if="!isContactsPersonView"
            label="Mitarbeiternummer (zum Login ins smartMSC / App)"
            isComponentHalfSize
            v-model="ansprechpartner.mitarbeiternr"
            :disabled="true"
            @change="addAnsprechpartnerDataEdited('ansprechpartner')"
          />

          <ComboBox
            label="Verwende als"
            isComponentHalfSize
            :renderDanger="!ansprechpartner.typ"
            :values="typs"
            v-model="ansprechpartner.typ"
            v-if="!isContactsPersonView"
            :disabled="disabled || isVerwendeAlsDisabled"
            @change="
              addAnsprechpartnerDataEditedCombo(
                'ansprechpartner',
                'typ',
                $event
              )
            "
          />
          <InputCheckboxItem
            v-if="!isContactsPersonView"
            v-model="ansprechpartner.darfNichtBeraten"
            label="Geschäftsführung darf nicht beraten"
            :disabled="disabled || !isGeschaeftsfuehrung"
            @input="addAnsprechpartnerDataEdited('ansprechpartner')"
          />

          <InputCheckboxItem
            v-if="ansprechpartner.maklerVVAdvisor"
            v-model="ansprechpartner.advisor"
            label="Advisor (Vermögensverwaltung)"
            :disabled="disabled"
            @input="addAnsprechpartnerDataEdited('ansprechpartner')"
          />

          <InputCheckboxItem
            v-model="ansprechpartner.vertrauensvollerMitarbeiter"
            v-if="!isContactsPersonView"
            label="Vertrauenswürdige Person"
            :disabled="disabled || internUser"
            @input="addAnsprechpartnerDataEdited('vertrauensvollerMitarbeiter')"
          />

          <InputCheckboxItem
            v-if="ansprechpartner.maklerVVVermoegensverwalter"
            v-model="ansprechpartner.vermoegensverwalter"
            label="Vermögensverwalter"
            :disabled="disabled"
            @input="addAnsprechpartnerDataEdited('ansprechpartner')"
          />

          <InputCheckboxItem
            v-if="ansprechpartner.maklerRiskManager"
            v-model="ansprechpartner.riskmanager"
            label="Risk-Manager"
            :disabled="disabled"
            @input="addAnsprechpartnerDataEdited('ansprechpartner')"
          />

          <InputField
            label="Position"
            v-model="ansprechpartner.beschreibung"
            isComponentHalfSize
            :disabled="disabled"
            @change="addAnsprechpartnerDataEdited('ansprechpartner')"
          ></InputField>
          
          <ComboBox
            label="Anrede"
            isComponentHalfSize
            :values="brokerDataConfig.anrede"
            v-model="ansprechpartner.anrede"
            :disabled="disabled || isGeschaeftsfuehrungFieldsDisabled"
            @change="
              addAnsprechpartnerDataEditedCombo(
                'ansprechpartner',
                'anrede',
                $event
              )
            "
          />
          <ComboBox
            label="Titel"
            isComponentHalfSize
            :values="brokerDataConfig.titles"
            v-model="ansprechpartner.titel"
            :disabled="disabled || isGeschaeftsfuehrungFieldsDisabled"
            @change="
              addAnsprechpartnerDataEditedCombo(
                'ansprechpartner',
                'titel',
                $event
              )
            "
          />
          <InputField
            label="Vorname"
            isComponentHalfSize
            v-model="ansprechpartner.vorname"
            :disabled="disabled || isGeschaeftsfuehrungFieldsDisabled"
            @change="addAnsprechpartnerDataEdited('ansprechpartner')"
          />
          <InputField
            data-field-target="lastNameField"
            label="Name"
            isComponentHalfSize
            v-model="ansprechpartner.name"
            :renderDanger="!ansprechpartner.name"
            :disabled="disabled || isGeschaeftsfuehrungFieldsDisabled"
            @change="addAnsprechpartnerDataEdited('ansprechpartner')"
          />
          <DatePickerField
            label="Geburtsdatum"
            isValueAsString
            isComponentHalfSize
            v-model="ansprechpartner.gebDatum"
            :disabled="disabled && !isEditableWK"
            @change="addAnsprechpartnerDataEdited('ansprechpartner')"
          />
          <InputField
            label="Geburtsname"
            isComponentHalfSize
            v-model="ansprechpartner.gebName"
            :disabled="disabled"
            @change="addAnsprechpartnerDataEdited('ansprechpartner')"
          />
          <InputField
            v-if="!isContactsPersonView"
            label="Geburtsort"
            isComponentHalfSize
            v-model="ansprechpartner.gebOrt"
            :disabled="disabled && !isEditableWK"
            @change="addAnsprechpartnerDataEdited('ansprechpartner')"
          />
          <ComboBox
            v-if="!isContactsPersonView"
            label="Geburtsland"
            isComponentHalfSize
            :values="brokerDataConfig.countries"
            v-model="ansprechpartner.gebLand"
            :disabled="disabled"
            @change="
              addAnsprechpartnerDataEditedCombo(
                'ansprechpartner',
                'gebLand',
                $event
              )
            "
          />
          <InputField
            v-if="!isContactsPersonView"
            label="Finanzamt"
            isComponentHalfSize
            v-model="ansprechpartner.finanzamt"
            :disabled="disabled"
            @change="addAnsprechpartnerDataEdited('ansprechpartner')"
          />
          <InputField
            v-if="!isFA && !isContactsPersonView"
            label="Sozialversicherungsnummer"
            v-model="ansprechpartner.sozialVersNummer"
            isComponentHalfSize
            :disabled="disabled"
            @change="addAnsprechpartnerDataEdited('ansprechpartner')"
          ></InputField>
          <InputField
            v-if="!isContactsPersonView"
            label="Gewerbenummer"
            v-model="ansprechpartner.gewerbenummer"
            isComponentHalfSize
            :disabled="disabled"
            @change="addAnsprechpartnerDataEdited('ansprechpartner')"
          ></InputField>
          <InputField
            v-if="!isContactsPersonView"
            label="Gut beraten ID"
            v-model="ansprechpartner.gutBeratenId"
            isComponentHalfSize
            :disabled="disabled"
            @change="addAnsprechpartnerDataEdited('ansprechpartner')"
          ></InputField>
          <InputCheckboxItem
            v-model="ansprechpartner.gestorben"
            label="Person ist verstorben"
            :disabled="disabled"
            @input="addAnsprechpartnerDataEdited('ansprechpartner')"
          />
          <DatePickerField
            label="Sterbedatum"
            isValueAsString
            isComponentHalfSize
            v-model="ansprechpartner.sterbeDatum"
            :disabled="disabled"
            @change="addAnsprechpartnerDataEdited('ansprechpartner')"
          />
          <InputCheckboxItem
            v-if="!isContactsPersonView"
            v-model="ansprechpartner.zugangFreigeschaltet"
            label="Zugang freigeschaltet"
            :disabled="disabled"
            @input="addAnsprechpartnerDataEdited('ansprechpartner')"
          />
          <InputCheckboxItem
            v-if="!isContactsPersonView"
            v-model="ansprechpartner.istAktiv"
            label="Ist aktiv"
            @input="addAnsprechpartnerDataEdited('ansprechpartner')"
            :disabled="!ansprechpartner.showAktiv || disabled"
          />
          <InputCheckboxItem
            v-if="!isContactsPersonView && isRestrictionToBrokerConfigEnabled"
            v-model="ansprechpartner.checkNetworkRestrictionDisregard"
            label="Zugriff auf MSC aus Homeoffice / unterwegs erlaubt"
            @input="addAnsprechpartnerDataEdited('ansprechpartner')"
            :disabled="disabled"
          />
          <InputField
            v-if="!isContactsPersonView"
            label="Abw. Registernr 34 d/e"
            v-model="ansprechpartner.registriernr34de"
            isComponentHalfSize
            :disabled="disabled"
            @change="addAnsprechpartnerDataEdited('ansprechpartner')"
          ></InputField>
          <InputField
            v-if="!isContactsPersonView"
            label="Abw. Registernr 34 f/h"
            v-model="ansprechpartner.registriernr34fh"
            isComponentHalfSize
            :disabled="disabled"
            @change="addAnsprechpartnerDataEdited('ansprechpartner')"
          ></InputField>
          <!-- <MaklerPicture :isAnsprechpartner="true" :ansprechPartnerId="personId"></MaklerPicture> -->
          <AnsprechpartnerPicture :personId="personId" :disabled="disabled" />
        </div>
        <div v-else-if="selectedStepKey === 'kontakt'" class="box__container">
          <div class="box__title">Kontaktdaten</div>
          <InputField
            label="E-Mail"
            type="email"
            isComponentHalfSize
            v-model="ansprechpartner.email"
            :disabled="disabled"
            validateUntouched
            @change="addAnsprechpartnerDataEdited('ansprechpartner')"
          />
          <InputField
            label="Telefon"
            isComponentHalfSize
            v-model="ansprechpartner.telefon"
            :disabled="disabled"
            @change="addAnsprechpartnerDataEdited('ansprechpartner')"
            inputmode="tel"
          />
          <InputField
            label="Telefon Privat"
            isComponentHalfSize
            v-model="ansprechpartner.telefonPrivat"
            :disabled="disabled"
            @change="addAnsprechpartnerDataEdited('ansprechpartner')"
            inputmode="tel"
          />
          <div>
            <div>
              <InputField
                label="Mobil"
                isComponentHalfSize
                v-model="ansprechpartner.mobil"
                :disabled="disabled"
                @change="addAnsprechpartnerDataEdited('ansprechpartner')"
                inputmode="tel"
              />
            </div>
            <div>
              <InputToggleSwitch
                v-model="ansprechpartner.isMobilePublic"
                label="für Kundenkontakt verwenden"
                :disabled="disabled"
                v-fc-tooltip="'Dürfen meine Kunden diese Mobilnummer sehen?'"
                @input="saveMobileNumberPreference($event)"
              />
            </div>
          </div>
          <InputField
            label="Fax"
            isComponentHalfSize
            v-model="ansprechpartner.fax"
            :disabled="disabled"
            @change="addAnsprechpartnerDataEdited('ansprechpartner')"
            inputmode="tel"
          />
        </div>
        <div
          v-else-if="selectedStepKey === 'postanschrift'"
          class="box__container"
        >
          <div class="box__title">Postanschrift</div>
          <InputField
            label="Straße, HNr."
            isComponentHalfSize
            v-model="ansprechpartner.strasseHnrPost"
            :disabled="disabled"
            @change="addAnsprechpartnerDataEdited('ansprechpartner')"
          />
          <InputField
            label="PLZ"
            isComponentHalfSize
            v-model="ansprechpartner.plzPost"
            :disabled="disabled"
            @change="addAnsprechpartnerDataEdited('ansprechpartner')"
          />
          <InputField
            label="Ort"
            isComponentHalfSize
            v-model="ansprechpartner.ortPost"
            :disabled="disabled"
            @change="addAnsprechpartnerDataEdited('ansprechpartner')"
          />
          <InputField
            label="Postfach"
            isComponentHalfSize
            v-model="ansprechpartner.postfachPost"
            :disabled="disabled"
            @change="addAnsprechpartnerDataEdited('ansprechpartner')"
          />
          <InputField
            label="PLZ für Postfach"
            isComponentHalfSize
            v-model="ansprechpartner.plzPostfachPost"
            :disabled="disabled"
            @change="addAnsprechpartnerDataEdited('ansprechpartner')"
          />
          <ComboBox
            label="Land"
            isComponentHalfSize
            :values="brokerDataConfig.countries"
            v-model="ansprechpartner.landPost"
            :disabled="disabled"
            @change="
              addAnsprechpartnerDataEditedCombo(
                'ansprechpartner',
                'landPost',
                $event
              )
            "
          />
          <template v-if="isEditable">
            <BaseButton
              isSecondary
              @click="copyToPrivate"
              :disabled="loading"
              label="Adresse als Privatanschrift übernehmen"
            />
            <BaseButton
              isSecondary
              @click="copyMaklerAdresse"
              :disabled="loading"
              label="Firmenadresse als Postadresse übernehmen"
            />
          </template>
        </div>
        <div
          v-else-if="selectedStepKey === 'privatanschrift'"
          class="box__container"
        >
          <div class="box__title">Privatanschrift</div>
          <InputField
            label="Straße, HNr."
            isComponentHalfSize
            v-model="ansprechpartner.strasseHnrPrivat"
            :disabled="disabled && !isEditableWK"
            @change="addAnsprechpartnerDataEdited('ansprechpartner')"
          />
          <InputField
            label="PLZ"
            isComponentHalfSize
            v-model="ansprechpartner.plzPrivat"
            :disabled="disabled && !isEditableWK"
            @change="addAnsprechpartnerDataEdited('ansprechpartner')"
          />
          <InputField
            label="Ort"
            isComponentHalfSize
            v-model="ansprechpartner.ortPrivat"
            :disabled="disabled && !isEditableWK"
            @change="addAnsprechpartnerDataEdited('ansprechpartner')"
          />
          <InputField
            label="Postfach"
            isComponentHalfSize
            v-model="ansprechpartner.postfachPrivat"
            :disabled="disabled && !isEditableWK"
            @change="addAnsprechpartnerDataEdited('ansprechpartner')"
          />
          <InputField
            label="PLZ für Postfach"
            isComponentHalfSize
            v-model="ansprechpartner.plzPostfachPrivat"
            :disabled="disabled && !isEditableWK"
            @change="addAnsprechpartnerDataEdited('ansprechpartner')"
          />
          <ComboBox
            label="Land"
            isComponentHalfSize
            :values="brokerDataConfig.countries"
            v-model="ansprechpartner.landPrivat"
            :disabled="disabled && !isEditableWK"
            @change="
              addAnsprechpartnerDataEditedCombo(
                'ansprechpartner',
                'landPrivat',
                $event
              )
            "
          />
        </div>
        <div
          v-else-if="selectedStepKey === 'paketanschrift'"
          class="box__container"
        >
          <div class="box__title">Paketanschrift</div>
          <InputField
            label="Straße, HNr."
            isComponentHalfSize
            v-model="ansprechpartner.strasseHnrPaket"
            :disabled="disabled"
            @change="addAnsprechpartnerDataEdited('ansprechpartner')"
          />
          <InputField
            label="PLZ"
            isComponentHalfSize
            v-model="ansprechpartner.plzPaket"
            :disabled="disabled"
            @change="addAnsprechpartnerDataEdited('ansprechpartner')"
          />
          <InputField
            label="Ort"
            isComponentHalfSize
            v-model="ansprechpartner.ortPaket"
            :disabled="disabled"
            @change="addAnsprechpartnerDataEdited('ansprechpartner')"
          />
          <InputField
            label="Postfach"
            isComponentHalfSize
            v-model="ansprechpartner.postfachPaket"
            :disabled="disabled"
            @change="addAnsprechpartnerDataEdited('ansprechpartner')"
          />
          <InputField
            label="PLZ für Postfach"
            isComponentHalfSize
            v-model="ansprechpartner.plzPostfachPaket"
            :disabled="disabled"
            @change="addAnsprechpartnerDataEdited('ansprechpartner')"
          />
          <ComboBox
            label="Land"
            isComponentHalfSize
            :values="brokerDataConfig.countries"
            v-model="ansprechpartner.landPaket"
            :disabled="disabled"
            @change="
              addAnsprechpartnerDataEditedCombo(
                'ansprechpartner',
                'landPaket',
                $event
              )
            "
          />
        </div>

        <div v-else-if="selectedStepKey === 'legitimation'">
          <div class="box__container">
            <div class="box__title">Legitimationsurkunde</div>
          </div>

          <LegitimationImages
            :legitimation="ansprechpartner.legitimation || {}"
            :mitarbeiterId="ansprechpartner.personId"
            :isMitarbeiterEditable="ansprechpartner.editable || isEditableWK"
            isMitarbeiter
            @read-image-data="setReadImageDataAnsprechpartner($event)"
            @addAnsprechpartnerDataEdited="
              addAnsprechpartnerDataEdited('legitimation')
            "
          />
          <div class="box__container">
            <ComboBox
              :labelClass="depoteroeffnungenLabelClass"
              label="Art der Urkunde"
              isComponentHalfSize
              :values="ansprechpartner.artUrkundeValues"
              v-model="ansprechpartner.legitimation.legitimType"
              :disabled="disabled && !isEditableWK"
              @change="saveAnsprechpartner($event)"
            
            />
            <InputField
              label="Ausweisnummer"
              :labelClass="depoteroeffnungenLabelClass"
              isComponentHalfSize
              v-model="ansprechpartner.legitimation.legitimNumber"
              :disabled="disabled && !isEditableWK"
              @change="addAnsprechpartnerDataEdited('ansprechpartner')"
            />
            <DatePickerField
              label="Gültig bis"
              :labelClass="depoteroeffnungenLabelClass"
              isValueAsString
              isComponentHalfSize
              v-model="ansprechpartner.legitimation.validTo"
              :disabled="disabled && !isEditableWK"
              @change="addAnsprechpartnerDataEdited('ansprechpartner')"
            />
            <InputField
              label="Ausstellungsbehörde"
              :labelClass="depoteroeffnungenLabelClass"
              isComponentHalfSize
              v-model="ansprechpartner.legitimation.authority"
              :disabled="disabled && !isEditableWK"
              @change="addAnsprechpartnerDataEdited('ansprechpartner')"
            />
            <DatePickerField
              label="Ausstellungsdatum"
              :labelClass="depoteroeffnungenLabelClass"
              isValueAsString
              isComponentHalfSize
              v-model="ansprechpartner.legitimation.dateOfIssue"
              :disabled="disabled && !isEditableWK"
              @change="addAnsprechpartnerDataEdited('ansprechpartner')"
            />
            <ComboBox
              label="Staatsangehörigkeit"
              :labelClass="depoteroeffnungenLabelClass"
              isComponentHalfSize
              :values="brokerDataConfig.nationalities"
              v-model="ansprechpartner.nationality"
              :disabled="disabled && !isEditableWK"
              @change="
                addAnsprechpartnerDataEditedCombo(
                  'ansprechpartner',
                  'nationality',
                  $event
                )
              "
            />
          </div>
        </div>
        <div v-else-if="selectedStepKey === 'tickets'" class="box__container">
          <div class="box__title">Tickets</div>
          <InputCheckboxItem
            v-model="ansprechpartner.ticketErlaubt"
            label="Ticketempfänger"
            :disabled="disabled"
            @input="addAnsprechpartnerDataEdited('ansprechpartner')"
          />
          <InputCheckboxItem
            v-model="ansprechpartner.moveTicket"
            label="Bearbeiter ändern"
            :disabled="disabled"
            @input="addAnsprechpartnerDataEdited('ansprechpartner')"
          />
        </div>
        <div v-else-if="selectedStepKey === 'facsimile'">
          <Facsimile :personId="ansprechpartner.personId" suffix="STEMPEL" />
          <Facsimile :personId="ansprechpartner.personId" />
        </div>
        <ZusaetzlicheInfos
          v-else-if="selectedStepKey === 'zusaetzlicheInfos'"
          isEditable
          v-model="ansprechpartner.zusaetzlicheInfos"
          :zusaetzlicheInfosValues="brokerDataConfig.zusaetzlicheInfosValues"
          @change="addAnsprechpartnerDataEdited('ansprechpartner')"
        />
        <AnsprechpartnerZugriff
          v-else-if="selectedStepKey === 'zugriff'"
          @change="
            addAnsprechpartnerDataEdited('ansprechpartner', {
              zugriffVertraege: $event,
            })
          "
        />
        <div v-else-if="selectedStepKey && selectedStepKey.startsWith('menu-config')">
          <MenuConfig :currentPath="menuCurrentPath" :configContext="MENU_CONFIG_CONTEXT" />
        </div>
        <div v-else-if="selectedStepKey === 'weitere_zugriffsrechte'">
          <AdditionalPermissions :unternr="ansprechpartner.unternr" v-if="ansprechpartner.unternr" userPermissionTarget="MAKLER_PERSONEN"/>
        </div>
        <div v-else-if="selectedStepKey === 'access-management'">
          <TwoFactorSMSOption :unternr="ansprechpartner.unternr" v-if="ansprechpartner.unternr"/>
        </div>
      </template>
    </StepperForm>
    <DataConflictModal ref="dataConflictModal" @confirm="confirmDataConflictAnsprechpartner($event)" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { StepperMediator } from "@/components/stepper/utils";
import CORE_TYPES from "@/store/core/types";
import BROKERDATA_TYPES from "@/store/brokerData/types";
import ANSPRECHPARTNER_TYPES from "@/store/ansprechpartner/types";
import MENU_CONFIG_TYPES from '@/store/menuConfig/types';

import OptionMenu from "@/components/core/option-menu/OptionMenu.vue";
import PageHeaderTitleNavigation from "@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue";
import StepperForm from "@/components/stepper/StepperForm.vue";
import HinweiseUndFehler from "@/components/core/HinweiseUndFehler.vue";
import ComboBox from "@/components/core/forms/ComboBox.vue";
import BaseButton from "@/components/core/BaseButton.vue";
import InputField from "@/components/core/forms/InputField.vue";
import DatePickerField from "@/components/core/forms/date-picker2/DatePickerField.vue";
import InputCheckboxItem from "@/components/core/forms/checkbox/InputCheckBoxItem.vue";
import AnsprechpartnerZugriff from "@/components/broker/makler-ansprechpartner/AnsprechpartnerZugriff.vue";
import { ROLES, VIEW_ROLES } from "@/router/roles";
import Facsimile from "@/components/brokerData/Facsimile.vue";
import axios from "axios";
import { makeQueryParam } from "@/helpers/utils-helper";
import AnsprechpartnerPicture from "@/views/gesellschaft/ansprechpartner/AnsprechpartnerPicture.vue";
import LegitimationImages from "@/components/persoenliche-daten/stammdaten/LegitimationImages.vue";
import stammdatenMixin from "@/components/persoenliche-daten/stammdaten/stammdaten-mixin";
import persoenlicheDatenMixin from "@/components/persoenliche-daten/persoenliche-daten-mixin";
import { maxDate, email, } from '@/mixins/validator/rules'
import dayjs from "dayjs";
import DataConflictModal from '@/components/persoenliche-daten/stammdaten/DataConflictModal.vue';
import MenuConfig from '@/views/menu/MenuConfig.vue';
import AdditionalPermissions from '@/views/core/configs/brokerPermissions/AdditionalPermissions.vue';
import TwoFactorSMSOption from '@/components/settings/TwoFactorSMSOption.vue';
import { BASE_AXIOS_CONFIG as config } from "@/configs/http-request-interceptor";

import { SUBSTEP_KEY_SEPARATOR, EINSTELLUNGEN_MITARBEITEREBENE_LABEL, findMenuConfigStep, } from '@/menu/menu-config-utils';
import InputToggleSwitch from "@/components/core/forms/InputToggleSwitch.vue";
import ZusaetzlicheInfos from '@/views/gesellschaft/ansprechpartner/ZusaetzlicheInfos.vue';

const MENU_CONFIG_CONTEXT = '__MITARBEITER__';

const SERVICE_PATH = "/brokerData";

export default {
  mixins: [stammdatenMixin, persoenlicheDatenMixin],
  props: {
    personIdParam: {
      type: String,
    },
  },
  components: {
    InputToggleSwitch,
    OptionMenu,
    PageHeaderTitleNavigation,
    StepperForm,
    ZusaetzlicheInfos,
    HinweiseUndFehler,
    ComboBox,
    InputField,
    DatePickerField,
    BaseButton,
    InputCheckboxItem,
    AnsprechpartnerZugriff,
    Facsimile,
    AnsprechpartnerPicture,
    LegitimationImages,
    DataConflictModal,
    MenuConfig,
    AdditionalPermissions,
    TwoFactorSMSOption,
  },
  data() {
    return {
      ansprechpartnerData: {},
      edited: false,
      loading: false,
      menuConfigSteps: [],
      MENU_CONFIG_CONTEXT,
      ansprechpartner: {},
    };
  },
  computed: {
    ...mapGetters({
      isIntern: CORE_TYPES.GETTERS.IS_INTERN,
      isFK: CORE_TYPES.GETTERS.IS_FK,
      isFA: CORE_TYPES.GETTERS.IS_FA,
      internUser: CORE_TYPES.GETTERS.ORIGINAL_USER_IS_INTERN,
      istFPPlusMaster: CORE_TYPES.GETTERS.IS_FPP_PLUS_MASTER,
      storedAnsprechpartner: BROKERDATA_TYPES.GETTERS.GET_ANSPRECHPARTNER_BY_ID,
      brokerDataConfig: BROKERDATA_TYPES.GETTERS.GET_BROKER_DATA_CONFIG,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      tempUserPicture: ANSPRECHPARTNER_TYPES.GETTERS.GET_TEMP_USER_PICTURE_DATA,
      isMenuConfiguredFn: MENU_CONFIG_TYPES.GETTERS.IS_MENU_CONFIGURED,
      parentsMenuConfigFn: MENU_CONFIG_TYPES.GETTERS.PARENTS_MENU_CONFIG,
    }),
    isEditable() {
      return !!this.ansprechpartner?.editable || this.personId === 'newPerson' || this.$route.params.personId === 'newPerson';
    },
    isEditableWK() {
      return !!this.ansprechpartner?.editableWK;
    },    
    disabled() {
      return this.loading || !this.isEditable;
    },
    hasSyncUser() {
      return this.hasRoles([ROLES.HAT_SYNC_USER]);
    },
    isVerwendeAlsDisabled() {
      const isBrokerView = this.hasRoles([VIEW_ROLES.VIEW_BROKER_ONLY, VIEW_ROLES.VIEW_BROKER_AS_BROKER]);
      const { maklerRechtlichVerantwortlicher } = this.ansprechpartner || {};
      return this.isFK && isBrokerView && !this.istFPPlusMaster && maklerRechtlichVerantwortlicher;
    },
    isGeschaeftsfuehrung() {
      return this.ansprechpartner?.typ === "Geschäftsführung";
    },
    isGeschaeftsfuehrungFieldsDisabled() {
      if (this.personIdParam === "newPerson") {
        return false;
      }
      const isBrokerView = this.hasRoles([VIEW_ROLES.VIEW_BROKER_ONLY, VIEW_ROLES.VIEW_BROKER_AS_BROKER]);
      return this.isGeschaeftsfuehrung && this.isFK && isBrokerView && !this.istFPPlusMaster;
    },
    selectedStepKey() {
      return this.$route.query.stepKey;
    },
    selectedSubstepKey() {
      return this.$route.query.substepKey;
    },
    getStepperMediator() {
      return new StepperMediator(JSON.parse(JSON.stringify(this.steps)));
    },
    hints() {
      return (
        this.selectedStepKey &&
        this.ansprechpartner &&
        this.ansprechpartner.hinweise &&
        this.ansprechpartner.hinweise[this.selectedStepKey]
      );
    },
    warnings() {
      return (this.ansprechpartner &&  this.ansprechpartner.errors && this.ansprechpartner.errors.legitimation) || []     
    },
    isSavable() {
      return (
        this.ansprechpartner &&
        !!this.ansprechpartner.name &&
        !!this.ansprechpartner.typ
      );
    },
    personId() {
      if (this.personIdParam && this.personIdParam === "newPerson") {
        return this.ansprechpartner && this.ansprechpartner.personId;
      }
      return this.personIdParam;
    },
    isRestrictionToBrokerConfigEnabled() {
      return this.hasRoles(ROLES.IS_RESTRICTION_TO_BROKER_CONFIG_ENABLED);
    },
    typs() {
      if (this.isContactsPersonView) {
        return ['Kontakt']
      }
      return this.brokerDataConfig.typs;
    },
    isContactsPersonView() {
      return this.$route.meta.isPerson;
    },
    menuCurrentPath() {
      return this.selectedSubstepKey?.split(SUBSTEP_KEY_SEPARATOR)?.pop?.();
    },
    steps() {
      return [
        {
          stepKey: "stammdaten",
          label: "Stammdaten",
          totalProgress: 1,
        },
        {
          stepKey: "kontakt",
          label: this.isContactsPersonView ? 'Kontaktdaten' : "Kontakt",
          totalProgress: 1,
        },
        {
          stepKey: "postanschrift",
          label: "Postanschrift",
          totalProgress: 1,
        },
        {
          stepKey: "privatanschrift",
          label: "Privatanschrift",
          totalProgress: 1,
        },
        ...(!this.isContactsPersonView ? [{
          stepKey: "paketanschrift",
          label: "Paketanschrift",
          totalProgress: 1,
        }] : []),
        {
          stepKey: "zusaetzlicheInfos",
          label: "Zusätzliche Informationen",
          totalProgress: 1,
        },
        {
          stepKey: "legitimation",
          label: "Legitimation",
          totalProgress: 1,
        },
        {
          stepKey: "tickets",
          label: "Tickets",
          totalProgress: 1,
          hidden: true,
        },
        {
          stepKey: "facsimile",
          label: "Facsimile",
          totalProgress: 1,
          hidden: true,
        },
        ...(!this.isContactsPersonView ? [{
          stepKey: "zugriff",
          label: "Zugriff Verträge",
          totalProgress: 1,
          hidden: true,
        }] : []),
        ...this.menuConfigSteps,
        {
          stepKey: "weitere_zugriffsrechte",
          label: "Weitere Zugriffsrechte",
          totalProgress: 1,
          hidden: true,
        },
        {
          stepKey: "access-management",
          label: "Zugangsverwaltung",
          totalProgress: 1,
          hidden: true,
        },
      ]
    },
  },
  watch: {
    personIdParam: {
      handler(val) {
        if (val) {
          this.getAnsprechpartner(this.personId).then(() =>
            this.configureSteps()
          );
        }
      },
      immediate: true,
    },
    storedAnsprechpartner: {
      handler(val) {
        if (!val) {
          return;
        }
        this.ansprechpartner = JSON.parse(JSON.stringify(val));
        this.configureSteps();

        if (!this.ansprechpartner.legitimation) {
          this.ansprechpartner.legitimation = {}
        }
        if (!this.ansprechpartner.legitimation.legitimType) {
          this.ansprechpartner.legitimation.legitimType = 'Personalausweis';
        }
      },
      immediate: true,
    },
  },
  methods: {
    async setStepByKey(stepKey) {
      if (this.selectedStepKey === stepKey) {
        return;
      }

      await this.setSubstepByKey({
        stepKey: stepKey,
        substepKey: this.findFirstSubstep(stepKey),
      });
    },
    findFirstSubstep(stepKey) {
      const currentStep = this.getStepperMediator.getCurrentStep(stepKey);

      if(currentStep?.substeps?.length > 0) {
        return this.getStepperMediator.findNextSubstepKey(currentStep, -1);
      }

      return undefined;
    },
    async setSubstepByKey(params) {
      const { stepKey, substepKey, } = params;
      const { selectedStepKey, selectedSubstepKey, } = this;

      if(stepKey === selectedStepKey && substepKey === selectedSubstepKey) {
        return;
      }

      await this.doSave();

      await this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          stepKey,
          substepKey,
        }
      });

      this.configureSteps();
    },
    async configureSteps() {
      await this.configureMenuConfig();

      const isUserUnternr = this.hasRoles([ROLES.IS_USER_UNTERNR]);
      const isBrokerAsIntern = this.hasRoles([VIEW_ROLES.VIEW_BROKER_AS_INTERN]);

      this.showStep("tickets", this.ansprechpartner?.maklerTicketErlaubt);
      this.showStep("facsimile", this.ansprechpartner?.maklerRechtlichVerantwortlicher);
      this.showStep("zugriff", !isUserUnternr);
      this.showStep("access-management", !isUserUnternr && !!this.ansprechpartner.unternr);
      this.showStep("weitere_zugriffsrechte", !isUserUnternr && isBrokerAsIntern && !!this.ansprechpartner.unternr);
    },
    showStep(stepKey, show = false) {
      const step = this.steps.find((s) => s.stepKey === stepKey);
      if (step) {
        step.hidden = !show;
        this.$refs.stepperForm.setStepHidden(stepKey, !show);
      }
    },
    async saveAnsprechpartner(type){
      this.$set(this.ansprechpartner.legitimation, 'legitimType', type);
      await this.doSave(true);
    },
    doSave(forceSaving = false) {
      if ((this.edited || forceSaving) && this.isSavable) {
        this.loading = true;
        const params = makeQueryParam({ personId: this.personId });

        let path = `${process.env.VUE_APP_API}/${SERVICE_PATH}/saveAnsprechpartner?${params}`
        if (this.isContactsPersonView) {
          path = `${process.env.VUE_APP_API}/ContactFilters/savePerson?${params}`
        }

        const isNewPerson = !this.ansprechpartner?.personId;
        return axios
          .put(path, this.ansprechpartner, config)
          .then((response) => {
            if (response.status === 200 && response.data) {
              this.$store.commit(
                BROKERDATA_TYPES.MUTATIONS.SAVE_MAKLER_ANSPRECHPARTNER_SUCCESS,
                { ansprechpartner: response.data }
              );
              this.edited = false;
              this.saveTempUserPicture(response.data);

              if (isNewPerson) {
                this.configureSteps();
              }
            }
          })
          .finally(() => (this.loading = false));
      }
    },
    saveTempUserPicture(ansprechpartner) {
      if (this.tempUserPicture && ansprechpartner.personId) {
        const payload = {
          personId: ansprechpartner.personId,
          file: this.tempUserPicture.file,
        };
        this.$store.dispatch(
          ANSPRECHPARTNER_TYPES.ACTIONS.SAVE_USER_PICTURE,
          payload
        );
      }
    },
    saveMobileNumberPreference(value) {
      const { personId } = this;

      if(!personId || value == null) {
        return;
      }

      let url = `${process.env.VUE_APP_API}/customerContactPerson/setMobileNumberDisplayToCustomerPreference`;

      axios({
        method: "post",
        url: url,
        data: {
          personId,
          value,
        }
      })
        .then((response) => {
          if (response.status === 200 && response.data) {
            console.log("Mobile number visible for customer updated successfully");
          }
        }, (error) => {
          console.log("Error while setting Mobile number visible for customer update: \n" + error);
        });
    },
    copyToPrivate() {
      const payload = {
        ansprechpartner: {
          ...this.ansprechpartner,
          strasseHnrPrivat: this.ansprechpartner.strasseHnrPost,
          plzPrivat: this.ansprechpartner.plzPost,
          ortPrivat: this.ansprechpartner.ortPost,
          postfachPrivat: this.ansprechpartner.postfachPost,
          plzPostfachPrivat: this.ansprechpartner.plzPostfachPost,
          landPrivat: this.ansprechpartner.landPost,
        },
      };
      this.edited = true;
      this.$store.commit(
        BROKERDATA_TYPES.MUTATIONS.SAVE_MAKLER_ANSPRECHPARTNER_SUCCESS,
        payload
      );
    },
    copyMaklerAdresse() {
      this.loading = true;
      axios
        .get(
          `${process.env.VUE_APP_API}/${SERVICE_PATH}/copyMaklerAdresse`,
          config
        )
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.edited = true;
            const payload = {
              ansprechpartner: {
                ...this.ansprechpartner,
                strasseHnrPost: response.data.strasseHnrPost,
                plzPost: response.data.plzPost,
                ortPost: response.data.ortPost,
                landPost: response.data.landPost,
              },
            };
            this.$store.commit(
              BROKERDATA_TYPES.MUTATIONS.GET_ANSPRECHPARTNER_BY_ID_SUCCESS,
              payload
            );
          }
        })
        .finally(() => (this.loading = false));
    },
    addAnsprechpartnerDataEdited(group, data) {
      this.edited = true;
      const payload = {
        ...this.ansprechpartner,
        ...data,
      };
      this.$store.commit(
        BROKERDATA_TYPES.MUTATIONS.SAVE_MAKLER_ANSPRECHPARTNER_SUCCESS,
        { ansprechpartner: payload }
      )
    },
    addAnsprechpartnerDataEditedCombo(group, field, value) {
      if (!(group in this)) return;

      this[group][field] = value;
      this.addAnsprechpartnerDataEdited(group);
    },
    getAnsprechpartner(personId) {
      this.loading = true;
      const servicePath = this.isContactsPersonView ? 'ContactFilters' : SERVICE_PATH

      return this.$store.dispatch(BROKERDATA_TYPES.ACTIONS.GET_ANSPRECHPARTNER_BY_ID, { personId, servicePath })
        .finally(() => (this.loading = false));
    },

    openDataConflictModalIfExistsConflictAnsprechpartner(data) {
      const dataConflictBySection = {};

      const personalDataAddress = {
        title: this.ansprechpartner?.titel,
        lastName: this.ansprechpartner?.name,
        firstName: this.ansprechpartner?.vorname,
        street: this.ansprechpartner?.strasseHnrPost,
        zipCode: this.ansprechpartner?.plzPost,
        city: this.ansprechpartner?.ortPost,
        country: this.ansprechpartner?.landPost,
      }

      const personalDataBirth = {
        nationality: this.ansprechpartner?.gebLand,
        dayOfBirth: this.ansprechpartner?.gebDatum,
        nameAtBirth: this.ansprechpartner?.gebName,
        placeOfBirth: this.ansprechpartner?.gebOrt,
      }

      const legitimation = {
        legitimNumber: this.ansprechpartner?.legitimation?.legitimNumber,
        authority: this.ansprechpartner?.legitimation?.authority,
        dateOfIssue: this.ansprechpartner?.legitimation?.dateOfIssue,
        validTo: this.ansprechpartner?.legitimation?.validTo,
      }

      if (this.isEditable) {
        dataConflictBySection['personalDataAddress'] = this.checkConflicts(personalDataAddress, {
          ...data.personalausweis,
          title: (this.customerDataConfig?.genders?.indexOf(data.personalausweis?.title) >= 0 ? data.personalausweis?.title : null),
          zip: data.personalausweis?.zipCode,
          country: this.findCountry(this.customerDataConfig?.nationalityToCountry?.[data.personalausweis?.nationality?.trim()]) || '',
        });
        dataConflictBySection['personalDataBirth'] = this.checkConflicts(personalDataBirth, {
          ...data.personalausweis,
          dayOfBirth: this.dateStringFromResponse(data.personalausweis?.dateOfBirth),
          nameAtBirth: data.personalausweis?.lastName,
          nationality: this.findNationality((data.personalausweis?.nationality || "").toLowerCase()),
        });
      }

      dataConflictBySection['legitimation'] = this.checkConflicts(legitimation, {
        ...data.personalausweis,
        legitimNumber: data.personalausweis?.idNumber,
        dateOfIssue: this.dateStringFromResponse(data.personalausweis?.date),
        validTo: this.dateStringFromResponse(data.personalausweis?.validUntil),
      });

      const dataConflict = Object.keys(dataConflictBySection)
        .reduce((result, key) => {
          return result.concat(dataConflictBySection[key].map(item => ({ ...item, section: key, })));
        }, []);
      if(dataConflict?.length) {
        this.openDataConflictModal(dataConflict);
      }
    },

    setReadImageDataAnsprechpartner(data) {
      this.openDataConflictModalIfExistsConflictAnsprechpartner(data);

      // personalDataAddress
      const country = this.findCountry(data.personalausweis?.nationality) || '';
      this.ansprechpartner.name = this.ansprechpartner?.name || data.personalausweis?.lastName;
      this.ansprechpartner.vorname = this.ansprechpartner?.vorname || data.personalausweis?.firstName;
      this.ansprechpartner.strasseHnrPost = this.ansprechpartner?.strasseHnrPost || data.personalausweis?.street;
      this.ansprechpartner.plzPost = this.ansprechpartner?.plzPost || data.personalausweis?.zipCode;
      this.ansprechpartner.ortPost = this.ansprechpartner?.ortPost || data.personalausweis?.city;
      this.ansprechpartner.landPost = this.ansprechpartner?.landPost || country;

      // personalDataBirth
      const nationality = this.findNationality((data.personalausweis?.nationality || "").toLowerCase()) || '';
      this.ansprechpartner.gebLand = this.ansprechpartner.gebLand || nationality;
      this.ansprechpartner.gebDatum = this.ansprechpartner?.gebDatum || this.dateStringFromResponse(data.personalausweis?.dateOfBirth);
      this.ansprechpartner.gebName = this.ansprechpartner?.gebName || data.personalausweis?.lastName;
      this.ansprechpartner.gebOrt = this.ansprechpartner?.gebOrt || data.personalausweis?.placeOfBirth;

      // legitimation
      this.ansprechpartner.legitimation.legitimType = this.ansprechpartner?.legitimation?.legitimType || "Personalausweis";
      this.ansprechpartner.legitimation.legitimNumber = this.ansprechpartner?.legitimation?.legitimNumber || data.personalausweis?.idNumber;
      this.ansprechpartner.legitimation.authority = this.ansprechpartner?.legitimation?.authority || data.personalausweis?.authority;
      this.ansprechpartner.legitimation.dateOfIssue = this.ansprechpartner?.legitimation?.dateOfIssue || this.dateStringFromResponse(data.personalausweis?.date);
      this.ansprechpartner.legitimation.validTo = this.ansprechpartner?.legitimation?.validTo || this.dateStringFromResponse(data.personalausweis?.validUntil);

      this.addAnsprechpartnerDataEdited('ansprechpartner');
    },
    confirmDataConflictAnsprechpartner(data) {
      const ansprechpartnerKeys = {
        title: 'titel',
        lastName: 'name',
        firstName: 'vorname',
        street: 'strasseHnrPost',
        zipCode: 'plzPost',
        city: 'ortPost',
        country: 'landPost',
        gebLand: 'nationality',
        dayOfBirth: 'gebDatum',
        nameAtBirth: 'gebName',
        placeOfBirth: 'gebOrt',
      }

      data.forEach(item => {
        if (['personalDataBirth', 'personalDataAddress'].includes(item.section)) {
          this.$set(this.ansprechpartner, ansprechpartnerKeys[item.key], item.newValue);
        }

        if (item.section === 'legitimation') {
          this.$set(this.ansprechpartner[item.section], [item.key], item.newValue);
        }

        this.addAnsprechpartnerDataEdited('ansprechpartner');
      });

      setTimeout(() => this.doSave());
    },
    async configureMenuConfig() {
      const isTestUser = this.hasRoles([ROLES.TEST_USER]);
      const isMenuConfigStepsVisible = !!this.ansprechpartner?.personId
        && (!!this.ansprechpartner?.unternr || this.ansprechpartner?.unternr === 0)
        && !this.hasRoles([ROLES.IS_USER_UNTERNR]) 
        && this.hasRoles([VIEW_ROLES.VIEW_BROKER_ONLY, VIEW_ROLES.VIEW_BROKER_AS_BROKER]);

      if (!isTestUser || !isMenuConfigStepsVisible || this.isMenuConfiguredFn(MENU_CONFIG_CONTEXT)) {
        return;
      }

      try {
        await this.$store.dispatch(MENU_CONFIG_TYPES.ACTIONS.CONFIGURE_MENU_CONFIG, { 
          configContext: MENU_CONFIG_CONTEXT,
          unternr: this.ansprechpartner.unternr,
          isPermissionConfigurable: true,
        });

        const stepKey = 'menu-config';
        const menuConfigSteps = [ findMenuConfigStep(MENU_CONFIG_CONTEXT, stepKey, EINSTELLUNGEN_MITARBEITEREBENE_LABEL) ];
        this.$set(this, 'menuConfigSteps', menuConfigSteps);
      } catch(e) {
        // empty block
      }
    },
  },
  validators: {
    legitimation: {
        dateOfIssue: [maxDate(dayjs().add(1, 'day'), "Das Ausstellungsdatum muss in der Vergangenheit liegen.")],
    },
    ansprechpartner: {
      email: [email()],
    },
  },

  beforeCreate() {
    this.$store.commit(
      ANSPRECHPARTNER_TYPES.MUTATIONS.GET_USER_PICTURE_SUCCESS,
      {}
    );
    this.$store.commit(
      ANSPRECHPARTNER_TYPES.MUTATIONS.SET_TEMP_USER_PICTURE,
      null
    );
    this.$store.commit(
      BROKERDATA_TYPES.MUTATIONS.GET_ANSPRECHPARTNER_BY_ID_SUCCESS,
      { ansprechpartner: { legitimation: { legitimType: 'Personalausweis'} } }
    );
  },
  mounted() {
    if (!this.selectedStepKey) {
      this.setStepByKey('stammdaten');
    }
    this.$store.dispatch(BROKERDATA_TYPES.ACTIONS.GET_BROKER_DATA_CONFIG);
    this.$store.commit(BROKERDATA_TYPES.MUTATIONS.RESET_FACSIMILE);
  },
  destroyed() {
    this.$store.dispatch(MENU_CONFIG_TYPES.ACTIONS.RESET_ALL, { configContext: MENU_CONFIG_CONTEXT, });
  },
  async beforeRouteLeave(to, from, next) {
    if (
      this.edited &&
      this.ansprechpartner &&
      (!this.ansprechpartner.typ || !this.ansprechpartner.name)
    ) {
      this.$confirmModal({
        title: "Es fehlen Angaben",
        message: `
          Ihre Änderungen werden nicht gespeichert.
          <br> Es fehlen folgende Angaben: 
          ${this.ansprechpartner.name ? "" : "<br> - Name der Person"}
          ${this.ansprechpartner.typ ? "" : "<br> - Typ der Person"}
          <br>
          <br>Möchten Sie die Seite trotzdem verlassen?`,
        labelButtonCancel: "Zurück",
        labelButtonConfirm: "Seite verlassen",
      }).then(() => next());
    } else {
      await this.doSave();
      next();
    }
  },
};
</script>