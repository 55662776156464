var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showAddImage
    ? _c(
        "div",
        [
          _c(
            "BaseToolbarButton",
            {
              directives: [
                {
                  name: "tippy",
                  rawName: "v-tippy",
                  value: { arrow: true },
                  expression: "{ arrow: true }"
                }
              ],
              staticClass: "mr-0 tb-image-button",
              attrs: {
                title: "Image",
                icon: _vm.icon,
                disabled: _vm.disabled,
                content: "Bild"
              }
            },
            [
              _c("input", {
                staticClass: "input-hidden",
                attrs: {
                  disabled: _vm.disabled,
                  type: "file",
                  accept: "image/x-png,image/jpeg,image/gif"
                },
                on: {
                  change: function($event) {
                    return _vm.onAddImage($event)
                  }
                }
              })
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }