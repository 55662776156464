<template>
  <div class="input-chips__container" 
    :class="{
      'one-line': isOneRow,
      'is-dropdown-active': isShowDropdown,
    }"
  >
    <label class="input-forms__label-container flex-strech">
      <div class="label-content" v-if="label">
        <span class="pr-3">{{label}}</span>
        <span class="color-danger" v-if="warning">{{warning}}</span>
      </div>
      
      <div :class="{'forms__input--half-size': this.isComponentHalfSize}" ref="inputContainerEl">
        <div class="input-chips__input-container" @click="onClickChipsContainer" :class="{'disabled': disableInput,}">
          <div class="input-chips__chips-container">
            <span class="input-chips__chips unselectable" v-for="(selected, index) of valueFiltered" :key="index" @click="clickItem(selected)" 
              :class="{
                'clickable': isClickableCheck(selected),
                'disabled': disableInput,
              }"
            >
              <a v-if="isClickableCheck(selected)" 
                :class="{ 'color-danger': isRedFlagged(selected)}">
                {{selected && selected.label}}
              </a>
              <span v-else 
                :class="{'color-danger': isRedFlagged(selected)}">
                {{selected && selected.label}}
              </span>
              <ph-x-circle class="clickable ml-1 del-icon" :size="16" 
                v-if="selected && showRemoveIcon && isRemovable(selected.value)" 
                @click.prevent.stop="removeItem(selected)" />
            </span>
            <input class="input-chips__input" 
              ref="inputText"
              type="text"
              :value="inputText" 
              :disabled="disableInput"
              @input="onInputText" 
              @focus="onFocus"
              @blur="onBlur"
              @keyup.prevent.enter="onEnter"
              @keydown.tab="onEnter"
              @keyup.prevent.up="showDropdown"
              @keyup.prevent.down="showDropdown"
              @keyup.prevent.delete="onDelete">
          </div>
          <button v-if="showToggle" 
            type="button"
            tabindex="-1"
            class="input-chips__toggle-dropdown btn-clear"
            :class="{'clickable': !disableInput}"
            :disabled="disableInput"
            @blur="onBlur"
            @click="toggleDropdown">
            <ph-caret-down :size="16" />
          </button>
        </div>

        <div v-if="isShowDropdown" ref="dropdownContainerEl" class="input-chips__dropdown-container-wrapper unselectable">
          <GhostLoading v-if="loading">
            <Block type="form-input" />
            <Block type="form-input" />
            <Block type="form-input" />
          </GhostLoading>

          <ul v-if="!loading" class="input-chips__dropdown-container">
            <li class="input-chips__dropdown-item" 
              v-for="(available, index) in availableOptionsFiltered" 
              @mousemove="onHover(index)"
              @click="onClickDropDownItem(available)"
              :class="{
                'clickable': !disableInput,
                'input-chips__dropdown-item--selected': index === dropdownSelectedIndex,
                }"
              :key="available.value + '-' + index"
              :tabindex="index+1"
            >
              {{available.label}}
            </li>
            <li class="input-chips__dropdown-item" v-if="noData && !supportCustomValue" tabindex="0">
              Keine daten
            </li>
            <li class="input-chips__dropdown-item" v-if="noData && supportCustomValue"
              @mousemove="onHover(0)"
              @click="onClickDropDownItem({
                label: inputText,
                value: inputText
              })"
              :class="{ 
                'clickable': !disableInput,
                'input-chips__dropdown-item--selected': !disableInput
              }"
              :key="inputText"
              tabindex="0"
            >
              {{inputText}}
            </li>
          </ul>
        </div>
      </div>
    </label>
  </div>
</template>

<script>
import { PhXCircle, PhCaretDown } from 'phosphor-vue'
import BrowserSupport from '@/browser-support';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import Block from '@/components/core/loading/ghost-loading/Block.vue';

const CHIPS_CONTAINER_SELECTOR = '.input-chips__container';
const CHIPS_INPUT_CONTAINER_SELECTOR = '.input-chips__input-container';
const CHIPS_TOGGLE_DROPDOWN_SELECTOR = '.input-chips__toggle-dropdown';
const CHIPS_DROPDOWN_WRAPPER_SELECTOR = '.input-chips__dropdown-container-wrapper';
const CHIPS_DROPDOWN_SCROLL_SELECTOR = '.input-chips__dropdown-container';
const CHIPS_DROPDOWN_ITEM_SELECTOR = '.input-chips__dropdown-item';

const MIN_DROP_LIST_WIDTH = 240;
const MAX_DROP_LIST_HEIGHT = 400;
const BOX_SHADOW_SIZE = 8;

const WHEEL_EVENT_NAME = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';
const WHEEL_EVENT_OPTIONS = BrowserSupport.supportsPassive ? { passive: false, cancelable: false, } : false;
const WHEEL_EVENT_OPTIONS_PASSIVE = BrowserSupport.supportsPassive ? { passive: true, cancelable: false, } : false;

const IGNORE_MODAL_ESC_FOR_CHIPS_CLASS = 'ignore-modal-esc-for-chips';

function eventCoord(event, coord) {
  let eventCoords = event;

  if(event.targetTouches && event.targetTouches.length) {
    eventCoords = event.targetTouches[0];
  } else if(event.changedTouches && event.changedTouches.length) {
    eventCoords = event.changedTouches[0];
  }

  return eventCoords[coord];
}

export default {
  props: {
    availableOptions: { 
      type: Array,
      default: () => []
    },
    filterAvailableOptions: { // always show avaliable option list or filter by the input typed term
      type: Boolean,
      default: true
    },
    showRemoveIcon: {
      type: Boolean,
      default: true,
    },
    label: {
    },
    value: {
      type: Array,
      default: () => []
    },
    supportCustomValue: {
      type: Boolean,
      default: false,
    },
    isOneRow: {
      type: Boolean,
      default: false,
    },
    notRemovableItem: { 
      type: String | Number,
      default: ''
    },
    showToggle: {
      type: Boolean,
      default: false,
    },
    warning: {
      type: String,
      default: ''
    },
    disableInput: {
      type: Boolean,
      default: false,
    },
    isComponentHalfSize: {
      type: Boolean,
      default: false
    },
    isClickable: {
      type: Function,
    },
    uniqueSelection: {
      type: Boolean,
      default: false
    },
    maxItemsVisible: {
      type: Number,
      default: 0
    },
    // TODO: This flag can be improved
    redFlagWhenDifferentLabelValue: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      inputText: '',
      inputTextPrev: null,
      dropdownSelectedIndex: -1,
      removeLastSelectionOnNextDeleteEvent: false,
      isShowDropdown: false,
      touchScroll: {
        lastClientY: null,
      },
    }
  },
  computed: {
    valueFiltered() {
      if (this.maxItemsVisible) {
        let result = [...this.value].slice(0, this.maxItemsVisible).filter(a => a.value !== '...')
        if (this.value.length >= this.maxItemsVisible) {
          result = result
            .concat({
              value: '...',
              label: '...',
              type: 'MORE',
            })
        } else {
          result = result.filter(a => a.value !== '...')
        }
        return result
      }
      return this.value
    },
    availableOptionsFiltered() {
      let availableOptions = this.availableOptions;
      if (this.uniqueSelection) {
        availableOptions = availableOptions.filter(item => !this.value.find(v => v.value == item.value));
      }
      if (!this.inputText || !this.filterAvailableOptions) {
        return availableOptions
      }
      return availableOptions.filter(aval => {
        return aval.label && aval.label.toLowerCase().includes(this.inputText.toLowerCase())
      })
    },
    noData() {
      return !this.availableOptionsFiltered?.length
    }
  },
  methods: {
    isRedFlagged(selected) {
      if (!this.redFlagWhenDifferentLabelValue) {
        return false
      }

      return !selected.email && selected.label !== selected.value
    },
    selectItem(selectedItem) {
      const newSelected = this.value ? [ ...this.value, selectedItem ] : [selectedItem] 
      this.inputText = null;
      this.dropdownSelectedIndex = -1;
      this.removeLastSelectionOnNextDeleteEvent = false;
      this.$emit('input', newSelected)
      this.$emit('addItem', selectedItem);
    },
    onEnter() {
      if (this.availableOptionsFiltered?.length > 0 
        && this.dropdownSelectedIndex >= 0 
        && this.dropdownSelectedIndex < this.availableOptionsFiltered.length) {
        this.selectItem(this.availableOptionsFiltered[this.dropdownSelectedIndex])
      } else if (this.supportCustomValue && this.inputText) {
        this.selectItem({
          label: this.inputText,
          value: this.inputText
        });
      }

      this.hideDropdown();
    },
    onClickDropDownItem(selected) {
      this.selectItem(selected)
      this.hideDropdown();
    },
    removeItem(selected) {
      if (!this.value) {
        return
      }
      const foundList = this.value.filter(item => item.value !== selected.value)
      this.$emit('removeItem', selected)
      this.$emit('input', foundList)
    },
    onClickChipsContainer(event) {
      const { target } = event;
      const toggleDropdownEl = this.$el.querySelector(CHIPS_TOGGLE_DROPDOWN_SELECTOR);
      if (target === toggleDropdownEl 
        || target?.closest?.(CHIPS_TOGGLE_DROPDOWN_SELECTOR) === toggleDropdownEl) {
        event.preventDefault();
        return;
      }

      this.focusOnInput();
    },
    onInputText(event) {
      this.inputText = event.target.value

      if (this.disableInput) {
        this.inputText = this.inputTextPrev;
      }
      if (this.inputText) {
        this.removeLastSelectionOnNextDeleteEvent = false
      }

      this.dropdownSelectedIndex = -1;

      if (this.inputText || this.availableOptionsFiltered?.length > 0) {
        this.showDropdown();
      } else {
        this.hideDropdown();
      }
      this.repositionDropdown();

      this.$emit('inputText', this.inputText)
      this.inputTextPrev = this.inputText;
    },
    onFocus() {
      this.removeLastSelectionOnNextDeleteEvent = false;

      if (this.inputText || this.availableOptionsFiltered?.length > 0) {
        this.showDropdown();
      } else {
        this.hideDropdown();
      }
      this.repositionDropdown();
    },
    onBlur(event) {
      if (!this._isElementOnComponentContext(event.relatedTarget)) {
        this.hideDropdown();
      }
    },
    onDelete() {
      if (!this.value) {
        return
      }
      if (this.removeLastSelectionOnNextDeleteEvent && this.isRemovable(this.value?.value)) {
        const newList = [...this.value]
        newList.pop();
        this.$emit('input', newList)
      }
      if (!this.inputText) {
        this.removeLastSelectionOnNextDeleteEvent = true
      }
    },
    onArrowNavigation(event) {
      if (!this.availableOptionsFiltered?.length) {
        return
      }

      const arrowNavigationInner = up => {
        event.preventDefault();
        event.stopPropagation();

        this.focusOnInput();

        const { dropdownSelectedIndex } = this;
        const availableOptionsCount = this.availableOptionsFiltered.length;

        if (up) {
          const prev = dropdownSelectedIndex - 1;
          this.dropdownSelectedIndex = prev < 0 ? availableOptionsCount - 1 : prev;
        } else {
          const next = dropdownSelectedIndex + 1;
          this.dropdownSelectedIndex = next === availableOptionsCount ? 0 : next;
        }

        const { dropdownContainerEl } = this.$refs;
        const selectedItemEl = dropdownContainerEl?.querySelectorAll(`${CHIPS_DROPDOWN_ITEM_SELECTOR}`)[this.dropdownSelectedIndex];
        this._scrollToListOption(selectedItemEl, up);
      };

      const keyPressed = event.key || event.keyCode;
      switch(keyPressed) {
        case 'ArrowUp':
        case 38:
          arrowNavigationInner(true);
          break;
        case 'ArrowDown':
        case 40:
          arrowNavigationInner(false);
          break;
      }
    },
    onHover(index) {
      this.dropdownSelectedIndex = index
    },
    isRemovable(id) {
      if (this.disableInput) {
        return false;
      }

      return !this.notRemovableItem || id !== this.notRemovableItem;
    },
    toggleDropdown() {
      if (this.isShowDropdown) {
        this.hideDropdown();
      } else { 
        this.showDropdown();
      }
    },
    showDropdown() {
      if (this.disableInput || this.isShowDropdown) return; // ignores when it is disabled / already shown

      this.isShowDropdown = true;

      this.$nextTick(() => {
        const { dropdownContainerEl, } = this.$refs;
        if(!dropdownContainerEl) return;

        // add global classes
        document.body.classList.add(IGNORE_MODAL_ESC_FOR_CHIPS_CLASS);

        // add events
        const dropdownScrollEl = dropdownContainerEl.querySelector(CHIPS_DROPDOWN_SCROLL_SELECTOR);
        dropdownScrollEl.addEventListener('DOMMouseScroll', this.keepOnlyDropdownListScrollable, WHEEL_EVENT_OPTIONS);
        dropdownScrollEl.addEventListener(WHEEL_EVENT_NAME, this.keepOnlyDropdownListScrollable, WHEEL_EVENT_OPTIONS);

        window.addEventListener('touchstart', this.onTouchScrollStart, WHEEL_EVENT_OPTIONS_PASSIVE);
        window.addEventListener('touchmove', this.keepOnlyDropdownListScrollable, WHEEL_EVENT_OPTIONS);
        window.addEventListener('scroll', this.repositionDropdown, true);
        window.addEventListener('resize', this.repositionDropdown);

        document.addEventListener('click', this.handleGlobalClick);
        document.addEventListener('keydown', this.onEscPress);
        document.addEventListener('keydown', this.onArrowNavigation);
        document.addEventListener('keypress', this.focusOnInput);

        this._moveListToBody();
        requestAnimationFrame(() => this._positionList());
      });
    },
    repositionDropdown() {
      if (this.isShowDropdown) {
        requestAnimationFrame(() => this._positionList());
      }
    },
    _moveListToBody() {
      const { dropdownContainerEl, } = this.$refs;
      if(dropdownContainerEl.parentNode !== document.body) {
        document.body.appendChild(dropdownContainerEl);
      }
    },
    _positionList() {
      if (!this.isShowDropdown) return;

      const { inputContainerEl, dropdownContainerEl, } = this.$refs;
      const inputBounding = inputContainerEl.getBoundingClientRect();

      const screenWidth = window.innerWidth;
      const screenHeight = visualViewport.height || window.innerHeight;
      const pageY = window.scrollY;
      const remainingSizeOnBottom = screenHeight - inputBounding.bottom - BOX_SHADOW_SIZE;
      const remainingSizeOnTop = inputBounding.top - BOX_SHADOW_SIZE;
      const isOutOfScreen = inputBounding.bottom < 0 || (screenHeight - inputBounding.top) < 0;
      if (isOutOfScreen) {
        this.hideDropdown();
        return;
      }

      // set list element size
      const width = inputBounding.width >= MIN_DROP_LIST_WIDTH ? inputBounding.width : MIN_DROP_LIST_WIDTH;
      const maxHeight = (() => {
        if ((MAX_DROP_LIST_HEIGHT + BOX_SHADOW_SIZE) <= remainingSizeOnBottom || (MAX_DROP_LIST_HEIGHT + BOX_SHADOW_SIZE) <= remainingSizeOnTop) {
          return MAX_DROP_LIST_HEIGHT;
        } else {
          return Math.max(Math.floor(remainingSizeOnBottom), Math.floor(remainingSizeOnTop));
        }
      })();
      dropdownContainerEl.style.cssText = `
        width: ${width}px;
        max-height: ${maxHeight}px;
        top: -9999px;
        left: -9999px;
      `;

      // set list element x and y position
      const positionX = (() => {
        const width = dropdownContainerEl.offsetWidth;
        const remainingSizeOnRight = screenWidth - inputBounding.left;
        if(remainingSizeOnRight >= width) { // is there enough space remaining on left?
          return inputBounding.left;
        } else { // if no, place it on right
          return inputBounding.right - width;
        }
      })();

      const positionY = (() => {
        const height = dropdownContainerEl.offsetHeight;
        if(remainingSizeOnBottom >= height) { // is there enough remaining space on bottom?
          return inputBounding.bottom;
        } else if(remainingSizeOnTop >= height) { // is there enough remaining space on top?
          return inputBounding.top - height;
        } else { // when there is not enough space (on the top and on the bottom) it puts the list over the input field
          return inputBounding.bottom - height + (screenHeight - inputBounding.bottom - BOX_SHADOW_SIZE);
        }
      })() + pageY;

      dropdownContainerEl.style.cssText = `
        width: ${width}px;
        max-height: ${maxHeight}px;
        top: 0;
        left: 0;
        transform: translate(${positionX}px, ${positionY}px);
      `;
    },
    hideDropdown() {
      if(!this.isShowDropdown) return; // ignore if it is already hidden

      this.dropdownSelectedIndex = -1;
      //this.inputText = null;
      this.isShowDropdown = false;

      // add global classes
      document.body.classList.remove(IGNORE_MODAL_ESC_FOR_CHIPS_CLASS);

      // remove events
      const { dropdownContainerEl, } = this.$refs;
      if(dropdownContainerEl) {
        const dropdownScrollEl = dropdownContainerEl.querySelector(CHIPS_DROPDOWN_SCROLL_SELECTOR);
        dropdownScrollEl.removeEventListener('DOMMouseScroll', this.keepOnlyDropdownListScrollable, WHEEL_EVENT_OPTIONS);
        dropdownScrollEl.removeEventListener(WHEEL_EVENT_NAME, this.keepOnlyDropdownListScrollable, WHEEL_EVENT_OPTIONS);
      }

      window.removeEventListener('touchstart', this.onTouchScrollStart, WHEEL_EVENT_OPTIONS_PASSIVE);
      window.removeEventListener('touchmove', this.keepOnlyDropdownListScrollable, WHEEL_EVENT_OPTIONS);
      window.removeEventListener('scroll', this.repositionDropdown, true);
      window.removeEventListener('resize', this.repositionDropdown);

      document.removeEventListener('click', this.handleGlobalClick);
      document.removeEventListener('keydown', this.onEscPress);
      document.removeEventListener('keydown', this.onArrowNavigation);
      document.removeEventListener('keypress', this.focusOnInput);
    },
    handleGlobalClick(event) {
      const inputContainerEl = this.$el.querySelector(CHIPS_INPUT_CONTAINER_SELECTOR);
      if(event?.target !== inputContainerEl 
        && event?.target?.closest?.(CHIPS_INPUT_CONTAINER_SELECTOR) !== inputContainerEl) {
        this.hideDropdown();
      }
    },
    onEscPress(event) {
      const key = event.keyCode || event.which;
      if(key === 27) {
        this.hideDropdown();
      }
    },
    focusOnInput() {
      if (this.$refs.inputText) {
        this.$refs.inputText.focus();
      }

      // remove event
      document.removeEventListener('keypress', this.focusOnInput);
    },
    isClickableCheck(item) {
      if (this.disableInput) {
        return false;
      }

      return this.isClickable && this.isClickable(item);
    },
    clickItem(item) {
      if(!this.isClickableCheck(item)) return;

      this.$emit('clickItem', item);
    },
    onTouchScrollStart(event) {
      this.touchScroll.lastClientY = eventCoord(event, 'clientY');
    },
    keepOnlyDropdownListScrollable(event) {
      if(this._isElementOnListContext(event.target)) {
        event.preventDefault();
        const { dropdownContainerEl, } = this.$refs;
        const dropdownScrollEl = dropdownContainerEl?.querySelector(CHIPS_DROPDOWN_SCROLL_SELECTOR);

        requestAnimationFrame(() => {
          let scrollTop = null;
          if(event.type === 'touchmove') {
            const currentClientY = eventCoord(event, 'clientY');
            scrollTop = this.touchScroll.lastClientY - currentClientY;
            this.touchScroll.lastClientY = currentClientY;
          } else {
            scrollTop = event.wheelDeltaY * -1;
          }

          dropdownScrollEl.scrollTop = dropdownScrollEl.scrollTop + scrollTop;
        });
      }
    },
    _scrollToListOption(element, up) {
      const { dropdownContainerEl, } = this.$refs;
      const dropdownScrollEl = dropdownContainerEl?.querySelector(CHIPS_DROPDOWN_SCROLL_SELECTOR);
      if(!dropdownScrollEl || !element) return;

      if(element.offsetTop <= dropdownScrollEl.scrollTop 
        || (element.offsetTop + element.offsetHeight) >= (dropdownScrollEl.scrollTop + dropdownScrollEl.offsetHeight)) {
        if(up) { // scroll to the selected item keeping it at the top
          dropdownScrollEl.scrollTop = element.offsetTop;
        } else { // scroll to the selected item keeping it at the bottom
          dropdownScrollEl.scrollTop = (element.offsetTop - dropdownScrollEl.offsetHeight) + element.offsetHeight;
        }
      }
    },
    _isElementOnListContext(element) {
      if(!element) return false;

      const { dropdownContainerEl } = this.$refs;
      return element === dropdownContainerEl || element.closest?.(CHIPS_DROPDOWN_WRAPPER_SELECTOR) === dropdownContainerEl;
    },
    _isElementOnComponentContext(element) {
      if(!element) return false;

      const rootEl = this.$el;
      return element === rootEl || element.closest?.(CHIPS_CONTAINER_SELECTOR) === rootEl || this._isElementOnListContext(element);
    },
  },
  beforeDestroy() {
    this.hideDropdown();
    if(this.$refs.dropdownContainerEl) {
      document.body.removeChild(this.$refs.dropdownContainerEl);
    }
  },
  components: {
    PhXCircle,
    PhCaretDown,
    GhostLoading,
    Block
  }
}
</script>

<style scoped>
  .del-icon {
    min-width: 1rem;
    height: 1rem;
  }
  .input-chips__input,
  .input-chips__input:focus,
  .input-chips__input:active {
    background: var(--color-box);
    border: none;
    outline: none;
    flex: 1 1 60px;
    width: 60px;
  }
  .input-chips__dropdown-container-wrapper {
    background: var(--color-box);
    border-radius: 4px;
    box-shadow: 0 2px 6px rgba(0,0,0,.5);
    color: var(--color-text);
    overflow: hidden;
    position: absolute;
    top: -9999px;
    left: -9999px;
    z-index: 99999;
  }
  .input-chips__container {
    display: flex;
    width: 100%;
  }
  .input-chips__dropdown-container {
    list-style: none;
    margin: 0;
    padding: 0;
    overflow-y: auto;
    width: 100%;
    max-height: inherit;
  }
  .input-chips__chips {
    border-radius: 4px;
    border: 1px solid var(--color-text);
    padding: 0 4px;
    font-size: 0.80rem;
    display: flex;
    align-items: center;
    margin: 4px;
    min-height: 22px;
  }
  .input-chips__dropdown-item {
    padding: 8px 12px;
  }
  .input-chips__dropdown-item:last-child {
    border: none;
  }
  .input-chips__input-container {
    background: var(--color-box);
    color: var(--color-text);
    border-radius: 4px;
    border: 1px solid var(--color-text);
    padding: 0 10px;
    min-height: 32px;
    display: flex;
    cursor: text;
  }
  .input-chips__dropdown-item--selected {
    background: rgba(200,200,200, 0.5);
  }
  .one-line .input-chips__input-container {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-flow: nowrap;
  }
  .input-chips__chips-container {
    flex: 1 1 auto;
    display: flex;
    flex-wrap: wrap;
  }
  .input-chips__toggle-dropdown {
    flex: 0 0 auto;
    padding: 0 10px;
    margin: 0 -10px 0 0;
    transition: transform .3s ease;
  }
  .input-chips__toggle-dropdown svg {
    color: var(--color-text);
  }
  .is-dropdown-active .input-chips__toggle-dropdown {
    transform: rotate(180deg);
  }
  .label-content {
    display: flex;
    flex-wrap: wrap;
    align-content: stretch;
  }
  .flex-strech {
    flex: 1 1 auto;
    width: 100%;
  }
  .disabled {
    cursor: not-allowed;
    background-color: var(--color-box);
    color: var(--color-text);
    border-color: var(--color-text);
    opacity: 0.6;
  }
</style>