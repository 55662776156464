var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: { title: _vm.$appNavigation.currentMenu.label }
      }),
      _vm._m(0),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("div", { staticClass: "box__title" }, [
            _vm._v(
              " Hier können Sie den Kunden zu einem anderen Makler Ihrer Struktur wechseln. Die Formulare werden wo möglich sofort an die Lagerstellen geschickt. Alle Lagerstellen die nicht automatisch umgestellt werden können, müssen nach dem Übertrag ggf. mit Kundenunterschrift übertragen werden. "
            )
          ]),
          _c("ComboBox", {
            attrs: {
              label: "Wechsel zu",
              isComponentHalfSize: "",
              disabled: _vm.isSomeButtonLoading,
              values: _vm.targetMaklerValues
            },
            model: {
              value: _vm.targetMakler,
              callback: function($$v) {
                _vm.targetMakler = $$v
              },
              expression: "targetMakler"
            }
          }),
          _c("BaseButton", {
            staticClass: "mr-2",
            attrs: {
              label: "Vermittler wechseln",
              animated: _vm.loading.change,
              disabled: !_vm.targetMakler || _vm.isSomeButtonLoading
            },
            on: {
              click: function($event) {
                return _vm.$refs.changeVermittlerModal.open()
              }
            }
          })
        ],
        1
      ),
      _vm.lagerstellenList && _vm.lagerstellenList.length
        ? _c(
            "div",
            { staticClass: "box__container" },
            [
              _c("div", { staticClass: "box__title" }, [
                _vm._v("Lagerstellen:")
              ]),
              _vm._l(_vm.lagerstellenList, function(lagerstelle, index) {
                return _c("div", { key: index }, [
                  _c("div", { staticStyle: { display: "flex" } }, [
                    _c("div", { staticStyle: { width: "50%" } }, [
                      _vm._v(" " + _vm._s(lagerstelle.label) + " ")
                    ]),
                    _c("div", [
                      _vm._v(" " + _vm._s(lagerstelle.description) + " ")
                    ])
                  ]),
                  _c("hr")
                ])
              })
            ],
            2
          )
        : _vm._e(),
      _c(
        "BaseModal",
        {
          ref: "changeVermittlerModal",
          attrs: {
            labelButtonConfirm: "Vermittler wechseln",
            labelButtonCancel: "Abbrechen"
          },
          on: {
            onConfirmButton: function($event) {
              return _vm.changeVermittler()
            }
          },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [
                  _c("ph-warning", {
                    staticClass: "color-danger mr-2",
                    attrs: { size: 16 }
                  }),
                  _vm._v(" Vermittlerwechsel ")
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("p", { staticClass: "color-danger" }, [_vm._v("Achtung!")]),
          _vm._v(
            " Hiermit wird der Kunde auf den ausgewählten Makler umgebucht "
          ),
          _c("b", [
            _vm._v(
              " und alle Dokumente werden automatisch an die jeweiligen Depotstellen versendet! "
            )
          ])
        ]
      ),
      _c(
        "BaseModal",
        {
          ref: "versandFailedModal",
          attrs: {
            modalTitle: "Übertrag kann nicht durchgeführt werden",
            labelButtonConfirm: "Ticket für Fehlerbehebung erstellen",
            labelButtonCancel: "Abbruch (Wechsel nicht durchführen)"
          },
          on: {
            onConfirmButton: function($event) {
              return _vm.createTicket()
            }
          }
        },
        [
          _c("div", {
            domProps: {
              innerHTML: _vm._s(_vm.sanitize(_vm.versandFailedMessage))
            }
          })
        ]
      ),
      _c(
        "BaseModal",
        {
          ref: "hinweisModal",
          attrs: {
            modalTitle: "Wichtige Hinweise",
            labelButtonConfirm: "Gelesen",
            showCancelButton: false,
            showCloseButton: false
          },
          on: {
            onConfirmButton: function($event) {
              return _vm.sendGelesen()
            }
          }
        },
        [
          _c("div", {
            domProps: { innerHTML: _vm._s(_vm.sanitize(this.popupText)) }
          })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "box__container" }, [
      _c("div", { staticClass: "box__title" }, [
        _vm._v("Vermittlerwechsel innerhalb der Struktur")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }