var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showOptionen
        ? [
            _c("PageHeaderTitleNavigation", {
              attrs: { title: "2FA Intervall ändern" }
            })
          ]
        : _vm._e(),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _c("div", { staticClass: "box__title" }, [
            _vm._v("Intervall für Zwei-Faktor-Authentifizierung festlegen")
          ]),
          _vm.currentInterval === 1
            ? _c("p", [
                _vm._v(
                  "Momentan können Sie sich nach einem Login mit Zwei-Faktor-Authentifizierung " +
                    _vm._s(_vm.currentInterval) +
                    " Tag anmelden, bis Sie wieder Ihren zweiten Faktor verwenden müssen."
                )
              ])
            : _c("p", [
                _vm._v(
                  "Momentan können Sie sich nach einem Login mit Zwei-Faktor-Authentifizierung " +
                    _vm._s(_vm.currentInterval) +
                    " Tage anmelden, bis Sie wieder Ihren zweiten Faktor verwenden müssen."
                )
              ]),
          _vm.error
            ? _c("p", { staticClass: "color-danger" }, [
                _vm._v(_vm._s(_vm.error))
              ])
            : _vm._e(),
          _c("ComboBox", {
            attrs: {
              isComponentHalfSize: "",
              values: _vm.options,
              label:
                "Tage zwischen Zwei-Faktor-Authentifizierung <strong>für Vermittler & Mitarbeiter</strong>",
              disabled: _vm.loading
            },
            on: { change: _vm.changeIntervals },
            model: {
              value: _vm.currentInterval,
              callback: function($$v) {
                _vm.currentInterval = $$v
              },
              expression: "currentInterval"
            }
          }),
          _vm.maxCustomerLimit > 0
            ? _c("ComboBox", {
                attrs: {
                  isComponentHalfSize: "",
                  values: _vm.customerLimitOptions,
                  label: "Obergrenze für Kunden <strong>(in Tagen)</strong>",
                  disabled: _vm.loading
                },
                on: { change: _vm.changeIntervals },
                model: {
                  value: _vm.customerLimit,
                  callback: function($$v) {
                    _vm.customerLimit = $$v
                  },
                  expression: "customerLimit"
                }
              })
            : _vm._e(),
          _vm.maxBrokerLimit > 0
            ? _c("ComboBox", {
                attrs: {
                  isComponentHalfSize: "",
                  values: _vm.brokerLimitOptions,
                  label: "Obergrenze für Makler",
                  disabled: _vm.loading
                },
                on: { change: _vm.changeIntervals },
                model: {
                  value: _vm.brokerLimit,
                  callback: function($$v) {
                    _vm.brokerLimit = $$v
                  },
                  expression: "brokerLimit"
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }