<template>
  <div v-if="showAddImage">
    <BaseToolbarButton 
      title="Image"
      :icon="icon"
      :disabled="disabled"
      class="mr-0 tb-image-button"
      content="Bild"
      v-tippy="{ arrow: true }">
      <input 
        :disabled="disabled"
        type="file" 
        class="input-hidden" 
        accept="image/x-png,image/jpeg,image/gif" 
        @change="onAddImage($event)">
    </BaseToolbarButton>
  </div>
</template>

<script>
import { PhImage } from 'phosphor-vue';
import BaseToolbarButton from '../base-components/BaseToolbarButton.vue';
import { loadImagePropertiesOfBase64 } from '../custom-extensions/CustomImage';

export default {
  components: {
    BaseToolbarButton,
  },

  props: {
    editor: {
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      showLinkModal: false,
      linkForm: null,
    }
  },

  computed: {
    icon() {
      return PhImage;
    },
    showAddImage() {
      return !this.editor?.isActive('image')
    }
  },

  methods: {
    onAddImage(event) {
      const files = event.target.files;
      if (FileReader && files && files.length) {
        const fr = new FileReader();
        fr.onload = () => {
          loadImagePropertiesOfBase64(fr.result, files[0].name).then(imageProps => {
            this.editor.chain().focus().setImage(imageProps).run()
          })
        }
        fr.readAsDataURL(files[0]);
      }
    }
  }
}
</script>

<style scoped>

.tb-image-button {
  position: relative; 
  overflow: hidden;
}

.input-hidden {
  opacity: 0;
  position: absolute;
  left: 0;
  width: 22px;
  cursor: pointer;
}
</style>
