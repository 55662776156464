var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: { title: _vm.$appNavigation.currentMenu.label }
      }),
      _c("BaseFilter", {
        attrs: {
          filterId: _vm.saveKey,
          isCache: "",
          configFilter: _vm.configFilter,
          metadata: _vm.searchMenu,
          defaultOptions: _vm.initialFilters,
          showSaveButton: "",
          hasSmartSearch: "",
          immidiateSearch:
            _vm.isNotCommingFromPruefstautsChangePage && _vm.isBroker
        },
        on: { onFilter: _vm.handleSearch }
      }),
      _c("HandleTablePin", {
        attrs: { keyOfPIN: "TABELLE_VERSICHER_ANTRAEGE" }
      }),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _vm.loading
            ? _c("GhostLoading", {
                attrs: { type: "table", title: _vm.TABLE_TITLE }
              })
            : _c("Table", {
                ref: "tableResult",
                attrs: {
                  tableId: "antragslisteVersicherung",
                  title: _vm.TABLE_TITLE,
                  headers: _vm.headers,
                  rows: _vm.rows,
                  rowsPerPage: _vm.rowsPerPage,
                  exportConfig: { roottext: "Versicherungsanträge" },
                  thHoverDisabled: true,
                  mobileConfig: {
                    title: "gesellschaftName",
                    headers: ["kundenLinkName", "pruefstatus", "vertragsbeginn"]
                  }
                },
                on: {
                  "click-kundenLinkName": _vm.openCustomerNewTab,
                  "click-kundennr": _vm.openCustomerNewTab,
                  "click-gesellschaftName": function($event) {
                    return _vm.navigateToGesellschaft(
                      $event.gesellschaft,
                      $event.gesellschaftArt
                    )
                  },
                  "click-sender": function($event) {
                    return _vm.openBrokerNewTab({ brokerId: $event.sender })
                  },
                  "click-maklernr": _vm.navigateToVermittlerstruktur,
                  "click-policennummer": _vm.openInsurancesNewTab,
                  "click-vertragId": _vm.openInsurancesNewTab,
                  "click-courtageErhalten": function($event) {
                    return _vm.openInsurancesNewTab($event, "courtage")
                  },
                  "action-INFO": _vm.openMaklervertragInfoModal,
                  "action-INSURANCE": _vm.openInsurancesNewTab,
                  "action-PROTOKOLL": _vm.showProtokoll
                },
                scopedSlots: _vm._u([
                  {
                    key: "pruefstatus",
                    fn: function(row) {
                      return [
                        _vm.istBearbeiter && !row.mobileTableContext
                          ? _c(
                              "div",
                              [
                                _c("ComboBox", {
                                  attrs: {
                                    value: row.pruefstatus.value,
                                    values: row.pruefStatusComboboxValues || []
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.changePruefStatus($event, row)
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          : _c(
                              "div",
                              [
                                _c("Pill", {
                                  attrs: {
                                    type: "info",
                                    label: row.pruefstatus.label
                                  }
                                })
                              ],
                              1
                            )
                      ]
                    }
                  },
                  {
                    key: "document",
                    fn: function(row) {
                      return [
                        row.fileWithSignedAttachments
                          ? _c(
                              "a",
                              {
                                attrs: {
                                  tid: _vm._generateTidFromString(
                                    "versicherungsantraege-table-" + row.index
                                  )
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.viewDocument(row.id)
                                  }
                                }
                              },
                              [_c("PhFilePdf", { attrs: { size: 20 } })],
                              1
                            )
                          : _c(
                              "DownloadLink",
                              {
                                attrs: {
                                  tid: _vm._generateTidFromString(
                                    "versicherungsantraege-table-" + row.index
                                  ),
                                  downloadServicePath:
                                    "/getVersicherungantraegeFile",
                                  filename: row.fileName || row.fremdnr,
                                  queryParams: {
                                    id: row.id
                                  }
                                }
                              },
                              [_c("PhFilePdf", { attrs: { size: 20 } })],
                              1
                            )
                      ]
                    }
                  }
                ])
              })
        ],
        1
      ),
      _c(
        "BaseModal",
        {
          ref: "hatFehler",
          attrs: { showCancelButton: false, labelButtonConfirm: "Ok" },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [
                  _c("ph-warning", {
                    staticClass: "mr-2",
                    staticStyle: { color: "orangered" },
                    attrs: { size: 16 }
                  }),
                  _vm._v(" Fehler ")
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c("div", [
            _vm.internUser && !_vm.isBroker
              ? _c("p", [
                  _vm._v(
                    "Für den internen Zugang ist die Maklernummer erforderlich"
                  )
                ])
              : _vm._e(),
            !_vm.hasMandatoryFilters
              ? _c("p", [
                  _vm._v(
                    "Wählen Sie bitte einen Status: geprüft, nicht geprüft, nicht versendet, erledigt, selbst gesendet, abgelehnt."
                  )
                ])
              : _vm._e(),
            !_vm.hasZeitraumFilters
              ? _c("p", [
                  _vm._v(
                    "Wählen Sie bitte eine Zeitraum: 30 Tage, 14 Tage, 7 Tage oder Zwischen."
                  )
                ])
              : _vm._e()
          ])
        ]
      ),
      _c(
        "BaseModal",
        {
          ref: "infoMaklervertrag",
          attrs: { showCancelButton: false, labelButtonConfirm: "Ok" },
          on: { onConfirmButton: _vm.closeInfoModal },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [_vm._v(" " + _vm._s(_vm.infoTitle) + " ")]
              },
              proxy: true
            }
          ])
        },
        [
          _vm.infoText && _vm.infoText.length
            ? _c(
                "div",
                _vm._l(_vm.infoText, function(line, index) {
                  return _c("div", { key: index, staticClass: "mt-1" }, [
                    _vm._v(" " + _vm._s(line) + " ")
                  ])
                }),
                0
              )
            : _vm._e()
        ]
      ),
      _c(
        "BaseModal",
        {
          ref: "emptyFile",
          attrs: { showCancelButton: false, labelButtonConfirm: "Ok" },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [
                  _c("ph-warning", {
                    staticClass: "mr-2",
                    staticStyle: { color: "orangered" },
                    attrs: { size: 16 }
                  }),
                  _vm._v("Fehler ")
                ]
              },
              proxy: true
            }
          ])
        },
        [_c("div", [_vm._v("Es liegen keine Dokumente vor.")])]
      ),
      _c(
        "BaseModal",
        {
          ref: "errorFile",
          attrs: { showCancelButton: false, labelButtonConfirm: "Ok" },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [
                  _c("ph-warning", {
                    staticClass: "mr-2",
                    staticStyle: { color: "orangered" },
                    attrs: { size: 16 }
                  }),
                  _vm._v("Fehler ")
                ]
              },
              proxy: true
            }
          ])
        },
        [_c("div", [_vm._v("Dokument kann nicht geöffnet werden.")])]
      ),
      _c(
        "BaseModal",
        {
          ref: "multipleFilesModal",
          attrs: {
            showCancelButton: false,
            showConfirmButton: false,
            modalTitle: _vm.currentFilename
          }
        },
        [
          _c("FileAttachmentsModal", {
            attrs: {
              signedAttachments: _vm.signedAttachments,
              unsignedAttachments: _vm.unsignedAttachments
            },
            on: {
              close: function($event) {
                return _vm.$refs.multipleFilesModal.close()
              }
            }
          })
        ],
        1
      ),
      _c(
        "BaseModal",
        {
          ref: "downloadErrorModal",
          attrs: {
            modalTitle: "Fehler",
            labelButtonConfirm: "Ok",
            showCancelButton: false
          }
        },
        [_c("label", [_vm._v(_vm._s(_vm.downloadErrorMessage))])]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }