<template>
  <div>
    <PageHeaderTitleNavigation
      :title="bezeichnung || 'Beratungstitel'"
      :actions="actions"
      @action-OPEN_ERZEUGEN="openErzeugen()"
      @action-OPEN_SIGNIEREN="openSignieren()"
      @action-OPEN_MAIL="openMail()"
      @action-EDIT_BEMERKUNG="editBemerkung()"
      @action-ADD_ACTIVITY="handleAddActivity()"
      @action-FORTSETZEN="beratungsmappeFortsetzen()"
      @action-ABSCHLIESSEN_CONFIRMATION="doMappeAbschliessenConfirmation()"
      @action-CHANGE_DESCRIPTION="openEditBeratungsmappe()">
      <template #subtitle>
        <span>{{pageHeaderSubtitle}}</span>
        <span v-if="statusText">&nbsp;|&nbsp;</span>
        <Pill v-if="statusText" :label="statusText" type="danger"></Pill>
      </template>
    </PageHeaderTitleNavigation>

    <BaseModal
      ref="editBeratungsmappe"
      modalTitle="Beratungsmappe Einstellungen"
      labelButtonConfirm="Übernehmen"
      labelButtonCancel="Abbrechen"
      size="sm"
      @onConfirmButton="saveBeratungsmappe()">

      <InputField
        label="Titel"
        v-model="tempBezeichnung">
      </InputField>

    </BaseModal>

    <BaseModal @onConfirmButton="deleteAntraege()" ref="deleteAntraegeConfirmation" modalTitle="Antrag löschen" labelButtonConfirm="Löschen">
      {{ deleteAntraegMessage }}
    </BaseModal>

    <BaseModal @onConfirmButton="deletePosition()" ref="deletePositionConfirmation" modalTitle="Position löschen" labelButtonConfirm="Löschen">
      {{ deletePositionMessage }}
    </BaseModal>    

    <BaseModal ref="nichtImplementiertModal" modalTitle="Nicht implementiert!" :showCancelButton="false" :showConfirmButton="false" :showCloseButton="true">
      Formular wurde noch nicht implementiert
    </BaseModal>  

    <BaseModal @onConfirmButton="beratungsmappeAbschliessen()" ref="mappeAbschliessenConfirmation" modalTitle="Beratungsmappe abschließen" labelButtonConfirm="Abschließen">
      Beratungsmappe abschließen? Wenn die Beratungsmappe abgeschlossen wird, sind keine weiteren Änderungen möglich.
    </BaseModal>  

    <BaseModal @onConfirmButton="saveBemerkung()" ref="editBemerkung" modalTitle="Beratungsmappe Bemerkung" labelButtonConfirm="Speichern">
      <InputTextArea
          label="Bemerkung zur Beratung / Vermittlung (für Berater des Kunden sichtbar)"
          v-model="tempBemerkung"
          >
      </InputTextArea>
    </BaseModal>  
    <!-- PDF ERZEUGEN -->

    
    <div class="box__container" v-if="erzeugen && loadingActions == false">
      <p>{{actionsTitle}}</p>
      <div v-if="!beratungVersicherungActions.signedAttachments || !beratungVersicherungActions.signedAttachments.length">
        <div class="row mt-2">
          <div class="col-lg-6 col-md-12 mb-2">
            <base-button @click="beratungsmappeErzeugen()">
              Beratungsmappe erzeugen
            </base-button>
          </div>
        </div>

        <div v-if="pdfErzeugenCheckboxesGroups && pdfErzeugenCheckboxesGroups.length">
          <div class="mt-2" v-for="(group, index) in pdfErzeugenCheckboxesGroups" :key="index">
            <InputCheckBoxGroup v-if="group"
              :title="group.label"
              :values="group.values" 
              :value="checkboxValues"
            />
          </div>
          <p class="font-bold">{{ beratungsmappeActions.nachricht }}</p>
        </div>
      </div>

      <div v-if="beratungVersicherungActions.signedAttachments && beratungVersicherungActions.signedAttachments.length">
        <div class="row mt-2">
          <div class="col-lg-6 col-md-12 mb-2">
            <base-button @click="listeErneutErzeugen()">
              Liste erneut erzeugen
            </base-button>
          </div>
        </div>

        <p>Die Dokumente können nicht zu einer Datei zusammengefasst werden, da sie z.T. digital signiert wurden. Sie können die Dateien hier einzeln abrufen:</p>

        <p><b>Nicht digital signierte Dokumente / Scans</b></p>

        <p><b>Digital signierte Dokument</b></p>

        <div v-for="sa in signedAttachments" v-bind:key="sa.label">
          <div class="clickable" (click)="showFileContent(sa.bytes)">- {{sa.fileName}}</div>
        </div>

      </div>
    </div>

    <!-- SIGNIEREN -->
    <div class="box__container" v-if="signieren && loadingActions == false">
      <p>{{actionsTitle}}</p>
      <div v-for="(signableDocument, index) in beratungVersicherungActions.signableDocuments" v-bind:key="index">
        <a v-if="signableDocument.link.value" target="_blank" :href="signableDocument.link.value">{{signableDocument.link.label}}</a>
        <span class="grayed-out" v-if="!signableDocument.link.value && !signableDocument.antragsdatenId">{{signableDocument.link.label}}</span>
        <a v-if="!signableDocument.link.value && signableDocument.antragsdatenId" target="_blank" @click="getSignedFiles(signableDocument)">{{signableDocument.link.label}}</a>        

      </div>
      <p>{{beratungVersicherungActions.nachricht}}</p>
    </div>

    <!-- BERATUNGSMAPPEN VERSENDEN -->

    
    <div class="box__container" v-if="versenden && loadingActions == false">
      <p>{{actionsTitle}}</p>
      <div v-if="!beratungVersicherungActions.signedAttachments || !beratungVersicherungActions.signedAttachments.length">
        <div class="row mt-2">
          <div class="col-lg-6 col-md-12 mb-2">
            <BaseButton class="btn btn-primary " @click="beratungsmappeVersenden()">
              Beratungsmappe versenden
            </BaseButton>
          </div>
        </div>

        <div v-if="pdfErzeugenCheckboxesGroups && pdfErzeugenCheckboxesGroups.length">
          <div class="mt-2" v-for="(group, index) in pdfErzeugenCheckboxesGroups" :key="index">
            <InputCheckBoxGroup v-if="group"
              :title="group.label"
              :values="group.values" 
              :value="checkboxValues"
            />
          </div>
          <p class="font-bold">{{ beratungsmappeActions.nachricht }}</p>
        </div>
      </div>

    </div>

    <div v-if="beratungVersicherungenCards && cardsData && cardsData.length" class="beratung-edit__main">
      <div class="beratung-edit__sidebar">
        <BoxList :items="cardsData" @clickItem="handleCardItemClick">
          <template #icon="{ item }">
            <ph-info v-if="item.key == 'unterschriften_info_text'" :size="24" class="color-info" />
            <ph-info v-else-if="item.key == 'antrage_box'" :size="24" class="color-info" />
            <ph-info v-else-if="item.key == 'erfassung_position_papierantrag'" :size="24" class="color-info" />
            <ph-question v-else-if="item.key == 'unterschriften_question_mark_text'" :size="24" class="color-info" />
            <ph-check-circle v-else-if="item.status == 'success'" :size="24" class="color-success" />
            <ph-warning-circle v-else-if="item.status == 'warning'" :size="24" class="color-warning" />
            <ph-warning-circle v-else-if="item.status == 'danger'" :size="24" class="color-danger" />
            <ph-circle v-else :size="24" class="color-primary" />
          </template>

          <template #default="{ item }">
            <h3 class="mt-0 mb-1" v-if="item.key !== 'unterschriften_info_text' && item.key !== 'unterschriften_question_mark_text'">{{ item.label }}</h3>
            <p class="mt-0 mb-0" v-if="item.key !== 'unterschriften_info_text'"  :tid="item.description && item.description.length ? _generateTidFromString(item.description + item.key) : null">
              <small>{{ item.description }}</small>
            </p>
            <p class="mt-0 mb-0" v-if="item.key == 'unterschriften_info_text'">
              <small v-html="sanitize(unterschriftenModalMsg)"></small>
            </p>    
          </template>

          <template #link="{ item }">
            <a v-if="item.clickable && item.key !== 'antrage_box'" href="javascript:;">{{ item.linkText }}</a>
            <a :tid="_generateTidFromString(item.description + item.linkText)" v-if="item.key == 'antrage_box' && isFA == false" @click="showVergleichsrechner()" href="javascript:;">{{ item.linkText }}</a>
            <a :tid="_generateTidFromString(item.description + item.linkText)" v-if="item.key == 'antrage_box'  && isFA == true" href="javascript:;" @click="dokumenteAbholen()">Dokumente abholen</a>
            <span v-if="item.key == 'antrage_box'  && isFA == true">&nbsp;|&nbsp;</span>
            <a :tid="_generateTidFromString(item.description + item.linkText)" v-if="item.key == 'antrage_box'  && isFA == true" @click="openExterneZugangBank(cheggnetData)">Chegg.net</a>
            <a :tid="_generateTidFromString(item.description + item.linkText)" v-if="item.buttonVorlegenAktiv && item.buttonVorlegenAktiv == true && item.key=='unterschriften'" href="javascript:;" @click="zurESignaturVorlegen(item)">{{ item.linkText }}</a>

            <span v-if="item.buttonVorlegenAktiv == false && item.key=='unterschriften'">{{item.linkText}}</span>
            
          </template>
        </BoxList>
      </div>
      <div class="beratung-edit__content">


        <OptionMenu v-if="showForms == true" id="fc3b303c-544b-4528-8aa8-7840fe07c1f1" :defaultMenu="customOptionMenu" 
         :showEditButton="false" :customOptionMenu="customOptionMenu" :ignoreRender="false" @configuredMenu="setConfiguredLagerstelleList($event)"></OptionMenu>
      

        <div v-if="showForms == true && selectedLagerstelle && selectedLagerstelle.path">
          <div class="box__container" :tid="_generateTidFromString('box__container')">
            <div class="box__title">{{selectedLagerstelle.label}}</div>
              <InputField 
                isComponentHalfSize
                placeholder="Suchen nach Bezeichnung"
                v-model="descriptionSearch"/>
          </div>
          <div class="box__container" v-for="(value, propName) in groupedForms" :key="propName">
              <Table 
                class="formulare-list__table"
                tableId="0eca709b-022c-4078-871f-633007348bae"
                :tableData="formulareTableData(value, propName)"
                :cardViewEnabled="false"
                :filterEnabled="false"
                :exportEnabled="false"
                :paginationEnabled="false"
                :rowsPerPage="value.length"
                :additionalCollapsed="true"
                >
                <template #label="{ data }">
                  <a @click="handleLabelClick(data.row)">
                    {{ data.row.label }}
                  </a>
                </template>
              </Table>
          </div>
        </div>      
        <div class="box__container">
          <p>Anträge</p>

          <Table class="table-container"
            v-if="beratungVersicherungenCards.antraege && beratungVersicherungenCards.antraege.antraegeList && beratungVersicherungenCards.antraege.antraegeList.records.length"
            :tableData="antraegTableData"
            :cardViewEnabled="false"
            :filterEnabled="false"
            :exportEnabled="false"
            :paginationEnabled="false"
            :actions="antragTableActions"
            @execute-action="handleAction"
            >
            <template v-slot:status="props">
              <Pill v-if="props.data.row.Status == 'success'" label="fehlerfrei" type="success"></Pill>
              <Pill v-if="props.data.row.Status == 'warning'" label="fehlerhaft" type="warning"></Pill>
              <Pill v-if="props.data.row.Status == 'danger'" label="fehlerhaft" type="danger"></Pill>
            </template>
            <template v-slot:antragTitle="props">
              <a :tid="_generateTidFromString('antraegTable' + props.data.index)" @click="editAntraeg(props.data.row)" :disabled="!props.data.row.Gesellschaft">{{props.data.row.Antrag}}</a>
            </template>            
            <template v-slot:lastColumn="props">
              <ph-trash :size="16" @click="deleteAntraegeConfirmation(props.data.row)"/>
            </template>
          </Table>       

        </div>

        <div class="box__container" v-if="showingVergleichsrechner"> 
          <p>Externe Rechner</p>
          
          <Table 
            tableId="cbbf3d4b-eec7-469b-9504-2e197d4de462"
            :tableData="bestehendeVertrageData"
            :cardViewEnabled="false"
            :filterEnabled="false"
            :exportEnabled="false"
            :paginationEnabled="false">
            <template v-slot:itemLabel="props">
              <div v-if="props.data.row.disabled" :tid="_generateTidFromString('vergleichsrechnerTable-disabled' + props.index)">
                {{props.data.row.itemLabel}}
              </div>
              <a v-else :tid="_generateTidFromString('vergleichsrechnerTable' + props.data.index)" @click="askOptionsExterneZugang(props.data.row)">{{props.data.row.itemLabel}}</a>
            </template>
          </Table>
        </div>

        <div class="box__container">
          <BaseButton isSecondary @click="openVertragVereinigenModal()" :disabled="!allSelectedLoaded || numberSelected !== 2">
            Vertrag vereinigen
          </BaseButton> 

          <InsuranceGroupTable 
            tableId="0cfc1fd4-f01b-4727-bf5d-3207a32fb6f4"
            :headers="headers"
            :pages="pages"
            :count="insurancesCount"
            :pageCount="pageCount"
            :rowsPerPage="rowsPerPage"
            :isLoading="isLoading"
            :selected="selectedRows"
            @selected="onSelect"
            :selectionAsBlacklist="selectionAsBlacklist"
            @selectionAsBlacklist="selectionAsBlacklist = $event"
            dragnDropFilesOnRow
            @action="handleInsuranceAction($event)"
            @requestPage="requestPage"
            @item-selected="openItemSelectedDetail($event)"
            @row-files="handleRowFiles($event)"
            @onSort="sortDataByColumn($event)"
          /> 
        </div>
      

        <ExterneZugangAskOptions 
        :askOptions="askOptions"
        :title="externeZugang.companyLabel"
        v-if="askOptions"
        @close="resetExterneZugang()"
        @onConfirmButton="askOptionsConfirmed($event)"/>
 
        <div>
            <BoxList :items="rightSideCards" @clickItem="handleCardItemClick">

            <template #default="{ item }">
              <div>
                <h3 class="mt-0 mb-1" v-if="item.key !== 'unterschriften_info_text' && item.key !== 'unterschriften_question_mark_text'" :tid="item.label && item.label.length ? _generateTidFromString(item.label + item.key) : null">{{ item.label }}</h3>
                <p class="mt-0 mb-0" v-if="item.key !== 'unterschriften_info_text'" :tid="item.description && item.label.description ? _generateTidFromString(item.description + item.key) : null">
                  <small>{{ item.description }}</small>
                </p>
                <p class="mt-0 mb-0" v-if="item.key == 'unterschriften_info_text'">
                  <small v-html="sanitize(unterschriftenModalMsg)"></small>
                </p>
              </div>            
            </template>

            <template #link="{ item }">
              <a v-if="item.clickable" href="javascript:;"  :tid="item.linkText && item.linkText.length ? _generateTidFromString(item.linkText + item.key) : null">{{ item.linkText }}</a>
              <a v-if="item.buttonVorlegenAktiv && item.buttonVorlegenAktiv == true && item.key=='unterschriften'" href="javascript:;" @click="zurESignaturVorlegen(item)">{{ item.linkText }}</a>

              <span v-if="item.buttonVorlegenAktiv == false && item.key=='unterschriften'">{{item.linkText}}</span>
              
            </template>
            </BoxList>
        </div>        
      </div>
    </div>


    <VertragVereinigenModalNew ref="vertragVereinigenModalNew" :vertragList="selectedAndLoaded" @save="mergeInsurances($event)"/>

    <BaseModal
      ref="unterschriftenModal"
      labelButtonCancel="Abbrechen"
      :showCancelButton="false"
      :showConfirmButton="true"
      labelButtonConfirm="Ok"
      size="lg">
      <template v-slot:modalTitle>
        Information
      </template>
      <div class="mb-1" v-if="unterschriftenModalMsg" v-html="sanitize(unterschriftenModalMsg)"></div>
    </BaseModal>    

    <BaseModal
      ref="zusatzDok"
      labelButtonConfirm="Weiter zur Unterschrift"
      labelButtonCancel="Abbrechen"
      @onConfirmButton="sendZusatzDoks"
      @onCancelButton="closeZusatzListModal"
      size="lg">
      <template v-slot:modalTitle>
        <ph-warning :size="16" class="mr-2" style="color: orangered"/>
        Zusätzliche Dokumente
      </template>
      <div class="mb-1" v-if="pictureAttachMsg">
        {{ pictureAttachMsg }}
      </div>
      <div class="font-strong mb-1">
        Wenn Sie möchten können Sie hier Dokumente auswählen, die vor der e-Signatur zum Antrag hinzugefügt werden.
      </div>
      <div class="font-strong mb-1">
        Andernfalls können Sie direkt mit der Unterschrift fortfahren.
      </div>
      <div class="mb-1">
        Es werden alle geeigneten PDF-Dokumente aus den allgemeinen Dokumenten im Dokumentenarchiv angezeigt (ausgenommen sind
        etwa digital signierte Dateien).
      </div>
      <div class="mb-1">
        Sie können auch ein PDF-Dokument neu hinzufügen, dieses wird dann ebenfalls im Dokumentenarchiv gespeichert.
      </div>
      <div class="mb-1">
        Bitte beachten Sie, dass das Anfügen der Dokumente dauerhaft ist, auch wenn Sie den Antrag doch nicht signieren.
      </div>
      <div class="row" v-for="(dok, index) of zusatzList" :key="index">
        <div class="col">
          <InputCheckBoxItem 
            v-model="zusatzDokForm[dok.id]" 
            :label="dok.bezeichnung + ' ('+ dok.herkunft + ')'"
            inLineLabel/>
        </div>
      </div>
    </BaseModal>

    <BaseModal
      ref="teilweise"
      size="lg"
      :showDefaultButtons="false">
      <template v-slot:modalTitle>
        <ph-warning :size="16" class="mr-2" style="color: orangered"/>
        Formular ist bereits teilweise signiert
      </template>
      <div>
        Das Dokument wurde bereits teilweise elektronisch signiert.
        <br>
        Möchten Sie eine neue PDF mit den geänderten Daten erstellen (alle bisherigen Unterschriften gehen verloren)
        oder die Daten nur speichern ohne sie in die PDF zu übernehmen?
      </div>
      <template v-slot:footer>
        <div class="col-auto">
          <BaseButton isSecondary @click="pdfNeuErstellen()">PDF new erstellen</BaseButton>
        </div>
        <div class="col-auto">
          <BaseButton isSecondary @click="showSignoListFromTeilweise()">PDF unverändert lassen</BaseButton>
        </div>
        <div class="col-auto">
          <BaseButton isSecondary>Abbrechen</BaseButton>
        </div>
      </template>
    </BaseModal>

    <BaseModal
      ref="mailContacts"
      modalTitle="Kontakte"
      size="lg"
      :showCancelButton="false"
      labelButtonConfirm="Auswahl übernehmen"
      @onConfirmButton="confirmContactsToSend">
      <InsuranceCompanyMailContacts :records="mailUsers" @selectedRows="onSelectedRow" />
    </BaseModal>


     <BaseModal
    ref="dokumenteAbholenModal"
    modalTitle="Dokumente abholen"
    :showDefaultButtons="false"
    size="lg">
    {{dokumenteAbholenResult.value}}
  </BaseModal> 

 

  </div>
</template>

<script>
import { PhCheckCircle, PhWarningCircle, PhCircle, PhTrash, PhPencilLine, PhWarning, PhInfo, PhQuestion } from 'phosphor-vue';

import BERATUNGSMAPPE_TYPES from "@/store/beratungVersicherung/types";
import WERTPAPIERORDER from "@/store/wertpapierorder/types";
import { mapGetters } from "vuex";
import InputCheckBoxGroup from '@/components/core/forms/checkbox/InputCheckBoxGroup.vue';
import Card from "@/components/card/card.vue";
import InputTextArea from '@/components/core/forms/InputTextArea.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import FormUtil from '@/components/beratung/formsMenu/forms-menu.js';
import BaseButton from "@/components/core/BaseButton.vue";
import Table from "@/components/table/Table.vue";
import BaseModal from '@/components/core/BaseModal.vue';
import PageHeaderActionCard from '@/components/core/PageHeaderActionCard.vue'
import InputField from '@/components/core/forms/InputField.vue'
import BoxList from '@/components/core/BoxList.vue';
import Pill from '@/components/core/Pill.vue';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import CORE_TYPES from '@/store/core/types'
import ANTRAG_TYPES from "../../store/antrag/types";
import InputCheckBoxItem from "@/components/core/forms/checkbox/InputCheckBoxItem.vue";
import AppointmentEditCreate from '@/components/calendar/appointmentFiles/AppointmentEditCreate.vue';
import { componentByLagerstelle } from '@/helpers/utils-helper'
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import MAILCOMPOSER_TYPES from "@/store/mailcomposer/types";
import vergleichsrechnerMixin from '@/components/externeZugang/vergleichsrechner-mixin';
import InsuranceGroupTable from '@/components/versicherungen/InsuranceGroupTable.vue';
import VERSICHERUNG_TYPES from '@/store/versicherungen/types';
import VertragVereinigenModalNew from '@/views/versicherungen/insurance-group/VertragVereinigenModalNew.vue';
import {sanitize} from '@/helpers/string-helper.js';
import InsuranceCompanyMailContacts from '@/views/versicherungen/InsuranceCompanyMailContacts.vue'
import EXTERNE_ZUGANG_BANK_TYPES from '@/store/externeZugangBank/types';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue'
import { PageHeaderSimpleAction } from '@/components/core/header-title-navigation/page-header-utils'

import { 
  prepareFilters,
  searchMenu,
  headers,
  BESTAND_FREMDBESTAND,
  BESTAND_EIGENBESTAND,
} from '@/views/versicherungen/insurance-group/insuranceSearchConstants';
import insuranceGroupMixin from '@/views/versicherungen/insurance-group/insurance-group-mixin';
import { ROLES, VIEW_ROLES }from '@/router/roles';
import CALENDAR_TYPES from '@/store/calendar/types';

const DEFAULT_OTHER_CATEGORY = 'Sonstiges'

const actionsModeConst = Object.freeze({
  NONE: 0,
  PDF: 1,
  SIGN: 2,
  MAIL: 3,
  BEMERKUNG: 4,
});

export default {
  mixins: [InteractiveHelpCommonsMixin, vergleichsrechnerMixin, insuranceGroupMixin],
  emits: ['configuredMenu'],
  data: function () {
    return {
      descriptionSearch: '',
      cards: {},
      liste: null,
      resultCount: null,
      actionsMode: actionsModeConst.NONE,
      loadingActions: false,
      actionsTitle: "Beratungsmappe Erzeugen",
      erzeugen: false,
      versenden: false,
      signieren: false,
      bemerkung: null,
      tempBemerkung: null,
      tempBezeichnung: null,
      bezeichnung: null,
      apiAddress: process.env.VUE_APP_API,
      verwende: null,
      tempVerwende: null,
      erlaubnisPara34: null,
      // menu: {...this.antragList.formGroups.filter(f => f.showInBeratungsmappe == true)},
      showForms: false,
      antraegeToDelete : null,
      positionToDelete: null,
      selectedBeratungsType: null,
      antragTableActions: [
        {
          "confirmationMessage": null,
          "confirmationTitle": null,
          "specificAction": null,
          "legend": {
            "icon": PhTrash,
              "iconProps": {
                size: 24,
              },
            "index": "0",
            "key": "ANTRAG_LOSCHEN",
            "label": "Löschen"
          }
        },
      ],    
      selectedAntrag: null,  
      configuredMenuInternal: [],
      loadingVersicherung: false,
      lagerstelleList: [],
      configuredLagerstelleList: [],
      selectedLagerstelle: {},
      zusatzList: [],
      loading: {
        pdf: false,
        sign: false,
      },
      signoList: [],  
      modalPdfType: '',  
      pictureAttachMsg: null,  
      zusatzDokForm: {},
      antragData: null,
      unterschriftenModalMsg: null,
      orderPositionenTableActions: [
        {
          legend: {
            icon: PhPencilLine,
            index: 1,
            key: 'POSITION_BEARBEITEN',
            label: 'Bearbeiten',
          }
        },
        {
          legend: {
            icon: PhTrash,
            index: 3,
            key: 'POSITION_LOSCHEN',
            label: 'Löschen',
          }
        }
      ],   
      actionsData: null,
      showingVergleichsrechner: false,
      rowsPerPage: 25,
      ignoreAddBreadcrumb: true,
      mailUsers: [],
      dokumenteAbholenResult: {},
    };
  },
  components: {
    InputCheckBoxGroup,
    AppointmentEditCreate,
    Card,
    InputTextArea,
    ComboBox,
    BaseButton,
    Table,
    BaseModal,
    PageHeaderActionCard,
    InputField,
    BoxList,
    PhCheckCircle,
    PhWarningCircle,
    PhCircle,
    PhTrash,
    Pill,
    OptionMenu,
    InputCheckBoxItem,
    PhInfo,
    PhQuestion,
    InsuranceGroupTable,
    VertragVereinigenModalNew,
    PhWarning,
    InsuranceCompanyMailContacts,
    PageHeaderTitleNavigation,
  },
  computed: {
    ...mapGetters({
      beratungVersicherungenCards: BERATUNGSMAPPE_TYPES.GETTERS.BERATUNG_VERSICHERUNG_CARDS,
      beratungVersicherungActions: BERATUNGSMAPPE_TYPES.GETTERS.BERATUNG_VERSICHERUNG_ACTIONS,
      executeActionResult: BERATUNGSMAPPE_TYPES.GETTERS.EXECUTE_ACTION_RESULT,
      roles: CORE_TYPES.GETTERS.GET_USER_ROLES,
      antragList: ANTRAG_TYPES.GETTERS.ANTRAG_LIST,
      loginData: CORE_TYPES.GETTERS.GET_LOGIN_DATA,
      lastDeletedAntragsPosition: BERATUNGSMAPPE_TYPES.GETTERS.LAST_DELETED_ANTRAGS_POSITION,
      addressLegacy: CORE_TYPES.GETTERS.API_ADDRESS_LEGACY,
      isFA: CORE_TYPES.GETTERS.IS_FA,
      insurancesFiltered: VERSICHERUNG_TYPES.GETTERS.INSURANCES_FILTERED,
      isBrokerOrBypass: CORE_TYPES.GETTERS.IS_BROKER_OR_BYPASS,
      versAdminA: CORE_TYPES.GETTERS.VERSADMIN_A,
      versAdminM: CORE_TYPES.GETTERS.VERSADMIN_M,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      initMailData: MAILCOMPOSER_TYPES.GETTERS.GET_INIT,
      list: EXTERNE_ZUGANG_BANK_TYPES.GETTERS.LIST,
    }),
    isOpenInNewTab() {
      return this.hasRoles([[VIEW_ROLES.VIEW_BROKER], [VIEW_ROLES.VIEW_INTERN]])
    },
    headers() {
        return headers();
    },
    checkboxValues() {
      return this.beratungsmappeActions?.data?.reduce((acc, curr) => ({...acc, [curr.label]: curr.value}), {}) || {};
    },
    pdfErzeugenCheckboxesGroups() {
      return this.beratungsmappeActions?.checkboxes?.map(group => ({
        ...group,
        values: group.values?.map(entry => ({label: entry?.label, path: entry.value})),
      })).sort((a,b) => !a.label ? -1 : a?.label?.localeCompare(b?.label))
    },
    // PDFErzeugenCheckboxesModel() {
    //   const result = {};

    //   this.beratungVersicherungActions && this.beratungVersicherungActions.checkboxes.forEach(c => {
    //       let isChecked = false;

    //       if (this.beratungVersicherungActions.data && this.beratungVersicherungActions.data.length) {
    //         isChecked = this.beratungVersicherungActions.data.filter(s => s.label==c.value)[0];
    //       }

    //       result[c.value] = typeof isChecked !== 'undefined' ? isChecked.value : false;
    //   });

    //   return result;
    // },

    cardsData() {
      let cards = [
        {...this.beratungVersicherungenCards.erfassungKundendaten, key: 'erfassungKundendaten', link: '/persoenlichedaten/customer-data/steps/stammdaten', linkText: 'zum Profil wechseln', clickable: true},
        {...this.beratungVersicherungenCards.erstinformation, key: 'erstinformation', link: this.getFormLink(`/beratung/formulare/antrag/${this.isFA ? 'FA' : 'FK' }/kundenerstinformation`,this.beratungVersicherungenCards.erstinformation?.id), linkText: 'öffnen', clickable: true},
        {...this.beratungVersicherungenCards.rahmenvereinbarung, key: 'rahmenvereinbarung', link: this.getFormLink(`/beratung/formulare/antrag/${this.isFA ? 'FA' : 'FK' }/rahmenvereinbarung`, this.beratungVersicherungenCards.rahmenvereinbarung?.id), linkText: 'öffnen', clickable: true},
        {...this.beratungVersicherungenCards.maklerauftrag, key: 'maklerauftrag', link: this.getFormLink(`/beratung/formulare/antrag/VERSICHERUNG/maklerauftrag/maklerauftrag`,this.beratungVersicherungenCards.maklerauftrag?.id), linkText: 'öffnen', clickable: true},
        {...this.beratungVersicherungenCards.erfassungIstSituation, key: 'erfassungIstSituation', link: '/home/versicherungen/auswahl-vertragsart', linkText: 'öffnen', clickable: true},
        {...this.beratungVersicherungenCards.bedarfsermittlung, key: 'bedarfsermittlung', link: this.getBedarfsermittlungLink(this.beratungVersicherungenCards.bedarfsermittlung?.id), linkText: this.getBedarfsermittlungLinkText(), clickable: true},
        {...this.beratungVersicherungenCards.antraege, key: 'antrage_box', linkText: this.showingVergleichsrechner ? 'Externe Rechner verbergen' : 'Externe Rechner anzeigen', clickable: true},        
        {...this.beratungVersicherungenCards.beratungsdokumentation , key: 'beratungsdokumentation', link: this.getFormLink('/beratung/formulare/antrag/VERSICHERUNG/beratungsdokumentationVersicherungen/STEP_KUNDENDATEN', null,this.beratungVersicherungenCards.beratungsdokumentation?.id), linkText: 'öffnen', clickable: true},        
        
        {...this.beratungVersicherungenCards.nutzungsVereinbarung, key: 'nutzungsVereinbarung', link: this.getFormLinkKSCNutzungsvereinbarung(this.beratungVersicherungenCards.nutzungsVereinbarung?.id), linkText: 'öffnen', clickable: true},
        {...this.beratungVersicherungenCards.datenschutzErklarung, key: 'datenschutzErklarung', link: this.getFormlinkDatenschutzerklaerung(this.beratungVersicherungenCards.datenschutzErklarung?.id), linkText: 'öffnen', clickable: true},
        {...this.beratungVersicherungenCards.auskunftsVollmacht, key: 'auskunftsVollmacht', link: this.getFormLinkAuskunftsvollmacht(this.beratungVersicherungenCards.auskunftsVollmacht?.id), linkText: 'öffnen', clickable: true},
        {...this.beratungVersicherungenCards.maklerVollmacht, key: 'maklerVollmacht', link: this.getFormLinkMaklerVollmacht(this.beratungVersicherungenCards.maklerVollmacht?.id), linkText: 'öffnen', clickable: true},
        {...this.beratungVersicherungenCards.versicherungsMaklervertrag, key: 'versicherungsMaklervertrag', link: this.getFormLinkVersicherungsmaklervertrag(this.beratungVersicherungenCards.versicherungsMaklervertrag?.id), linkText: 'öffnen', clickable: true},
        {...this.beratungVersicherungenCards.beratungsdokuSach, key: 'beratungsdokuSach', link: this.getFormLinkBeratungsDokuSach(this.beratungVersicherungenCards.beratungsdokuSach?.id), linkText: 'öffnen', clickable: true},
        {...this.beratungVersicherungenCards.beratungsdokuLV, key: 'beratungsdokuLV', link: this.getFormLinkBeratungsDokuLV(this.beratungVersicherungenCards.beratungsdokuLV?.id), linkText: 'öffnen', clickable: true},
        {...this.beratungVersicherungenCards.erweiterteGeldwaeschepruefung, key: 'erweiterteGeldwaeschepruefung', link: this.getFormLinkErweitereGeldwaeschepruefung(this.beratungVersicherungenCards.erweiterteGeldwaeschepruefung?.id), linkText: 'öffnen', clickable: true},

        {...this.beratungVersicherungenCards.unterschriften, key: 'unterschriften', linkText: 'Zur e-Signatur vorlegen', clickable: false,  buttonVorlegenAktiv:  this.beratungVersicherungenCards.unterschriften?.additionalData?.buttonVorlegenAktiv, additionalText: "Bitte beachten Sie, dass die Dokumente nach erfolgter Unterschrift nicht mehr bearbeitet werden können und die Beratungsmappe automatisch geschlossen wird."},

      ];
      if (this.beratungVersicherungenCards) {
        if (this.isFA && this.beratungVersicherungenCards?.antraege && !this.beratungVersicherungenCards?.antraege?.showCheggnet) {
            cards = cards.filter(card => card.key !== 'antrage_box');
        }

        return cards.filter(card => card.label);
      }
      return [];
    },
    rightSideCards() {
      return [];
    },    
   antraegTableData() {

      let tableData = {
        headers: {},
        records:[]
      };
      
      if (this.beratungVersicherungenCards.antraege && this.beratungVersicherungenCards.antraege.antraegeList) {
        const defaultHeaders = { 
          antragTitle: {
            label: 'Antragtitel',
            key: 'antragTitle',
            dataType: 'Slot',
            visible: true,
          },   
          status: {
            label: 'Status',
            key: 'status',
            dataType: 'Slot',
            visible: true,
          },          
        };

        const lastColumns = { 
          actions: {
            label: 'Aktionen',
            key: 'actions',
            dataType: 'tableAction',
            visible: true,
          },               
        };

        tableData.records = this.beratungVersicherungenCards.antraege.antraegeList.rows;  

        let generatedHeaders = {};

        this.beratungVersicherungenCards.antraege.antraegeList.tableHeaders.forEach((item) => {
          generatedHeaders[item.key] = {
            label: item.label,
            key: item.key,
            dataType: item.dataType,
            visible: item.visible,
            sortable: false,
            filterable: item.filterable,
            sum: item.sum,
          }
        })      


        tableData.headers = {...defaultHeaders, ...generatedHeaders, ...lastColumns};
      }


      
      return tableData;

    },    
    positionenTableData () {
      const result = {
        headers: {},
        records: []
      };

      if (this.beratungVersicherungenCards.antraege && this.beratungVersicherungenCards.antraege.orderPositionenNew && this.beratungVersicherungenCards.antraege.orderPositionenNew.headers) {
        let generatedHeader = {};

        // Generates the headers array
        this.beratungVersicherungenCards.antraege.orderPositionenNew.headers.forEach(ah => {
          generatedHeader[ah.key] = {
                  label: ah.label,
                  key: ah.key,
                  dataType: ah.dataType,
                  visible: ah.visible,
                  sortable: ah.sortable,
                  filterable: ah.filterable,
                  sum: ah.sum,
                  }; 
        });
        const lastColumns = { 
          actions: {
            label: 'Aktionen',
            key: 'actions',
            dataType: 'tableAction',
            visible: true,
          },               
        };

        result.headers = {... generatedHeader, ...lastColumns};
  
        // Populates the rows
        result.records = this.beratungVersicherungenCards.antraege.orderPositionenNew.rows.map(record => {
          return {
            ...record, 
            actions: record.isManuellePosition === "1" ? this.orderPositionenTableActions : []
          }
        });
      }

      return result;
    },      
    deleteAntraegMessage() {
      const antragName = this.antraegeToDelete && this.antraegeToDelete.Antrag;
      return antragName && `Soll der Antrag "${antragName}" wirklich aus der Mappe entfernt werden?`;
    },
    deletePositionMessage() {
      const positionName = this.positionToDelete && this.positionToDelete.wertpapier;

      return positionName && `Soll die folgende Position wirklich gelöscht werden? "${positionName}"`;
    },    
    verwendeDescription() {
      if (Array.isArray(this.beratungVersicherungenCards?.verwendeOptions)) {
        const found = this.beratungVersicherungenCards.verwendeOptions.find(v => v.value === this.verwende)
        return found ? found.label : ''
      }
      return ''
    },

    erlaubnisPara34Description() {
      if (Array.isArray(this.beratungVersicherungenCards?.erlaubnisPara34Options)) {
        const found = this.beratungVersicherungenCards.erlaubnisPara34Options.find(v => v.value === this.erlaubnisPara34)
        return found ? found.label : ''
      }
      return ''
    },
    statusText() {
      return this.isBeratungsmappeGesichert ? 'gesichert' : this.isBeratungsmappeClosed ? 'geschlossen' : null;
    },   
    isBeratungsmappeGesichert() {
      return this.beratungVersicherungenCards.kann_bearbaitet_werden?.status == 'IstGesperrtAutomatisch' ? true : false;
    }, 
    isBeratungsmappeSubClosed() {
      return this.beratungVersicherungenCards.kann_bearbaitet_werden?.status =='IstGesperrt'? true : false;
    }, 
    isBeratungsmappeClosed() {
      return this.beratungVersicherungenCards.kann_bearbaitet_werden?.status =='IstGesperrt' || this.beratungVersicherungenCards.kann_bearbaitet_werden?.status == 'IstGesperrtAutomatisch' ? true : false;
    }, 
    pageHeaderSubtitle() {
      if (this.isFA) {
        return this.verwendeDescription ? this.verwendeDescription : ''
      }
      return this.verwendeDescription ? `${this.verwendeDescription} | Erlaubnis §34 f/h: ${this.erlaubnisPara34Description}` : ''
    },
    customOptionMenu() {
      return this.lagerstelleList.filter(menuItem => (!menuItem.isHidden)).map((lagerstelle) => ({
        ...lagerstelle,
        component: componentByLagerstelle(lagerstelle),
        componentProps: {
          src: lagerstelle.id?.toUpperCase() ? `${this.addressLegacy}/mrscompanylogo?company=${lagerstelle.id?.toUpperCase()}` : null,
          fit: 'contain',
          placeholder: {
            width: '78px',
            height: '48px',
          }
        },
        action: () => {
          switch (lagerstelle?.id) {
            case 'WERTPAPIERORDER':      
              this.handleLabelClick(FormUtil.getForms(lagerstelle, this.$route.query.id)[0]);
              break;
            default:
              this.selectLagerstelle(lagerstelle);
              this.changeFormPath(lagerstelle?.path);
          }
        },
      }));
    },      
    currentFormPath() {
      return this.$route?.query?.formPath;
    },
    forms() {
      return FormUtil.getForms(this.selectedLagerstelle, this.$route.query.id);
    },
    filteredForms() {
      const forms = this.forms?.filter?.(f => !f.hideForm)
      if (!forms || !this.descriptionSearch) {
        return forms
      }

      return forms.filter(f => f?.friendlyName?.toLowerCase?.().includes(this.descriptionSearch.toLowerCase()))
    },
    groupedForms() {
      if (!this.filteredForms) {
        return this.filteredForms
      }

      const groupedForm = this.filteredForms.reduce((accu, curr) => {
        const childGroup = curr?.childGroup || DEFAULT_OTHER_CATEGORY

        if (!accu[childGroup]) {
          accu[childGroup] = []
        }
        accu[childGroup].push(curr)
        return accu
      }, {})

      return groupedForm
    },
    bestehendeVertrageData() {
      if (!this.externeZugangList) {
        return {}
      }

      const headers = {
        companyLabel: {
          label: 'Anbieter',
          key: 'companyLabel',
          dataType: 'String',
          visible: true,
        },
        type: {
          label: 'Art',
          key: 'type',
          dataType: 'String',
          visible: true,
        },
        itemLabel: {
          label: 'Bezeichnung',
          key: 'itemLabel',
          dataType: 'Slot',
          visible: true,
        }    
      }
      const records = this.externeZugangList

      return {
        headers,
        records
      }
    },
    cheggnetData() {
      const filteredList = this.list.find(item => item?.company == "Cheggnet");
      return filteredList || null;
    },
    actions() {
      return [
        PageHeaderSimpleAction('ABSCHLIESSEN_CONFIRMATION', 'Abschließen').withVisible(() => !this.isBeratungsmappeSubClosed),
        PageHeaderSimpleAction('OPEN_ERZEUGEN', 'PDF'),
        PageHeaderSimpleAction('OPEN_SIGNIEREN', 'PDF signieren'),
        PageHeaderSimpleAction('OPEN_MAIL', 'Versenden'),
        PageHeaderSimpleAction('EDIT_BEMERKUNG', 'Bemerkung'),
        PageHeaderSimpleAction('ADD_ACTIVITY', 'Aufgabe hinzufügen').withVisible(() => this.isBrokerOrBypass),
        PageHeaderSimpleAction('FORTSETZEN', 'Fortsetzen').withVisible(() => this.isBeratungsmappeGesichert),
        PageHeaderSimpleAction('CHANGE_DESCRIPTION', 'Beschreibung ändern').withVisible(() => !this.isBeratungsmappeClosed),
      ]
    },
  },
  watch: {
    beratungVersicherungActions: function (newValue, oldValue) {
      this.loadingActions = false;
      this.actionsData = newValue.data;

    },
    beratungVersicherungenCards: function (newValue, oldValue) {
      if (newValue && Object.keys(newValue).length) {
        this.bezeichnung = newValue.bezeichnung;
        this.tempBezeichnung = newValue.bezeichnung;

        if (!this.bezeichnung || this.bezeichnung.length==0) {
          this.openEditBeratungsmappe();     
        }

        this.verwende = newValue.verwende;
        this.tempVerwende = newValue.verwende;

        this.bemerkung = newValue.bemerkung

        this.erlaubnisPara34 = newValue.erlaubnisPara34;
        this.unterschriftenModalMsg = newValue.unterschriften && newValue.unterschriften.additionalData && newValue.unterschriften.additionalData.textTooltip; //.replace(/<br>+/g, "\\n");
        this.retrieveAntragList();
      }
    },
    executeActionResult: function (newValue, oldValue) {
      if (newValue.pdfData && newValue.pdfData.bytes) {
        this.showFileContent(newValue.pdfData.bytes);
      }

    },
    antragList: {
      handler(newValue, oldValue) {
        this.lagerstelleList = newValue  && [...newValue.formGroups].sort(this.sortByLabel) || [];
      },
      immediate: false,
    },
    lastDeletedAntragsPosition: function (newValue, oldValue) {
      this.retrieveBeratungsmappeCards();
    },
    initMailData() {
      this.mailUsers = this.initMailData?.participants;
    },    
  },
  methods: {
    async getListExterneZugang() {
      try {
        this.loading = true;
        await this.$store.dispatch(EXTERNE_ZUGANG_BANK_TYPES.ACTIONS.GET_LIST);
      } finally {
        this.loading = false;
      }
    },
    openExterneZugangBank(externeZugangBank) {
      if(externeZugangBank?.company) {
          this.$store.dispatch(EXTERNE_ZUGANG_BANK_TYPES.ACTIONS.OPEN_URL, externeZugangBank);
      }
    },
    sanitize(htmlString) {
        return sanitize(htmlString);
    },
    formulareTableData(forms, category) {
      if(!forms) {
        return {
          headers: {},
          records: [],
        };
      }
 	    return {
        headers: this.tableHeaders(category),
        records: forms,
      };
    },
    openEditBeratungsmappe() {
      this.tempBezeichnung = this.bezeichnung;
      this.tempVerwende = this.verwende;
      this.$refs.editBeratungsmappe.open()
    },
    retrieveBeratungsmappeCards() {
      let payload = {
        beratungsVersId: this.$route.query.id,
      }
/*       if (this.$route.query && this.$route.query.investementAdviceReferrer && !this.$route.query.backAction && this.investmentAdviceOrder) {        
        payload.posBesitzerId = this.investmentAdviceOrder.posBesitzerId;
        payload.antragType = this.investmentAdviceOrder.type;        
      } else if (this.$route.query && this.$route.query.investementAdviceReferrer && !this.$route.query.backAction && this.investmentAdviceDepoteroeffnung) {        
        payload.posBesitzerId = this.investmentAdviceDepoteroeffnung.posBesitzerId;
        payload.posBesitzerIdVerkauf = this.investmentAdviceDepoteroeffnung.posBesitzerIdVerkauf;
        payload.antragType = this.investmentAdviceDepoteroeffnung.type;        
      } */
     
      this.$store.dispatch(BERATUNGSMAPPE_TYPES.ACTIONS.EDIT_BERATUNG_VERSICHERUNG, payload);
    },

    retrieveBeratungsmappeActions(parameterNameModus) {
      this.$store.dispatch(
        BERATUNGSMAPPE_TYPES.ACTIONS.RETRIEVE_BERATUNG_VERSICHERUNG_ACTIONS,
        {
          beratungsVersId: this.$route.query.id,
          PARAMETER_NAME_MODUS: parameterNameModus,
        }
      );
    },
    beratungsmappeErzeugen() {
      let parameters =         {
          beratungsVersId: this.$route.query.id,
          action: 'ACTION_PDF'
        };

        Object.assign(parameters, this.checkboxValues);

      this.$store.dispatch(
        BERATUNGSMAPPE_TYPES.ACTIONS.EXECUTE_BERATUNGSMAPPEACTION,
        parameters
      );
    },
    beratungsmappeVersenden() {
      if (this.actionsData) {
        // let result = {};

        // this.actionsData.forEach(item => {
        //   result[item.label.toLowerCase()] = item.value;
        // });

        this.$store.dispatch(
          BERATUNGSMAPPE_TYPES.ACTIONS.RETRIEVE_VERSENDEN_FILES,
          {
            beratungsVersId: this.$route.query.id,
            data: this.checkboxValues
          }
          
        ).then(response => {
          if (response && response.data) {
            this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_MAILCOMPOSER_CONFIG, {
              type: 'mailcomposer-beratungsmappen',
              action: 'clear',
            });
            this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_ATTACHMENTS, {
              type: 'mailcomposer-beratungsmappen',
              action: 'clear',
            });
            const responseData = response.data
            this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_ATTACHMENTS, responseData);
            this.$router.push({path: `/communication/mailcomposer-beratungsmappen/${this.$route.query.id}`, query: {replaceContent: true, toCompany: true}});
          }
        });

      }

    },
    saveBeratungsmappe(selectedValue) {
      let parameters =         {
          beratungsVersId: this.$route.query.id,
          beratungsMappeTyp: selectedValue? selectedValue : this.tempVerwende,
          erlaubnisPara34: this.erlaubnisPara34,
          bezeichnung: this.tempBezeichnung
        };

      this.verwende = this.tempVerwende;
      this.bezeichnung  = this.tempBezeichnung;

      this.$store.dispatch(
        BERATUNGSMAPPE_TYPES.ACTIONS.SAVE_BERATUNG_VERSICHERUNG,
        parameters
      );
    },
    editBemerkung() {
      this.tempBemerkung = this.bemerkung
      this.$refs.editBemerkung.open()
    },
    saveBemerkung() {
      let parameters = {
          beratungsVersId: this.$route.query.id,
          beratungsMappeTyp: this.tempVerwende,
          bemerkung: this.tempBemerkung
        };

      this.$store.dispatch(
        BERATUNGSMAPPE_TYPES.ACTIONS.SAVE_BERATUNG_VERSICHERUNG,
        parameters
      );
    },
    handleAddActivity(){
      this.openAppointment();
    },
    openAppointment() {
      this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT_CONFIG, {
        title: 'Aufgabe bearbeiten',
  
        back: this.$router.currentRoute.fullPath,
      })
      this.$router.push({ path: `/communication/appointment` });
    },
    openErzeugen() {
      if (!this.erzeugen) {
        this.retrieveBeratungsmappeActions("PDF");
        this.actionsMode = actionsModeConst.PDF;
        this.loadingActions = true;
        this.actionsTitle = "Beratungsmappe erzeugen";
      }
      this.showErzeugen();
    },
    openSignieren() {
      if (!this.signieren) {
        this.retrieveBeratungsmappeActions("SIGN");
        this.actionsMode = actionsModeConst.SIGN;
        this.loadingActions = true;
        this.actionsTitle = "Beratungsmappe Signieren";
      }
      this.showSignieren();
    },
    openMail() {
      if (!this.versenden) {
        this.retrieveBeratungsmappeActions("MAIL");
        this.actionsMode = actionsModeConst.MAIL;
        this.loadingActions = true;
        this.actionsTitle = "Beratungsmappe versenden";
      }
      this.showVersenden();
    },
    showVersenden() {
      this.erzeugen = false;
      this.versenden = this.versenden ? false : true;
      this.signieren = false;
    },
    showErzeugen() {
      this.erzeugen = this.erzeugen ? false : true;
      this.versenden = false;
      this.signieren = false;
     },
    showSignieren() {
      this.erzeugen = false;
      this.versenden = false;
      this.signieren = this.signieren ? false : true;
    },
    showFileContent(bytes) {
      if (!bytes) {
        //this.openModal(this.noDataModal, false);
      } else {
        this.openFile(bytes, 'application/pdf', 'Wertpapierorder');
      }
    },
    openFile(byteArr, contentType, fileName) {
      const typedArray = new Uint8Array(byteArr);
      const out = new Blob([typedArray], { type: contentType });
      const windowRef = this.getRedirectionWindow();
      this.openFileInNewTab(out, windowRef, this.modalViewerService, fileName);
    },
    getRedirectionWindow() {
    return window.open(this.getRedirectionHtmlString(),"_blank");
    },
    openFileInNewTab(file, windowRef, modalViewerServce, filename = "Dokument.pdf"){
      if (!windowRef || !windowRef.location){
      //  console.log(modalViewerServce);
        modalViewerServce.showModal("Bitte Popups erlauben", "Die Datei wird in einem neuen Tab geöffnet. \
          Bitte vergewissern Sie sich, dass kein Addblocker diesen Zugriff verbietet. \
          Bitte erlauben Sie das Öffnen in einem neuen Tab");
        throw new Error("Can not access Tab to write content to.")
      } else {
        if (navigator.userAgent.toLocaleLowerCase().indexOf("edge") > -1){
          windowRef.close();
          navigator.msSaveOrOpenBlob(file, filename);
        } else {
          const linkUrl = URL.createObjectURL(new File([file], filename, {type: file.type}));
          windowRef.location.href = linkUrl;
        }
      }
    },
    getRedirectionHtmlString() {
      return this.apiAddress + "/../images/etc/ksc3/warten.html";
    },
    consoleLog(text) {
      console.log(text)
    },
    showAntragForms() {
      this.showForms = this.showForms == true? false : true;
    },
    showVergleichsrechner() {
        this.showingVergleichsrechner = this.showingVergleichsrechner == true? false : true;
    },
    getAntragButtonText() {
      return this.showForms === true ? 'Anträge ausblenden' : 'Anträge anzeigen';
    },
    createAntrag(menuEntry, formEntry) {
      this.selectedAntrag = formEntry.label;

      if (formEntry.label == "Wertpapierorder") {
        this.$router.push({path: `/beratung/formulare/antrag/WP/wertpapierorder/main`, query: {beratungsVersId: this.$route.query.id, initData: true}});
      } else {
        this.$router.push({path: `/beratung/formulare/antrag/${menuEntry.path}/${formEntry.path}`, query: {beratungsVersId: this.$route.query.id}});
      }


    },    
    editAntraeg (row) {
      if(row.Gesellschaft){  
        const antragsName = Object.values(this.lagerstelleList)
          .filter(menuItem => (menuItem.path.toUpperCase() === row.Gesellschaft.toUpperCase() || menuItem.label.toUpperCase() === row.Gesellschaft.toUpperCase()))
          .flatMap(menuItem => menuItem.children)
          .filter(child => row.AntragModus && row.AntragModus.length && row.AntragModus!=='DWS' ? child.typ && child.typ.toUpperCase() === row.AntragTyp.toUpperCase() && child.antragModus == row.AntragModus : child.typ && child.typ.toUpperCase() === row.AntragTyp.toUpperCase())
          .filter(child => child.friendlyName.toUpperCase() === row.Antrag.toUpperCase())
          //.filter(child => row.path && row.path.length ?  row.path === child.path : true)
          .reduce((acc, curr) => (curr.path), "");

        this.selectedAntrag = row.Antrag;

        if (row.Antrag == "Wertpapierorder") {
           this.$store.commit(WERTPAPIERORDER.MUTATIONS.RESET_STATE);
            this.$router.push({path: `/beratung/formulare/antrag/WP/wertpapierorder/main`, query: {beratungsVersId: this.$route.query.id, antragsdatenId: row.antragsDatenId, antragsnrIntern: row.Id, initData: true, editPreviouslySaved: true}});
        } else {
          if (antragsName) {           
            this.$router.push({path: `/beratung/formulare/antrag/${row.GesellschaftShort}/${antragsName}`, query: {beratungsVersId: this.$route.query.id, antragsdatenId: row.antragsDatenId, antragsnrIntern: row.Id }});
          } else {
            this.$refs.nichtImplementiertModal.open();
            
          }
        }

      }
    },
    deleteAntraegeConfirmation(row) {
      this.antraegeToDelete = row;
      this.$refs.deleteAntraegeConfirmation.open();
    },
    deletePositionConfirmation(row) {
      this.positionToDelete = row;
      this.$refs.deletePositionConfirmation.open();
    },    
    deleteAntraege() {
      const params = {
        action: 'ACTION_DELETE_ANTRAG',
        antragId: this.antraegeToDelete.Id,
        beratungsVersId: this.$route.query.id
      };

      this.$store.dispatch(BERATUNGSMAPPE_TYPES.ACTIONS.DELETE_ANTRAG, params);

      let payload = {
        beratungsVersId: this.$route.query.id
      }
      //an empty edit to reload Orderpositionen in case the deleted Antrag had Positions
      this.$store.dispatch(BERATUNGSMAPPE_TYPES.ACTIONS.EDIT_BERATUNGSMAPPE, payload);

      this.antraegeToDelete = null;
    },
    deletePosition() {
      const params = {
        positionId: this.positionToDelete.id,
        beratungsmappeId: this.$route.query.id
      };

      this.$store.dispatch(BERATUNGSMAPPE_TYPES.ACTIONS.DELETE_POSITION, params);

      this.positionToDelete = null;
    },    
    openOrderPosition(row) {
      const params = {
        positionId: this.positionToDelete.id,
        beratungsVersId: this.$route.query.id,
        isin: this.positionToDelete.isin
      };

      this.$store.dispatch(BERATUNGSMAPPE_TYPES.ACTIONS.RETRIEVE_ORDER_POSITION, params);

      this.$router.push({path: '/beratung/beratung/erfassungpositionpapierantrag', query: {beratungsVersId: this.$route.query.id, positionId: row.id}});
    },
    handleCardItemClick(card) {
      if(!card) {
        return ;
      }

      if(card.link){
        this.selectedBeratungsType = card.label;
        this.$router.push({path: card.link});
      }
    },
    zurESignaturVorlegen() {
      let parameters =         {
          beratungsVersId: this.$route.query.id,
          action: 'BUTTON_UNTERSCHRIFTEN_ANFORDERN',
          beratungsMappeTyp: this.verwende,
          erlaubnisPara34: this.erlaubnisPara34,
          bezeichnung: this.bezeichnung          
        };

      this.$store.dispatch(
        BERATUNGSMAPPE_TYPES.ACTIONS.ZUR_E_SIGNATUR_VORLEGEN,
        parameters
      );      
    },
    doMappeAbschliessenConfirmation() {
        this.$refs.mappeAbschliessenConfirmation.open();
    },
    beratungsmappeAbschliessen() {
      this.$store.dispatch(BERATUNGSMAPPE_TYPES.ACTIONS.BERATUNG_VERSICHERUNG_ABSCHLIESSEN,
        {
          beratungsVersId: this.$route.query.id,
          action: 'BUTTON_SPERREN',
          beratungsMappeTyp: this.verwende,
          erlaubnisPara34: this.erlaubnisPara34,
          bezeichnung: this.bezeichnung,     
        });
    },
    beratungsmappeFortsetzen() {
      this.$store.dispatch(BERATUNGSMAPPE_TYPES.ACTIONS.BERATUNG_VERSICHERUNG_ABSCHLIESSEN,
        {
          beratungsVersId: this.$route.query.id,
          action: 'BUTTON_OEFFNEN',
          beratungsMappeTyp: this.verwende,
          erlaubnisPara34: this.erlaubnisPara34,
          bezeichnung: this.bezeichnung,     
        });
    }, 
    handleAction(event) {
      switch (event.action.legend.key) {
        case 'ANTRAG_LOSCHEN':
          this.deleteAntraege
          this.deleteAntraegeConfirmation(event.value);
          break;
        default:
          console.log(event)
      }
    },
    handlePositionTableAction(event) {
      switch (event.action.legend.key) {
        case 'POSITION_BEARBEITEN':
          this.positionToDelete = event.value;
          this.openOrderPosition(event.value);
          break;
        case 'POSITION_LOSCHEN':
          this.deletePositionConfirmation(event.value);
          break;          
        default:
          console.log(event)
      }
    },    
    setConfiguredLagerstelleList(configuredList) {
      this.configuredLagerstelleList = configuredList;
      this.init();
    },
    init() {
      const formPath = this.currentFormPath;
      const selectedLagerstelle = formPath && this.configuredLagerstelleList.find((lagerstelle) => lagerstelle.path === formPath);
      if (selectedLagerstelle !== undefined) {
        this.selectLagerstelle(selectedLagerstelle);
      } else if (this.configuredLagerstelleList && this.configuredLagerstelleList.length === 1) {
        this.selectLagerstelle(this.configuredLagerstelleList[0]);
      }
    },  
    selectLagerstelle(lagerstelle) {
      if(this.selectedLagerstelle === lagerstelle) return;

      this.selectedLagerstelle = lagerstelle;
    },   
    changeFormPath(formPath) {
      if(this.currentFormPath === formPath) return ;
    },   
    handleLabelClick(row) {
      if(row?.link) {
        this.openForm(row);
      } else {
        this.doClick(row);
      }
    },  
    openForm(form) {
      this.selectedForm = form;
      this.$router.push(form?.link);
    },
    retrieveAntragList() {
      this.$store.dispatch(ANTRAG_TYPES.ACTIONS.RETRIEVE_ANTRAG_LIST, {
        showInBeratungsmappe: true,
        beratungsmappeType: this.tempVerwende,
      });
    },
    getSignedFiles(data, pdfType = 'sign') {
      const payload = {
        data: {
          antragsdatenId: data.antragsdatenId,
          antragsnrIntern: data.antragId,
          beratungsmappe: true
        }, 
        lagerstelle: null,
        id: null, 
        action: pdfType, 
        antragTyp: data.antragTyp,
      }

      this.antragData = {
          antragsdatenId: data.antragsdatenId,
          antragsnrIntern: data.antragId,
          dokId: data.dokId,
          beratungsmappe: true
      };

       this.$store.dispatch(ANTRAG_TYPES.ACTIONS.OPEN_PDF, payload).then(response => {
        this.errorStr = null;
        
        if (response && response.data) {
          const responseData = response.data
          
        if (responseData.zusatzList /* && responseData.zusatzList.length */) {
            this.zusatzList = responseData.zusatzList;
            this.pictureAttachMsg = responseData.pictureAttachMsg;
            this.openZusatzDokModal();
          }  else if (responseData.signaturStatus === 'KOMPLETT') {
            this.$refs.komplett.open()
          } else if (responseData.signaturStatus === 'TEILWEISE') {
            this.signoListTemp = responseData.signoList;
            this.openModal(this.$refs.teilweiseModal, false); 
          } else if (responseData.signoList) {
            this.signoList = responseData.signoList;
            this.showSignoList();
          }
        }
      })
      .catch(err => {
        this.errorStr = err && err.message;
      })
      .finally(() => {
        this.loading.sign = false;
      })
    },   
    closeZusatzListModal() {
      this.$refs.zusatzDok.close();
      this.zusatzList = null;
      this.pictureAttachMsg = null;
      this.zusatzDokForm = {};
    },
   sendZusatzDoks() {
      let concatIds;
      if (this.zusatzDokForm) {
        concatIds = Object.keys(this.zusatzDokForm).filter(id => this.zusatzDokForm[id] === true);
      }
      if (this.antragData) {
        const payload = {
          concatIds: concatIds,
          dokId: this.antragData['dokId'],
          antragsnrIntern: this.antragData['antragsnrIntern']
        }
        this.$store.dispatch(ANTRAG_TYPES.ACTIONS.GET_ANTRAG_CONTRACT_TO_SIGN, payload).then(response => {
          if (response && response.data) {
            const responseData = response.data
            if (responseData.errorMessage) {
              this.errorStr = responseData.errorMessage;
            } else if (responseData.signoList) {
              this.signoList = responseData.signoList;
              this.showSignoList();
            }
          }
        }).catch(err => {
          this.errorStr = err && err.message;
        });
      }
      this.closeZusatzListModal();
    },    
    openZusatzDokModal() {
      if (this.zusatzList /* && this.zusatzList.length */) {
        this.zusatzDokForm = {}
        this.zusatzList.forEach(dok => this.zusatzDokForm[dok.id] = false);
        this.$refs.zusatzDok.open();
      }
    },  
    showSignoListFromTeilweise() {
      this.$refs.teilweise.close();
      this.signoList = this.signoListTemp;
    }, 
    showSignoList() {
      if (!this.signoList || !this.signoList.length) {
        this.errorStr = 'Keine Dokumente vorhanden';
      } else if (this.signoList.length === 1 && this.signoList[0].url) {
        this.openSignPdFile(this.signoList[0].url);
      }
    },  
    openSignPdFile(urlStr) {
      if (urlStr) {
        window.open(urlStr, "_blank");
      }
    },      
    /**
      * For new customers, the "öffnen" button should open the form in "create mode"
      */
    getFormLink(basePath, antragsnrIntern, protokollId) {
        let result = `${basePath}?hideBeratungsmappepopup=true&beratungsVersId=${this.$route.query.id}`
        if(antragsnrIntern) {
          if(this.beratungVersicherungenCards.vvwVertrag?.id === antragsnrIntern){
            result = `${result}&antragsdatenId=${antragsnrIntern}`;
          } else {
            result = `${result}&antragsnrIntern=${antragsnrIntern}`;
          }
        }
        if (protokollId) {
          result = `${result}&protokollId=${protokollId}`;
        }
        return result;
    },
    getBedarfsermittlungLink(antragsnrIntern) {
      return this.isFA ? this.getFormLinkBedarfserhebung(antragsnrIntern) : `/beratung/vergleichsrechner/${encodeURIComponent('Finanzportal24')}?path=/get_finanzportal24`

    },
    getFormLinkMaklerVollmacht(antragsnrIntern) {
      if (this.versAdminM) {
        const baseURL = 'VERSADMIN_M';
        const labelParameter = 'VersAdminM';
        let result = `/beratung/formulare/antrag/${baseURL}/maklervollmacht/maklervollmacht?lagerstelleLabel=${labelParameter}&beratungsVersId=${this.$route.query.id}&hideBeratungsmappepopup=true`;

        if(antragsnrIntern) {
          result = `${result}&antragsnrIntern=${antragsnrIntern}`;
        }
        return result;
      }
    },
    getFormLinkBeratungsDokuSach(antragsnrIntern) {
      if (this.versAdminM || this.versAdminA) {
        const baseURL = this.versAdminM ? 'VERSADMIN_M': 'VERSADMIN_A';
        const labelParameter = this.versAdminM ? 'VersAdminM': 'VersAdminA';
        let result =  `/beratung/formulare/antrag/${baseURL}/beratungsdokusach/versicherungsnehmer?lagerstelleLabel=${labelParameter}&beratungsVersId=${this.$route.query.id}&hideBeratungsmappepopup=true`;

        if(antragsnrIntern) {
          result = `${result}&antragsnrIntern=${antragsnrIntern}`;
        }
        return result;        
      }
    },
    getFormLinkBedarfserhebung(antragsnrIntern) {
      if (this.versAdminM || this.versAdminA) {
        const baseURL = this.versAdminM ? 'VERSADMIN_M': 'VERSADMIN_A';
        const labelParameter = this.versAdminM ? 'VersAdminM': 'VersAdminA';
        let result =  `/beratung/formulare/antrag/${baseURL}/bedarfserhebung?lagerstelleLabel=${labelParameter}&beratungsVersId=${this.$route.query.id}&hideBeratungsmappepopup=true`;

        if(antragsnrIntern) {
          result = `${result}&antragsnrIntern=${antragsnrIntern}`;
        }
        return result;        
      }
    },
    getFormLinkBeratungsDokuLV(antragsnrIntern) {
      if (this.versAdminM || this.versAdminA) {
        const baseURL = this.versAdminM ? 'VERSADMIN_M': 'VERSADMIN_A';
        const labelParameter = this.versAdminM ? 'VersAdminM': 'VersAdminA';
        let result = `/beratung/formulare/antrag/${baseURL}/beratungsdokulv/versicherungsnehme?lagerstelleLabel=${labelParameter}&beratungsVersId=${this.$route.query.id}&hideBeratungsmappepopup=true`;

        if(antragsnrIntern) {
          result = `${result}&antragsnrIntern=${antragsnrIntern}`;
        }
        return result;         
      }
    },
    getFormLinkErweitereGeldwaeschepruefung(antragsnrIntern) {
      if (this.versAdminM || this.versAdminA) {
        const baseURL = this.versAdminM ? 'VERSADMIN_M': 'VERSADMIN_A';
        const labelParameter = this.versAdminM ? 'VersAdminM': 'VersAdminA';
        let result = `/beratung/formulare/antrag/${baseURL}/geldwaesche/kundendaten?lagerstelleLabel=${labelParameter}&beratungsVersId=${this.$route.query.id}&hideBeratungsmappepopup=true`;

        if(antragsnrIntern) {
          result = `${result}&antragsnrIntern=${antragsnrIntern}`;
        }
        return result;        
      }
    },
    getFormLinkAuskunftsvollmacht(antragsnrIntern) {
      if (this.versAdminM || this.versAdminA) {
        const baseURL = this.versAdminM ? 'VERSADMIN_M': 'VERSADMIN_A';
        const labelParameter = this.versAdminM ? 'VersAdminM': 'VersAdminA';
        let result = `/beratung/formulare/antrag/${baseURL}/auskunftsvollmacht/auskunftsvollmacht?lagerstelleLabel=${labelParameter}&beratungsVersId=${this.$route.query.id}&hideBeratungsmappepopup=true`;

        if(antragsnrIntern) {
          result = `${result}&antragsnrIntern=${antragsnrIntern}`;
        }
        return result;          
      }
    },
    getFormLinkVersicherungsmaklervertrag(antragsnrIntern) {
       if (this.versAdminM) {
        const baseURL = 'VERSADMIN_M';
        const labelParameter = 'VersAdminM';
        let result = `/beratung/formulare/antrag/${baseURL}/vertragdaten/versicherungsmaklervertrag?lagerstelleLabel=${labelParameter}&beratungsVersId=${this.$route.query.id}&hideBeratungsmappepopup=true`;

        if(antragsnrIntern) {
          result = `${result}&antragsnrIntern=${antragsnrIntern}`;
        }
        return result;         
      }     
    },
    getFormLinkKSCNutzungsvereinbarung(antragsnrIntern) {
       if (this.versAdminM || this.versAdminA) {
        const baseURL = this.versAdminM ? 'VERSADMIN_M': 'VERSADMIN_A';
        const labelParameter = this.versAdminM ? 'VersAdminM': 'VersAdminA';
        let result =  `/beratung/formulare/antrag/${baseURL}/nutzungsvereinbarung/nutzungsvereinbarung?lagerstelleLabel=${labelParameter}&beratungsVersId=${this.$route.query.id}&hideBeratungsmappepopup=true`;

        if(antragsnrIntern) {
          result = `${result}&antragsnrIntern=${antragsnrIntern}`;
        }
        return result;          
      }      
    },
    getFormlinkDatenschutzerklaerung(antragsnrIntern) {
       if (this.versAdminM || this.versAdminA) {
        const baseURL = this.versAdminM ? 'VERSADMIN_M': 'VERSADMIN_A';
        const labelParameter = this.versAdminM ? 'VersAdminM': 'VersAdminA';
        let result =   `/beratung/formulare/antrag/${baseURL}/datenschutz/datenschutz?lagerstelleLabel=${labelParameter}&beratungsVersId=${this.$route.query.id}&hideBeratungsmappepopup=true`;

        if(antragsnrIntern) {
          result = `${result}&antragsnrIntern=${antragsnrIntern}`;
        }
        return result;          
      }
    },
    getBedarfsermittlungLinkText() {
      return this.isFA ? 'Öffnen' : 'Analyse-Tools anzeigen';
    },    
    showUnterschriftenModal() {
       this.$refs.unterschriftenModal.open();
    },
    updateLagerstelle(lagerstelle) {
      let parameters = {
          beratungsVersId: this.$route.query.id,
          beratungsMappeTyp: this.tempVerwende,
          lagerstelle: lagerstelle
        };

      this.$store.dispatch(
        BERATUNGSMAPPE_TYPES.ACTIONS.SAVE,
        parameters
      );
    },
    loadAllInsurances() {
      this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.GET_INSURANCE_FILTERS_DATA);
      this.handleSearch();
    },
    handleSearch(filters) {
      this.filtered = true;
      this.filtersObj = prepareFilters(filters);
      this.isLoading = true;
      this.requestPage(0, true);
    },
    mergeInsurances(insurances){
      if(insurances){
        this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.VERSICHERUNG_VEREINIGEN, 
        { insuranceToKeep: insurances.toKeep.vertragId, insuranceToRemove: insurances.toRemove.vertragId })
      }
    },
    openVertragVereinigenModal() {
      this.$refs.vertragVereinigenModalNew.open();
    },
    onSelectedRow(rows) {
      this.selectedRowsValues = rows;
    },     
    dokumenteAbholen() {
      this.$store.dispatch(BERATUNGSMAPPE_TYPES.ACTIONS.DO_DOWNLOAD_DOKUMENT_SMART_MSC).then(
        response => {
            this.dokumenteAbholenResult = response;

            this.$refs.dokumenteAbholenModal.open()          
        }
      );
    },
  },
  mounted() {
    this.loadAllInsurances();  
    this.getListExterneZugang();

  },
  beforeMount() {
    this.retrieveBeratungsmappeCards();
    this.retrieveAntragList();
  },
  beforeRouteLeave (to, from, next) {
    // this.overrideBreadcrumb = false;

    this.$addBreadcrumb({
      label: 'zurück zur Beratungsmappe', 
      to,
      from,
      breadcrumb: this.selectedAntrag || this.selectedBeratungsType || this.$route.query.art,
    });

    next()
  },
}
</script>

<style scoped>

.link-antrag {
  color: var(--color-link)
}
div.action-buttons > i {
  font-size: 1.8em;
}

.category {
  font-size: .75rem;
  text-transform: uppercase;
}

.category {
    position: absolute;
    top: 110px;
    left: 0;
    color: var(--color-box);
    background: #e74c3c;
    padding: 10px 15px;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
}
.beratung-edit__main {
  display: flex;
}
.beratung-edit__sidebar {
  flex: 1 0 auto;
  width: 480px;
}
.beratung-edit__content {
  width: calc(92% - 480px);
  margin-left: 28px;
}

.grayed-out {
  color: grey;
}

@media screen and (max-width: 1100px) {
  .beratung-edit__sidebar {
    width: 380px;
  }
  .beratung-edit__content {
    width: calc(92% - 380px);
  }
}

@media screen and (max-width: 870px) {
  .beratung-edit__sidebar {
    width: 300px;
  }
  .beratung-edit__content {
    width: calc(92% - 300px);
  }
}

@media screen and (max-width: 767px) {
  .beratung-edit__main {
    flex-direction: column;
  }

  .beratung-edit__sidebar {
    width: 100%;
  }
  .beratung-edit__content {
    margin-left: 0;
    width: 100%;
  }
}
</style>