<template>
<div>
    <PageHeaderTitleNavigation title="Datensatz zuordnen" />

    <div class="card__layout">
        <div class="card__item">
            <span class="box__title">
                {{title}}: {{formatName}} Datensatz zuordnen
            </span>

            <br>
            <br>

            <div v-if="fileName" class="info-header"><div>Dateiname</div> <div>{{fileName}}</div></div>
            <div v-if="foreignName" class="info-header"><div>Kundenname</div> <div>{{foreignName}}</div></div>
            <div v-if="foreignVorname" class="info-header"><div>Kundenvorname</div> <div>{{foreignVorname}}</div></div>
            <div v-if="foreignBirthName" class="info-header"><div>Kundengeburtsname</div> <div>{{foreignBirthName}}</div></div>
            <div v-if="foreignAddress" class="info-header"><div>Kundenanschrift</div> <div>{{foreignAddress}}</div></div>
            <div v-if="foreignBirthDate" class="info-header"><div>Kundengeburtsdatum</div> <div>{{foreignBirthDate}}</div></div>

            <InputToggleSwitch
                v-model="transferInsuranceNumber"
                :label="formatName + ' Versicherungsnummer übernehmen'"
                inLineLabel
            />
        </div>

        <div class="card__item">
            <InputField
                label="MSC Interne Nummer"
                v-model="mscInternalId"
            />

            <BaseButton @click="searchInsurances" isSecondary>
                Versicherungssuche
            </BaseButton>

            <BaseButton @click="buttonKundengesellZuordnen" isPrimary :disabled="assignMahnungAllowed || !mscInternalId">
                {{formatName}} KundenGesellNr. zuordnen
            </BaseButton>

            <hr>

            <BaseButton @click="buttonNewCustomer" isPrimary>
                Neuer Kunde
            </BaseButton>

            <InputField
                label="MSC Kundenname"
                v-model="customerNameSearch"
            />
            <BaseButton @click="searchCustomer" isPrimary>
                Suchen
            </BaseButton>

        </div>

        <div class="card__item">
            <div v-if="cause" class="info-header"><div>Fehlerursache</div> <div>{{cause}}</div></div>
            <div v-if="damageNumber" class="info-header"><div>Schaden-Nr.</div> <div>{{damageNumber}}</div></div>
            <div v-if="customerGesellId" class="info-header"><div>KundenGesellNr.</div> <div>{{customerGesellId}}</div></div>
            <div v-if="foreignPersonId" class="info-header"><div>FremdPersonNr.</div> <div>{{foreignPersonId}}</div></div>
            <div v-if="sparteNr" class="info-header"><div>Sparten-Nr.</div> <div>{{sparteNr}}</div></div>
            <div v-if="vtgSparte" class="info-header"><div>VTG-Sparte</div> <div>{{vtgSparte}}</div></div>
            <div v-if="insuranceId" class="info-header"><div>Versicherungsnummer</div> <div>{{insuranceId}}</div></div>
        </div>
    </div>    

    <div class="box__container" v-if="insuranceRows.length || customerRows.length " >
        <BigTable  v-if="insuranceRows.length"
            title="SmartMSC Vorschläge (Versicherungen)"
            :headers="insuranceHeaders"
            :rows="insuranceRows"
            :rowsPerPage="10"
            @click-kundennr="openCustomerNewTab"
            @action-CONTRACT="actionContract"
            @action-UNITE="actionUnite"
        >
            <template v-slot:datensatzZuordnen="row">  
                <PhArrowFatRight class="clickable" v-if="!assignMahnungAllowed" @click="kundengesellZuordnen(undefined, row.id)" :size="24"/>
            </template>            
            <template v-slot:street="row">  
                <div :class="{'green': row.street.green}">
                    {{row.street.text}}
                </div>
            </template>
            <template v-slot:plz="row">  
                <div :class="{'green': row.plz.green}">
                    {{row.plz.text}}
                </div>
            </template>
            <template v-slot:ort="row">  
                <div :class="{'green': row.ort.green}">
                    {{row.ort.text}}
                </div>
            </template>
            <template v-slot:birthdate="row">  
                <div :class="{'green': row.birthdate.green}">
                    {{row.birthdate.text}}
                </div>
            </template>
        </BigTable>
        <BigTable  v-if="customerRows.length"
            title="SmartMSC Vorschläge (Kunden)"
            :headers="customerHeaders"
            :rows="customerRows"
            :rowsPerPage="10"
            rowId="kundennr"
            @click-kundennr="openCustomerNewTab"
            @action-CONTRACT="actionContract"
        >
            <template v-slot:datensatzZuordnen="row">  
                <PhArrowFatRight class="clickable" v-if="customerGesellId" @click="kundengesellZuordnen(row.kundennr)" :size="24"/>
            </template>         
            <template v-slot:name="row">  
                <div :class="{'green': row.name.green}">
                    {{row.name.text}}
                </div>
            </template>
            <template v-slot:vorname="row">  
                <div :class="{'green': row.vorname.green}">
                    {{row.vorname.text}}
                </div>
            </template>
            <template v-slot:street="row">  
                <div :class="{'green': row.street.green}">
                    {{row.street.text}}
                </div>
            </template>
            <template v-slot:plz="row">  
                <div :class="{'green': row.plz.green}">
                    {{row.plz.text}}
                </div>
            </template>
            <template v-slot:ort="row">  
                <div :class="{'green': row.ort.green}">
                    {{row.ort.text}}
                </div>
            </template>
            <template v-slot:birthdate="row">  
                <div :class="{'green': row.birthdate.green}">
                    {{row.birthdate.text}}
                </div>
            </template>
        </BigTable>
    </div>
    <UniteContracts v-if="uniteInsurance" :insurance="uniteInsurance" @close="uniteInsurance=null"/>
</div>
</template>

<script>
import CORE_TYPES from '@/store/core/types'
import LOG_TYPES from '@/store/log/types';

import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import Table from "@/components/table2/Table.vue";
import BaseButton from '@/components/core/BaseButton.vue';
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import InputField from '@/components/core/forms/InputField.vue';
import UniteContracts from '@/components/bipro/UniteContracts.vue';
import { buildMessage } from "@/helpers/log-message-helper";

import { mapGetters } from 'vuex'
import axios from 'axios';
import {PhPencilLine, PhArrowFatRight, PhFilePlus} from 'phosphor-vue';

// import { PageHeaderSimpleAction, PageHeaderConfirmedAction} from '@/components/core/header-title-navigation/page-header-utils';

import BigTable, { CURRENCY_COLUMN, DATETIME_COLUMN, LINK_COLUMN  } from '@/components/bigTable/BigTable.vue';
import {STRING_COLUMN, ACTION_COLUMN, FIXED_LEFT, NUMBER_COLUMN, SLOT_COLUMN, DATE_COLUMN} from '@/components/bigTable/BigTable.vue';
import {SimpleAction, ConfirmedAction, DownloadLinkAction, SlotColumn} from "@/components/table2/table_util.js";


export function GreenColumn(key, label) {
    return SlotColumn(key, label, 200, 1)
    .makeSortable((obj) => textToSortable(obj.text))
    .withExporter((obj) => obj.text);
}

const config = {
    defaultSpinner: true,
};

export default {
    components: {
        OptionMenu,
        PageHeaderTitleNavigation,
        Table,
        BaseButton,
        InputToggleSwitch,
        InputField,
        UniteContracts,
        PhArrowFatRight,
        BigTable,
    },
    data() {
        return {
            customerHeaders: [
                { label: "Kundenname", key: "name", width: 100, type: SLOT_COLUMN, position: FIXED_LEFT },
                { label: "Kundenvorname", key: "vorname", width: 250, type: SLOT_COLUMN, visible: true },
                { label: "Kundennr", key: "kundennr", width: 250, type: STRING_COLUMN, visible: true },
                { label: "", key: "datensatzZuordnen", width: 150, type: SLOT_COLUMN, visible: true },
                { label: "Straße u. Hausnr.", key: "street", width: 250, type: SLOT_COLUMN, visible: true },
                { label: "PLZ", key: "plz", width: 250, type: SLOT_COLUMN, visible: true },
                { label: "Ort", key: "ort", width: 250, type: NUMBER_COLUMN, visible: true },
                { label: "Geburtsdatum", key: "birthdate", width: 150, type: DATETIME_COLUMN, visible: true },
                { label: "Maklernr", key: "maklernr", width: 150, type: DATETIME_COLUMN, visible: true },
                { label: "Maklername", key: "makler", width: 250, type: STRING_COLUMN, visible: true },
                { label: "Kunden GesellNr.", key: "gesellnr", width: 250, type: STRING_COLUMN, visible: true },
                { label: "Aktionen", key: "actions", width: 120, type: ACTION_COLUMN, visible: true },
            ],
            insuranceHeaders: [
                { label: "Interne Nr", key: "id", width: 100, type: STRING_COLUMN, position: FIXED_LEFT },
                { label: "", key: "datensatzZuordnen", width: 50, type: SLOT_COLUMN, position: FIXED_LEFT },
                { label: "Gesellschaft", key: "gesellschaft", width: 100, type: STRING_COLUMN, visible: true},
                { label: "Vers. Nr.", key: "insuranceIdValue", width: 250, type: STRING_COLUMN, visible: true, backgroundColor:  'var(--color-success)', colorIf: (row) => row.insuranceId.green },
                { label: "Prod. Bezeichnun", key: "description", width: 250, type: STRING_COLUMN, visible: true },
                { label: "Sparte", key: "sparte", width: 250, type: STRING_COLUMN, visible: true },
                { label: "Beginn", key: "begin", width: 250, type: DATE_COLUMN, visible: true },
                { label: "Beitrag", key: "beitrag", width: 250, type: CURRENCY_COLUMN, visible: true },
                { label: "Status", key: "status", width: 250, type: STRING_COLUMN, visible: true },
                { label: "Kundenname", key: "nameValue", width: 250, type: STRING_COLUMN, visible: true, backgroundColor:  'var(--color-success)', colorIf: (row) => row.name.green},
                { label: "Kundenvorname", key: "vornameValue", width: 250, type: STRING_COLUMN, visible: true, backgroundColor:  'var(--color-success)', colorIf: (row) => row.vorname.green },
                { label: "Kundennr", key: "kundennr", width: 250, type: LINK_COLUMN, visible: true },
                { label: "Straße u. Hausnr.", key: "street", width: 250, type: SLOT_COLUMN, visible: true },
                { label: "PLZ", key: "plz", width: 250, type: SLOT_COLUMN, visible: true },
                { label: "Ort", key: "ort", width: 250, type: SLOT_COLUMN, visible: true },
                { label: "Geburtsdatum", key: "birthdate", width: 250, type: SLOT_COLUMN, visible: true },
                { label: "Maklernr", key: "maklernr", width: 250, type: STRING_COLUMN, visible: true },
                { label: "Maklername", key: "makler", width: 250, type: STRING_COLUMN, visible: true },
                { label: "Weitere Personen/Gegenstand", key: "weitere", width: 250, type: STRING_COLUMN, visible: true },
                { label: "Kunden GesellNr.", key: "gesellnr", width: 250, type: STRING_COLUMN, visible: true },
                { label: this.isFA ? "OMDS aktualisiert" : "GDV aktualisiert", key: "current", width: 250, type: DATE_COLUMN, visible: true },
                { label: "Aktionen", key: "actions", width: 120, type: ACTION_COLUMN, visible: true },                
            ],                
            insurances: null,
            customers: null,

            title: null,
            assignMahnungAllowed: false,

            fileName: null,
            foreignName: null,
            foreignVorname: null,
            foreignBirthName: null,
            foreignAddress: null,
            foreignStrasse: null,
            foreignOrt: null,
            foreignPLZ: null,
            foreignBirthDate: null,
            cause: null,
            damageNumber: null,
            customerGesellId: null,
            foreignPersonId: null,
            sparteNr: null,
            vtgSparte: null,
            insuranceId: null,

            transferInsuranceNumber: false,
            customerNameSearch: "",

            mscInternalId: "",
            uniteInsurance: null,
            insuranceRows: [],
            customerRows: [],
        }
    },
    computed: {
        ...mapGetters({
            isFA: CORE_TYPES.GETTERS.IS_FA,
        }),
		formatName() {
			return this.isFA ? "OMDS" : "GDV";
		},
    },
    mounted() {
        if (typeof(this.$route.query.insurance) == "object")
            this.mscInternalId = this.$route.query.insurance.id || "";
        
        this.loadData(null);
    },
    methods: {
        loadData(name) {
            let nameParam = "";
            if (name !== null)
                nameParam = `&name=${name}`;
            axios.get(`${process.env.VUE_APP_API}/bipro_import/datensatz_zuordnen?id=${this.$route.query.id}` + nameParam)
            .then(response => {
                this.title = response.data.title;
                this.assignMahnungAllowed = response.data.assignMahnungAllowed;

                // table rows
                this.insurances = response.data.insurances;
                this.customers = response.data.customers;

                this.insuranceRows = (this.insurances || []).map(row => {
                    const actions = [
                        SimpleAction("CONTRACT", PhPencilLine, "Versicherungsvertrag öffnen"),
                        SimpleAction("UNITE", PhFilePlus, "Diesen Vertrag vereinigen"),
                    ];
                    return {
                        ...row,
                        insuranceIdValue: row?.insuranceId?.text,
                        nameValue: row?.name?.text,
                        vornameValue: row?.vorname?.text,                        
                        actions,
                    }
                });                


                this.customerRows =  (this.customers || []).map(row => {
                    const actions = [
                        SimpleAction("CONTRACT", PhFilePlus, "Neuen Vers. Vertrag anlegen"),
                    ];
                    return {
                        ...row,
                        nameValue: row?.name?.text,
                        vornameValue: row?.vorname?.text,
                        actions,
                    }
                });

                // information displayed above the tables
                this.fileName = response.data.fileName;
                this.foreignName = response.data.foreignName;
                this.foreignVorname = response.data.foreignVorname;
                this.foreignBirthName = response.data.foreignBirthName;
                this.foreignAddress = response.data.foreignAddress;
                this.foreignStrasse = '';
                this.foreignOrt = '';
                this.foreignPLZ = '';
                const adr = this.foreignAddress ? this.foreignAddress.split(', ') : [];
                if (adr.length === 3) {
                    this.foreignStrasse = adr[2];
                    this.foreignOrt = adr[1];
                    this.foreignPLZ = adr[0];
                }
                this.foreignBirthDate = response.data.foreignBirthDate;
                this.cause = response.data.cause;
                this.damageNumber = response.data.damageNumber;
                this.customerGesellId = response.data.customerGesellId;
                this.foreignPersonId = response.data.foreignPersonId;
                this.sparteNr = response.data.sparteNr;
                this.vtgSparte = response.data.vtgSparte;
                this.insuranceId = response.data.insuranceId;
            });
        },
        actionContract(row) {
            this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, {
                customerId: row.kundennr,
                insurances: true,
                path: `/home/versicherungen/insurance-group/details/${row.id}`
            });
        },
        actionUnite(row) {
            this.uniteInsurance = row.id;
        },
        searchInsurances() {

            this.$router.push({
                path: "/home/versicherungsfilter/insurance-group",
            });
        },
        buttonKundengesellZuordnen() {
            this.kundengesellZuordnen();
        },
        kundengesellZuordnen(kundennr, mscInternalId) {
            const params = {
                docId: this.$route.query.id,
                mscInternalId: mscInternalId || this.mscInternalId,
                insuranceId: this.insuranceId,
                setPoliceNr: this.transferInsuranceNumber,
                kundennr,
            };
            axios.post(`${process.env.VUE_APP_API}/bipro_import/assign_gdv_kunde`, params, config)
            .then(response => {
                if (response?.data)
                    this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.data, 'success'));
                this.$router.push({path: "/intern/gdv/import"});
            }).catch(error => {
                const fehlertext = error.response?.headers?.fehlertext;                
                if (fehlertext) {
                    this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(fehlertext, 'danger'));
                }
            })
        },
        buttonNewCustomer() {
            this.$addBreadcrumb({
              label: `zurück`,
              fullPath: this.$route.fullPath,
              breadcrumb: 'Datensatz zuordnen',
            });
            let query = '';
            if (this.foreignName) {
                query += '&lastName=' + this.foreignName;
            }
            if (this.foreignVorname) {
                query += '&firstName=' + this.foreignVorname;
            }
            if (this.foreignBirthDate) {
                query += '&dayOfBirth=' + this.foreignBirthDate;
            }
            if (this.foreignPLZ) {
                query += '&zip=' + this.foreignPLZ;
            }
            if (this.foreignOrt) {
                query += '&city=' + this.foreignOrt;
            }
            if (this.foreignStrasse) {
                query += '&street=' + this.foreignStrasse;
            }
            if (query) {
                query = '?' + query.substring(1);
            }
            this.$router.push({
                path: "/customer/maklersuche",
                query: {
                    bipro: true,
                    nextUrl: `customer/kunde-anlegen${query}`
                }
            });
        },
        searchCustomer() {
            this.loadData(this.customerNameSearch);
        },
        openCustomerNewTab(row) {
            this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, { customerId: row.kundennr })
        },
    },
    beforeRouteLeave(to, from, next) {
        this.$addBreadcrumb({ 
            label: `zurück zu ${this.$route.meta?.breadcrumb?.label}`,
                fullPath: this.$route.fullPath,
                breadcrumb: "Datensatz zuordnen",            
         });



        next();
    },    
}
</script>


<style scoped>
.info-header {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
}
.info-header > :first-child {
    flex-basis: 200px;
    font-weight: bold;
}
.info-header > :nth-child(2) {
    flex: 1;
}

.green {
    background-color: var(--color-success)
}

.card__layout {
    display: flex;
    flex-wrap: wrap;
    column-gap: 8px;
    row-gap: 8px;
}

.card__item {
    box-shadow: 0px 4px 16px rgba(85, 85, 85, 0.1);
    min-width: calc(50% - 8px);
    /* margin-right: 8px; */
    padding: 8px;
}


</style>
