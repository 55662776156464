<template>
  <div :key="componentKey">
    <DashboardPanel
      id="f8ec5a24-4fe6-453f-90b2-3378ca2c4b28"
      :subtitle="depotTitle"
      :data="dashboardData"
      :noPrimaryAction="false"
      :headerActions="headerActions"
      @widgetsActived="handleWidgetsActivated"
      @executeAction="handleAction"
    >
      <template #Wertpapiere>
        <div class="box__container-wrap">
          <div class="box__container-rows">
            <div class="row" v-if="isWertpapiere">
              <div class="col-12" :class="{'d-flex justify-content-end' : !isMobileNativeContext}">
                <div class="tbl" v-if="!isMobileNativeContext">
                  <div class="tbl-row">
                    <div class="tbl-cell text-right text-small" v-if="showSaldozeile">G&V</div>
                    <div class="tbl-cell text-right text-small" v-if="hasPerformance">Wertentwicklung</div>
                    <div class="tbl-cell text-right text-small">Depotbestand</div>
                  </div>
                  <div class="tbl-row">
                    <div class="tbl-cell text-right text-small" v-if="showSaldozeile" :class="getClassValueAmount(GandV)"><CurrencyLabel :value="GandV"/></div>
                    <div v-if="hasPerformance" class="tbl-cell text-right text-small" :class="{ 'd-flex': !$isExtraSmallScreen, }">
                      <PercentLabel 
                        v-if="performance.hasTotal" 
                        :value="performance.total" 
                        :info="$isExtraSmallScreen? 'ges.' : 'gesamt'" 
                        :precision="performanceDecimalPrecision" 
                      />
                      <span v-if="!$isExtraSmallScreen && performance.hasTotal && performance.hasPA" class="mx-1">/</span>
                      <PercentLabel 
                        v-if="performance.hasPA" 
                        :value="performance.pa" 
                        info="p.a." 
                        :precision="performanceDecimalPrecision" 
                      />
                    </div>
                    <div class="tbl-cell text-right text-small"><CurrencyLabel :value="bestand"/></div>
                  </div>
                </div>
                <div v-else>
                  <TableMobile
                    ref="wertpapiereTable"
                    tableId="9d2d3b33-f6fb-4a01-84fe-2cbb499b6702"
                    rowId="id"
                    cardView
                    hidePagination
                    :headers="mobileWertpapierTable.headers"
                    :rows="mobileWertpapierTable.rows"
                  />
                </div>
              </div>
            </div>
            <div class="row mb-1" v-if="!isMobileNativeContext">
              <div class="col-12" v-if="hasDataEChartWertpapiere">
                <LineChart v-if="hasDataEChartWertpapiere" 
                  :chartData="eChartWertpapiereData" 
                  :customTooltipFormatter="wertpapiereTooltipFormatter" 
                  height="470px" 
                  :gridLeft="40"/>
              </div>
              <div class="col-12 text-centered" v-if="!hasDataEChartWertpapiere">
                Keine Daten
              </div>
            </div>
          </div>
          <div class="bottom-row" v-if="!isMobileNativeContext">
            <div class="d-flex justify-content-end">
              <router-link to="/home/berichte/transaktionen" class="mr-24">Transaktionen</router-link>
              <router-link to="/home/berichte/performance">Wertentwicklung</router-link>
            </div>
          </div>
        </div>
      </template>

      <template #Risikoverteilung>
        <div class="box__container-wrap">
          <div class="box__container-rows">
            <div>
              <Risikoverteilung v-if="!showRiskoverteilungDetails" :displayAnlegerprofilLink="!isMobileNativeContext"/>
              <div v-if="showRiskoverteilungDetails && !isMobileNativeContext">
                <p>Die durchschnittliche Risikoverteilung (GSRI 1-7) Ihres Depots errechnet sich aus dem gewichteten Durchschnitt aller einzelnen gewichteten Risikokennzoffern (SRI 1-7) der einzelnen Wertpapierpositionen.
                <br/>Dabei stellt SRI 1 ein geringens Risiko und SRI 7 ein sehr hohes Risiko dar</p>
                
                <dl class="row m-0">
                  <dt class="pl-0 col-md-1">SRI 1:</dt>
                  <dd class="pl-0 col-md-11">Wertpapiere mit sehr geringer Schwankungsbreite (kleiner als 0,5% p.a.).</dd>
                  <dt class="pl-0 col-md-1">SRI 2:</dt>
                  <dd class="pl-0 col-md-11">Wertpapiere mit geringer Schwankungsbreite (zwischen 0,5% und 5% p.a.).</dd>
                  <dt class="pl-0 col-md-1">SRI 3:</dt>
                  <dd class="pl-0 col-md-11" style="white-space: nowrap;">Wertpapiere mit geringer bis mittlerer Schwankungsbreite (zwischen 5% und 12% p.a.).</dd>
                  <dt class="pl-0 col-md-1">SRI 4:</dt>
                  <dd class="pl-0 col-md-11">Wertpapiere mit mittlerer Schwankungsbreite (zwischen 12% und 20% p.a.).</dd>
                  <dt class="pl-0 col-md-1">SRI 5:</dt>
                  <dd class="pl-0 col-md-11">Wertpapiere mit erhöhter Schwankungsbreite (zwischen 20% und 30% p.a.).</dd>
                  <dt class="pl-0 col-md-1">SRI 6:</dt>
                  <dd class="pl-0 col-md-11">Wertpapiere mit hoher Schwankungsbreite (zwischen 30% und 80% p.a.).</dd>
                  <dt class="pl-0 col-md-1">SRI 7:</dt>
                  <dd class="pl-0 col-md-11">Wertpapiere mit sehr hoher Schwankungsbreite (höher als 80% p.a.).</dd>
                </dl>
              </div>

              <BaseModal ref="risikoverteilungMobileModal" 
                v-if="isMobileNativeContext" 
                :actions="risikoverteilungModalActions"
                :showDefaultButtons="false"
                @action-ANLEGERPROFIL="navigateToAnlegerprofil"
              >
                <template #modalTitle>
                  <div class="dashboard-panel-config-modal--title-container clearfix">
                    <span class="dashboard-panel-config-modal--title" style="font-size: 14px">Details zu den Risikoziffern</span>
                  </div>
                </template>
                <div>
                <p>Die durchschnittliche Risikoverteilung (GSRI 1-7) Ihres Depots errechnet sich aus dem gewichteten Durchschnitt aller einzelnen gewichteten Risikokennzoffern (SRI 1-7) der einzelnen Wertpapierpositionen.
                <br/>Dabei stellt SRI 1 ein geringens Risiko und SRI 7 ein sehr hohes Risiko dar</p>
                
                <div>
                  <div class="d-flex divisorLine justify-content-between align-items-center">
                    <div class="pl-0 col-2 col-xs-2 text-bold">SRI 1</div>
                    <div class="pl-0 col-md-9 text-right " style="overflow-wrap: break-word">Sehr geringer Schwankungsbreite <br>(kleiner als 0,5% p.a.).</div>
                  </div>

                  <div class="d-flex divisorLine justify-content-between align-items-center">
                    <div class="pl-0 col-2 col-xs-2 text-bold">SRI 2</div>
                    <div class="pl-0 col-md-9 text-right " style="overflow-wrap: break-word">Geringe Schwankungsbreite <br>(zwischen 0,5% und 5% p.a.).</div>
                  </div>

                  <div class="d-flex divisorLine justify-content-between align-items-center">
                    <div class="pl-0 col-2 col-xs-2 text-bold">SRI 3</div>
                    <div class="pl-0 col-md-9 text-right " style="overflow-wrap: break-word">Geringe bis mittlerer Schwankungsbreite <br>(zwischen 5% und 12% p.a.).</div>
                  </div>

                  <div class="d-flex divisorLine justify-content-between align-items-center">
                    <div class="pl-0 col-2 col-xs-2 text-bold">SRI 4</div>
                    <div class="pl-0 col-md-9 text-right " style="overflow-wrap: break-word">Mittlere Schwankungsbreite <br>(zwischen 12% und 20% p.a.).</div>
                  </div>

                  <div class="d-flex divisorLine justify-content-between align-items-center">
                    <div class="pl-0 col-2 col-xs-2 text-bold">SRI 5</div>
                    <div class="pl-0 col-md-9 text-right " style="overflow-wrap: break-word">Erhöhte Schwankungsbreite <br>(zwischen 20% und 30% p.a.).</div>
                  </div>

                  <div class="d-flex divisorLine justify-content-between align-items-center">
                    <div class="pl-0 col-2 col-xs-2 text-bold">SRI 6</div>
                    <div class="pl-0 col-md-9 text-right " style="overflow-wrap: break-word">Hohe Schwankungsbreite <br>(zwischen 30% und 80% p.a.).</div>
                  </div>

                  <div class="d-flex divisorLine justify-content-between align-items-center">
                    <div class="pl-0 col-2 col-xs-2 text-bold">SRI 7</div>
                    <div class="pl-0 col-md-9 text-right " style="overflow-wrap: break-word">Sehr hoher Schwankungsbreite <br>(höher als 80% p.a.).</div>
                  </div>

                </div>
              </div>
              </BaseModal>
            </div>
          </div>
          <div class="bottom-row" v-if="!isMobileNativeContext">
            <div class="d-flex justify-content-end">
              <a @click="toggleRiskoverteilungInfo()" v-show="!showRiskoverteilungDetails">Details zu den Risikoziffern</a>
              <a @click="toggleRiskoverteilungInfo()" v-show="showRiskoverteilungDetails">Übersicht</a>
            </div>
          </div>
        </div>
      </template>

      <template #Versicherungen>
        <div class="box__container-wrap">
          <div class="box__container-rows">
             <TableMobile
              v-if="isInsurances && isMobileNativeContext"
              ref="insuranceTable"
              tableId="e6c786cb-0dca-4578-8c11-e26f080a9706"
              rowId="id"
              hidePagination
              :mobileConfig="mobileInsuranceTable.mobileConfig"
              :headers="mobileInsuranceTable.headers"
              :rows="mobileInsuranceTable.rows"
            />
            <Table
              v-else-if="isInsurances && !isMobileNativeContext"
              :tableData="insurances"
              :cardViewEnabled="false"
              :exportEnabled="false"
              :paginationEnabled="false"
              :lockedColumns="insuranceLockedColumns"   
            />              
            <div v-else>
              Keine Versicherungen vorhanden
            </div>
          </div>

          <div v-if="isInsurances && !isMobileNativeContext" class="bottom-row">
            <div class="d-flex justify-content-end">
              <router-link to="/home/versicherungen">Übersicht</router-link>
            </div>
          </div>
        </div>
      </template>    
    </DashboardPanel>
  </div>
</template>

<script>
import CORE_TYPES from "@/store/core/types";
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import STECKBRIEF_TYPES from '../../components/steckbrief/store/types';
import OPEN_SIGNS_TYPES from '@/store/openSigns/types';
import { mapGetters } from 'vuex';
import FinancialCalculator from '@/components/retirementScenario/financialCalculator';
import CurrencyLabel from '@/components/core/CurrencyLabel.vue';
import PercentLabel from '@/components/core/PercentLabel.vue';
import {insuranceCategoriesDisplayName, getInsurancePeriod} from '@/components/insurance/utils.js'
import { ROLES } from '@/router/roles';
import Table from '@/components/table/Table.vue';
import TableMobile from "@/components/table2/Table.vue";
import BALANCE_SHEET_TYPES from '@/store/balance-sheet/types';
import LineChart from '@/components/charts/echarts/LineChart.vue'
import dayjs from 'dayjs'
import DEPOTPOSITIONS_TYPES from '@/store/depotpositions/types';
import { PageHeaderPortfolioSelectorAction, } from '@/components/core/header-title-navigation/page-header-utils';
import FC_CONFIG_TYPES from '@/store/fcConfig/types';
import FC_CONFIG, { FC_CONFIG_USER_LEVEL } from '@/configs/fcConfig.js';
import { findConfiguredMenuActives, } from '@/components/core/option-menu/option-menu-utils';
import BRIDGE_TYPES from '@/store/bridge/types';
import {CurrencyColumn, PercentageColumn} from "@/components/table2/table_util.js";
import BaseModal from '@/components/core/BaseModal.vue'
import { BaseModalSimpleAction } from '@/components/core/base-modal-actions/base-modal-actions-utils';

export default {
  components: {
    OptionMenu,
    CurrencyLabel,
    PercentLabel,
    Risikoverteilung: () => import('@/components/risikoverteilung/Risikoverteilung.vue'),
    DashboardPanel: () => import('@/components/dashboard/DashboardPanel.vue'),
    FondsFinderWidget: () => import('@/components/fondsFinderWidget/FondsFinderWidget.vue'),
    Table,
    TableMobile,
    LineChart,
    BaseModal,
  },
  mixins: [],
  beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({
      label: 'zurück zum Dashboard', 
      to,
      from,
    });

    next();
  },
  data() {
    return {
      insuranceLockedColumns: {
        toLeft: [
          { key: 'kategorie', neverHide: true },
          
        ],
        toRight: [
          { key: 'beitragMonat', neverHide: true },
          { key: 'beitrageJahr', neverHide: true },
        ],
      },
      showRiskoverteilungInfo: false,
      componentKey: 0,
      chartWertpapiereDataLength: 0,
      isChartDataLoaded: false,
      widgetsActivated: null,
      thereIsInsurances: true,
    }
  },

  methods: {
    getClassValueAmount(value) {
      return value ? (value < 0 ? 'negative-number' : 'positive-number') : ''
    },
    toggleRiskoverteilungInfo() {
      this.showRiskoverteilungInfo = !this.showRiskoverteilungInfo;
    },
    handleWidgetsActivated($event) {
      if($event?.length) {
        this.widgetsActivated = $event;
      }
    },
    wertpapiereTooltipFormatter(params) {
      const { marker, value, axisValueLabel } = params?.[0] || {};
      const formattedValue = this.yValue(value)?.toLocaleString('de-DE', {
        style: 'currency',
        currency: 'EUR',
      });

      const label = axisValueLabel ? dayjs(axisValueLabel).format("DD.MM.YYYY") : '';

      return `
        <div>
          <div class="tooltip-series-label">
            <div><span>${marker}</span>${label}:<wbr>
              <span style="white-space: nowrap;"><b>${formattedValue}</b></span>
            </div>
          </div>
        </div>`;
    },
    yValue(value) {
      return Array.isArray(value) ? value[1] : value;
    },
    handleAction(event) {
      if(event?.action?.action === 'BEARBEITEN_APP') {
        if(event?.action?.widget?.name === 'Versicherungen') {
          this.$router.push({ path: '/home/versicherungen/insurance-group' });
        }
        if(event?.action?.widget?.name === 'Risikoverteilung') {
          this.$refs.risikoverteilungMobileModal.open();
        }
        if(event?.action?.widget?.name === 'MultibankingOverview') {
          this.$router.push({ path: '/home/multibanking-account' });
        }
        if(event?.action?.widget?.name === 'EinnahmenUndAusgaben') {
          this.$router.push({ path: '/home/multibanking-account/einnahmen-und-ausgaben' });
        }
        if(event?.action?.widget?.name === 'SavingsPotential') {
          this.$router.push({ path: '/home/multibanking-account/monatliches-sparpotential'})
        }
        if(event?.action?.widget?.name === 'Diagramme') {
          this.$router.push({ path: '/home/berichte/diagramme' });
        }
        if(event?.action?.widget?.name === 'TicketsNachrichtenCard') {
          this.$router.push({ path: '/communication/postfach/chats' });
        }        
      }
    },
    navigateToAnlegerprofil() {
      this.$router.push({ path: '/beratung/anlegerprofil/person1/anlageziele/anlageziele' });
    },
    loadAbsenderDepotauszug() {
      const payload = [
        {
          configId: 'ABSENDER_DEPOTAUSZUG',
          configType: FC_CONFIG.ADVANCED_PARAMETER,
          forceReload: true,
        },
      ];
      this.$store.dispatch(FC_CONFIG_TYPES.ACTIONS.LOAD_FC_CONFIG, payload);
    },
  },

  computed: {
    ...mapGetters({
      insuranceTable: BALANCE_SHEET_TYPES.GETTERS.INSURANCE_TABLE,
      userPictureUrl: CORE_TYPES.GETTERS.GET_USER_PICTURE_URL,
      fullname: CORE_TYPES.GETTERS.GET_USER_FULL_NAME,
      isIntern: CORE_TYPES.GETTERS.IS_INTERN,
      isByPass: CORE_TYPES.GETTERS.IS_BYPASS,
      isBroker: CORE_TYPES.GETTERS.IS_BROKER,
      isCustomer: CORE_TYPES.GETTERS.IS_CUSTOMER,
      userId: CORE_TYPES.GETTERS.GET_USER_ID,
      videoIndent: STECKBRIEF_TYPES.GETTERS.TODO_VIDEO_INDENT,
      openSignatures: OPEN_SIGNS_TYPES.GETTERS.OPEN_SIGNS,
      depotpositions: STECKBRIEF_TYPES.GETTERS.GET_DEPOTPOSITIONS,
      investmentBestand: STECKBRIEF_TYPES.GETTERS.GET_INVESTMENT_BESTAND,
      multibanking: STECKBRIEF_TYPES.GETTERS.GET_MULTIBANKING_DATA,
      simplyChartGV: STECKBRIEF_TYPES.GETTERS.SIMPLY_CHART_GV,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      depotid: DEPOTPOSITIONS_TYPES.GETTERS.DEPOT_ID,
      isSmallScreen: CORE_TYPES.GETTERS.IS_SMALL_SCREEN,
      fcConfigOptionMenu: FC_CONFIG_TYPES.GETTERS.GET_FC_CONFIG_OPTION_MENU,
      depotTitle: DEPOTPOSITIONS_TYPES.GETTERS.SELECTED_DEPOT_TITLE,
      isMobileNativeContext: BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT,
      hasAccess: CORE_TYPES.GETTERS.HAS_ACCESS,
      advancedParameter: FC_CONFIG_TYPES.GETTERS.GET_FC_CONFIG_ADVANCED_PARAMETER,   
    }),
    absenderDepotauszug() {
        return this.advancedParameter?.['ABSENDER_DEPOTAUSZUG']?.content;
    },
    dashboardData() {
      return {
        widgets: [
          {
            name: 'BalanceSheetView',
            title: 'Vermögensübersicht',
            displayBoxContainer: !this.isMobileNativeContext,
            component: () => import('@/components/balance-sheet/BalanceSheetView.vue'),
            isNavigable: false
          },
          {
            name: 'Wertpapiere',
            title: 'Depotbestand',
            loading: () => !this.isChartDataLoaded,
            isNavigable: false
          },
          {
            name: 'Risikoverteilung',
            title: 'Risikoverteilung',
            //titleComponent: this.isMobileNativeContext ? () => import('@/components/dashboard/cards/CardTitleNavigator.vue') : null,
          },
          {
            name: 'Versicherungen',
            title: 'Versicherungen',
            //titleComponent: this.isMobileNativeContext ? () => import('@/components/dashboard/cards/CardTitleNavigator.vue') : null,
            visible: () => this.hasAccess('/home/versicherungen'),
          },
          {
            name: 'MultibankingOverview',
            title: 'Übersicht externe Konten',
            titleComponent: () => import('@/components/multiBanking/AccountListDashboardCardTitle.vue'),
            //titleComponent: this.isMobileNativeContext ? () => import('@/components/dashboard/cards/CardTitleNavigator.vue') : null,
            visible: () => this.hasRoles(ROLES.MULTIBANKING_DATA_ALLOWED) && this.isMultiBankingMenuSetAsVisible,
            component: () => import('@/components/multiBanking/AccountListDashboardCard.vue'),
          },
          {
            name: 'EinnahmenUndAusgaben',
            title: 'Einnahmen & Ausgaben',
            //titleComponent: this.isMobileNativeContext ? () => import('@/components/dashboard/cards/CardTitleNavigator.vue') : null,
            visible: () => this.hasRoles(ROLES.MULTIBANKING_DATA_ALLOWED) && this.isMultiBankingMenuSetAsVisible,
            component: () => import('@/components/multiBanking/EinnahmenUndAusgabenDashboardCard.vue'),
          },
          {
            name: 'SavingsPotential',
            title: 'Monatliches Sparpotential',
            //titleComponent: this.isMobileNativeContext ? () => import('@/components/dashboard/cards/CardTitleNavigator.vue') : null,
            visible: () => this.hasRoles(ROLES.MULTIBANKING_DATA_ALLOWED) && this.isMultiBankingMenuSetAsVisible,
            component: () => import('@/components/multiBanking/SavingsPotentialDashboardCard.vue'),
          },
          {
            name: 'Diagramme',
            title: 'Diagramme',
            // titleComponent: () => import('@/components/depotpositions/DepotpositionsChartsWidget.vue'),
            component: () => import('@/components/depotpositions/DepotpositionsChartsWidget.vue'),
          },
          {
            name: 'Marktbericht',
            title: 'Marktbericht',
            component: () => import('@/components/marktbericht/Marktbericht.vue'),
            size: 100,
            isNavigable: false
          },
          {
            name: 'TicketsNachrichtenCard',
            title: 'Nachrichten',
            component: () => import('@/components/communication/TicketsNachrichtenCard.vue'),
            props: {
              showTitle: false,
            },
          },          
          {
            name: 'Marktubersicht',
            title: 'Marktübersicht',
            component: () => import('@/components/marktubersicht/Marktubersicht.vue'),
            props: {
              showYearFilter: false,
              showBrushChart: false,
              showDetailLink: true,
            },
            isNavigable: false
          },
          {
            name: 'AgentContact',
            title: this.absenderDepotauszug ? this.absenderDepotauszug : 'Mein Betreuer',
            component: () => import('@/components/agentContact/AgentContact.vue'),
            isNavigable: false
          },
        ],
      }
    },
    headerActions() {
      return [
        PageHeaderPortfolioSelectorAction('SELECT-DEPOT'),
      ];
    },
    optionMenuConfig() { // TODO improve the way to check if a menu is active or not
      return this.fcConfigOptionMenu?.[this.$appNavigation?.currentOptionMenuId] || {};
    },
    activeOptionMenuConfig() {
      if(!this.optionMenuConfig?.content) {
        return this.$appNavigation?.currentOptionMenu || [];
      }
      let menuConfigList = []
      try {
        menuConfigList = JSON.parse(this.optionMenuConfig?.content)
        if (!Array.isArray(menuConfigList)) {
          return []
        }
      } catch (error) {
        return []
      }
      return findConfiguredMenuActives(menuConfigList)
    },
    isMultiBankingMenuSetAsVisible() {
      return this.activeOptionMenuConfig?.findIndex?.(act => act.path === '/home/multibanking-account') > -1;
    },
    showRiskoverteilungDetails() {
      return this.showRiskoverteilungInfo;
    },
    mobileInsuranceTable() {
      const steckBriefInsurances = this.insuranceTable?.data
      if (this.widgetsActivated?.length && !this.widgetsActivated.includes('BalanceSheetView') && !steckBriefInsurances?.records?.length && this.thereIsInsurances) {
        this.$store.dispatch(BALANCE_SHEET_TYPES.ACTIONS.GET_INSURANCE_TABLE, {id: this.depotid}).then(response => {
          if(!response?.data?.records?.length) {
            this.thereIsInsurances = false;
          }
        })
        return;
      }
      const labelsMap = insuranceCategoriesDisplayName;
      const records =  steckBriefInsurances.records.map(row => {
       return row.map((v, i) => ({
          [steckBriefInsurances.tableHeaders[i].key]: v
        })).reduce((acc, curr) => ({...acc, ...curr}), {})
      })
      const sumAndConvertToNumber = (values) => parseFloat(values.reduce((sum, curr) => 
      sum + FinancialCalculator.convertStringToNumber(curr.praemie) * getInsurancePeriod(curr.zw),0).toFixed(2));

      const groupedByCategory = records.reduce((acc, curr) => ({...acc, [curr.kategorie] : (acc[curr.kategorie] || []).concat(curr) }), {})
      const summary = Object.entries(groupedByCategory)
      .reduce((acc, [kategorie, values]) => {
          const beitragJahr = sumAndConvertToNumber(values);
          return ({...acc, [kategorie]: {kategorie: labelsMap[kategorie], beitrageJahr: beitragJahr, beitragMonat: beitragJahr/12 }})
        },{});

      const order = ['Gesundheit und Existenz', 'Betrieb', 'Besitz und Eigentum', 'Vorsorge'];
      const insurances = Object.values(summary).sort((a, b) => order.indexOf(a.kategorie) - order.indexOf(b.kategorie))
      insurances.forEach(insurance => {
        switch(insurance.kategorie){
          case order[0]: insurance.kategorie = 'Gesundheit'; break;
          case order[1]: insurance.kategorie = 'Betrieblich'; break;
          case order[2]: insurance.kategorie = 'Sachversicherungen'; break;
          case order[3]: insurance.kategorie = 'Vorsorge'; break;
        }
      });
      const rows = insurances;

      const headers = {
        center: [],
      };

      headers.center.push(CurrencyColumn("kategorie", "Kategorie"))
      headers.center.push(CurrencyColumn("beitragMonat", "ø Beiträge / Monat").withSumFooter())
      headers.center.push(CurrencyColumn("beitrageJahr", "Beiträge / Jahr").withSumFooter())

      const mobileConfig = {
        title: 'kategorie',
        headers: ['beitragMonat', 'beitrageJahr'],
        disableClickRow: true,
      }

      return {mobileConfig, headers, rows};
    },
    insurances() {
      const steckBriefInsurances = this.insuranceTable?.data
      if (this.widgetsActivated?.length && !this.widgetsActivated.includes('BalanceSheetView') && !steckBriefInsurances?.records?.length && this.thereIsInsurances) {
        this.$store.dispatch(BALANCE_SHEET_TYPES.ACTIONS.GET_INSURANCE_TABLE, {id: this.depotid}).then(response => {
          if(!response?.data?.records?.length) {
            this.thereIsInsurances = false;
          }
        })
        return;
      }
      const labelsMap = insuranceCategoriesDisplayName;
      const rows =  steckBriefInsurances.records.map(row => {
       return row.map((v, i) => ({
          [steckBriefInsurances.tableHeaders[i].key]: v
        })).reduce((acc, curr) => ({...acc, ...curr}), {})
      })

      const sumAndConvertToNumber = (values) => parseFloat(values.reduce((sum, curr) => 
      sum + FinancialCalculator.convertStringToNumber(curr.praemie) * getInsurancePeriod(curr.zw),0).toFixed(2));

      const groupedByCategory = rows.reduce((acc, curr) => ({...acc, [curr.kategorie] : (acc[curr.kategorie] || []).concat(curr) }), {})
      const summary = Object.entries(groupedByCategory)
      .reduce((acc, [kategorie, values]) => {
          const beitragJahr = sumAndConvertToNumber(values);
          return ({...acc, [kategorie]: {kategorie: labelsMap[kategorie], beitrageJahr: beitragJahr, beitragMonat: beitragJahr/12 }})
        },{});

      const order = ['Gesundheit und Existenz', 'Betrieb', 'Besitz und Eigentum', 'Vorsorge'];
      const insurances = Object.values(summary).sort((a, b) => order.indexOf(a.kategorie) - order.indexOf(b.kategorie))
      insurances.forEach(insurance => {
        switch(insurance.kategorie){
          case order[0]: insurance.kategorie = 'Gesundheit'; break;
          case order[1]: insurance.kategorie = 'Betrieblich'; break;
          case order[2]: insurance.kategorie = 'Sachversicherungen'; break;
          case order[3]: insurance.kategorie = 'Vorsorge'; break;
        }
      });
      const records = insurances;

      const headers = {
        kategorie: {
          label: 'Kategorie',
          key: 'kategorie',
          dataType: 'String',
          sortable: false,
          visible: true,
        },
        beitragMonat: {
          label: 'ø Beiträge / Monat',
          key: 'beitragMonat',
          dataType: 'Currency',
          sortable: false,
          visible: true,
          sum: true,
        },
        beitrageJahr: {
          label: 'Beiträge / Jahr',
          key: 'beitrageJahr',
          dataType: 'Currency',
          sortable: false,
          visible: true,
          sum: true,
        },
      }

      return {headers, records};

    },
    sumInsurances() {
      return this.insurances && this.insurances.reduce((acc, curr) => acc + FinancialCalculator.convertStringToNumber(curr.sum), 0)
    },
    videoIndentLength() {
      return this.videoIndent && this.videoIndent.records && this.videoIndent.records.length || 0;
    },
    openSignaturesLength() {
      return this.openSignatures && this.openSignatures.count  || 0;
    },
    isWertpapiere() {
      return this.depotpositions && this.depotpositions.records && this.depotpositions.records.length;
    },
    isInsurances() {
      return this.insurances;
    },
    GandV() {
      return FinancialCalculator.convertStringToNumber(this.depotpositions?.resultSums?.LABEL_SALDO_SALDO?.[1] ?? 0) || 0;
    },
    performanceDecimalPrecision() {
      return this.depotpositions?.decimalPrecision;
    },
    performance() {
      const values = (this.depotpositions?.resultSums?.LABEL_DEPOT_WERTENTWICKLUNG?.[1] || '')
        .split('/')
        .map(value => value.trim());

      const totalStr = values.find(value => !value.includes('p.a.'));
      const paStr = values.find(value => value.includes('p.a.'));

      return {
        hasTotal: !!totalStr,
        total: FinancialCalculator.convertStringToNumber(totalStr ?? 0),
        hasPA: !!paStr,
        pa: FinancialCalculator.convertStringToNumber(paStr ?? 0),
      };
    },
    hasPerformance() {
      return !!(this.depotpositions?.resultSums?.LABEL_DEPOT_WERTENTWICKLUNG?.[0]);
    },
    showSaldozeile() {
      return !!(this.depotpositions?.resultSums?.LABEL_SALDO_SALDO?.[0]);
    },
    bestand() {
      return FinancialCalculator.convertStringToNumber(this.depotpositions?.resultSums?.LABEL_DEPOTAUSZUG_SUMME?.[1] ?? 0);
    },
    chartWertpapiereData() {
      if (!this.simplyChartGV || this.simplyChartGV.status !== this.userId) {
        return [{
          name: '',
          data: []
        }];
      }
      return [{
          name: '',
          data: this.simplyChartGV.chart
        }]
    },
    eChartWertpapiereData() {
      if (!this.simplyChartGV || this.simplyChartGV.status !== this.userId) {
        return null
      }
      return this.simplyChartGV.chart.map((value,index) => ([ dayjs(this.simplyChartGV.datum[index], 'DD.MM.YYYY').toDate().getTime(), value]))
    },
    hasDataEChartWertpapiere() {
      if (this.simplyChartGV?.status === this.userId) {
        return this.simplyChartGV?.chart?.length > 1 
      }
      return false
    },
    chartWertpapiereDataOptions() {
      const xLabels = this.simplyChartGV.status === this.userId ? this.simplyChartGV.labels : [];
      return {
        chart: {
          id: 'wertpapiere-chart',
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
          animations: {
            enabled: false,
          },
        },
        grid: {
          show: false,
          enabled: false,
          padding: { left: 40, bottom: 20}
        },
        xaxis: {
          categories: xLabels,
        },
        yaxis: {
          show: false,
          enabled: false,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
          width: 1,
        },
        tooltip: {
          show: false,
          enabled: false,
        },
        legend: {
          show: false,
        },
      }
    },
    multibankingSum() {
      return this.multibanking && this.multibanking.savingsPotential && this.multibanking.savingsPotential.sumTotal;
    },
    mobileWertpapierTable() {
      const headers = {
        center: [],
      };

      const getClass = (value) => value < 0 ? 'color-danger' : 'color-success';

      if(this.showSaldozeile) {
        headers.center.push(CurrencyColumn("gundv", "G&V").setColorFn((row) => getClass(row?.gundv)))
      }

      if(this.hasPerformance) {
        if (this.performance.hasTotal) {
          headers.center.push(PercentageColumn("wertentwicklungGesamt", "Wertentwicklung (gesamt)", this.performanceDecimalPrecision)
            .setColorFn((row) => getClass(row?.wertentwicklungGesamt)))
        }
        if (this.performance.hasPA) {
          headers.center.push(PercentageColumn("wertentwicklungPA", "Wertentwicklung (p.a.)", this.performanceDecimalPrecision)
            .setColorFn((row) => getClass(row?.wertentwicklungPA)))
        }
      }

      headers.center.push(CurrencyColumn("depotbestand", "Depotbestand"))

      const rows = [];
      const column = {};
      column.gundv = this.GandV
      column.wertentwicklungGesamt = this.performance.total
      column.wertentwicklungPA = this.performance.pa
      column.depotbestand = this.bestand

      rows.push(column)

      return {headers, rows}
    },
    risikoverteilungModalActions() {
      return [BaseModalSimpleAction('ANLEGERPROFIL', 'Anlegerprofil').withPrimary(() => false)];
    },
  },
  watch: {
    depotid(id) {
      this.$store.dispatch(STECKBRIEF_TYPES.ACTIONS.GET_DEPOTPOSITIONS, id);
      this.$store.dispatch(STECKBRIEF_TYPES.ACTIONS.GET_INVESTMENT_BESTAND,  id);
      this.$store.dispatch(STECKBRIEF_TYPES.ACTIONS.SIMPLY_CHART_GV, {user: this.userId,id:id });

    },
  },

  mounted() {
    this.$store.dispatch(STECKBRIEF_TYPES.ACTIONS.GET_TODO_VIDEO_INDENT, {});
    this.$store.dispatch(STECKBRIEF_TYPES.ACTIONS.GET_DEPOTPOSITIONS, null);
    this.$store.dispatch(STECKBRIEF_TYPES.ACTIONS.SIMPLY_CHART_GV, {user: this.userId,id:'ALLE_DEPOTS_ANZEIGEN' }).then(() => setTimeout(() => this.isChartDataLoaded = true));
    this.$store.dispatch(STECKBRIEF_TYPES.ACTIONS.GET_INVESTMENT_BESTAND, null);
    this.loadAbsenderDepotauszug();
  },
}
</script>

<style scoped>

.h-large .cell,
.h-large,
.row.h-large {
  line-height: 46px;
}
.sum-line.h-large {
  margin-top: -51px;
}
.row.row-left {
  justify-content: flex-start;
}
.row.row-left .cell:first-child {
  width: 74px;
  padding: 0;
  text-align: center;
}
.chart-container h3 {
  display: none;
}
.chart-treemap .chart-container {
  margin-top: -44px;
}
.chart-area .chart-container {
  /* margin-top: -44px; */
  height: 0;
  max-width: 700px;
}
.row > .mp-0,
.cell.mp-0,
.row.mp-0 {
  margin: 0;
  padding: 0;
}
.chart-container {
  width: 100%;
}
.sum-line.sum-insurance {
  width: calc(100% - 90px);
  margin-left: 90px;
}
.row.content-left {
  justify-content: flex-start;
}
.row.mt-24 {
  margin-top: 24px;
}
.mr-24 {
  margin-right: 24px;
}

.empty {
  height: 21px;
}
.w-100 {
  width: 100%;
}
.pr-8 {
  padding-right: 8px;
}

.box__container-content {
  display: flex;
  flex-direction: column;
  align-content: space-between;
  flex-shrink: 0;
  flex-grow: 1;
}
.dashboard-panel-widget__content > div {
  flex: 1;
}

.box__container-wrapper {
  align-content: stretch;
  flex-grow: 1;
}

.card-col {
  display: flex;
  flex-grow: 1;
}

.card-bottom {
  align-self: flex-end;
}

.divisorLine {
  padding: 4px 0 4px 0;
  border-bottom: 1px solid #c4c4c4;
}
.divisorLine:last-child {
  border-bottom: none;
}

@media screen and (min-width: 415px) {
  .hide-large-screen{
    display: none;
  }
}
@media screen and (max-width: 414px) {
  .text-center {
    text-align: center;
  }

  .hide-small-screen {
    display: none;
  }
}
</style>