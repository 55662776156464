<template>
  <div>
    <PageHeaderTitleNavigation :title="$appNavigation.currentMenu.label" />

    <div class="box__container">
      <div class="box__title">Vermittlerwechsel innerhalb der Struktur</div>
    </div>

    <div class="box__container">
      <div class="box__title">
        Hier können Sie den Kunden zu einem anderen Makler Ihrer Struktur
        wechseln. Die Formulare werden wo möglich sofort an die Lagerstellen
        geschickt. Alle Lagerstellen die nicht automatisch umgestellt werden
        können, müssen nach dem Übertrag ggf. mit Kundenunterschrift übertragen
        werden.
      </div>
      <ComboBox
        label="Wechsel zu"
        isComponentHalfSize
        :disabled="isSomeButtonLoading"
        v-model="targetMakler"
        :values="targetMaklerValues"
      />
      <BaseButton
        class="mr-2"
        label="Vermittler wechseln"
        :animated="loading.change"
        :disabled="!targetMakler || isSomeButtonLoading"
        @click="$refs.changeVermittlerModal.open()" 
      />
    </div>

    <div
      class="box__container"
      v-if="lagerstellenList && lagerstellenList.length"
    >
      <div class="box__title">Lagerstellen:</div>
      <div v-for="(lagerstelle, index) in lagerstellenList" :key="index">
        <div style="display:flex">
          <div style="width: 50%">
            {{lagerstelle.label}}
          </div>
          <div>
            {{lagerstelle.description}}
          </div>
        </div>
        <hr />
      </div>
    </div>

    <BaseModal
      ref="changeVermittlerModal"
      labelButtonConfirm="Vermittler wechseln"
      labelButtonCancel="Abbrechen"
      @onConfirmButton="changeVermittler()"
    >
      <template v-slot:modalTitle>
        <ph-warning :size="16" class="color-danger mr-2"/>
        Vermittlerwechsel
      </template>
      
      <p class="color-danger">Achtung!</p>
      Hiermit wird der Kunde auf den ausgewählten Makler umgebucht
      <b>
        und alle Dokumente werden automatisch an die jeweiligen Depotstellen
        versendet!
      </b>
    </BaseModal>

    <BaseModal
      ref="versandFailedModal"
      modalTitle="Übertrag kann nicht durchgeführt werden"
      labelButtonConfirm="Ticket für Fehlerbehebung erstellen"
      labelButtonCancel="Abbruch (Wechsel nicht durchführen)"
      @onConfirmButton="createTicket()"
    >
      <div v-html="sanitize(versandFailedMessage)"></div>
    </BaseModal>

    <BaseModal
      ref="hinweisModal"
      modalTitle="Wichtige Hinweise"
      labelButtonConfirm="Gelesen"
      @onConfirmButton="sendGelesen()"
      :showCancelButton="false"
      :showCloseButton="false"
    >
      <div v-html="sanitize(this.popupText)"></div>
    </BaseModal>
  </div>
  
</template>

<script>
import CORE_TYPES from "@/store/core/types";
import LOG_TYPES from "@/store/log/types";
import BaseButton from "@/components/core/BaseButton.vue";
import BaseModal from "@/components/core/BaseModal.vue";
import ComboBox from "@/components/core/forms/ComboBox.vue";
import OptionMenu from "@/components/core/option-menu/OptionMenu.vue";
import PageHeaderTitleNavigation from "@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue";
import axios from "axios";
import { PhWarning } from "phosphor-vue";
import { sanitize } from "@/helpers/string-helper.js";
import { buildMessage } from "@/helpers/log-message-helper";
import { viewDocument } from "@/components/core/download/DownloadLink.vue";

const config = {
  defaultSpinner: true,
};

export default {
  components: {
    BaseButton,
    ComboBox,
    OptionMenu,
    PageHeaderTitleNavigation,
    BaseModal,
    PhWarning,
  },
  data() {
    return {
      targetMakler: null,
      targetMaklerValues: [],
      loading: {
        init: false,
        change: false,
      },
      versandFailedMessage: "",
      lagerstellenList: [],
      popupText: "",
      popupSaveParams: "",
    };
  },
  computed: {
    isSomeButtonLoading() {
      return Object.values(this.loading).some((valueLoading) => valueLoading);
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    sanitize(htmlString) {
      return sanitize(htmlString.replace(/\n/g, "<br>"));
    },
    init() {
      this.loading.init = true;

      axios
        .get(`${process.env.VUE_APP_API}/vermwechselintern/getData`, config)
        .then((response) => {
        if (response && response.data) {
            this.targetMaklerValues = response.data.strukturmemberAuswahl;
            this.lagerstellenList = response.data.lagerstellenList;
            this.popupText = response.data.popupDataText;
            this.popupSaveParams = response.data.popupDataValue;

            if (this.popupText) this.$refs.hinweisModal.open();
        }
        })
        .finally(() => (this.loading.init = false));
    },
    sendGelesen() {
      console.info(this.popupSaveParams);
      axios
        .get(
          `${process.env.VUE_APP_API}/vermwechselintern/confirmRead?readValue=${this.popupSaveParams}`,
          { defaultSpinner: false }
        );
    },
    changeVermittler() {
      if (!this.targetMakler) return;

      this.loading.change = true;

      const payload = {
        maklernr: this.targetMakler,
      };

      axios
        .post(
          `${process.env.VUE_APP_API}/vermwechselintern/doWechsel`,
          payload,
          config
        )
        .then((response) => {
        if (response && response.data) {
            this.handleResponse(response.data);
        }
        })
        .catch(() => (this.loading.change = false));
    },
    handleResponse(responseData) {
      this.loading.change = false;

      if (responseData.versandFailedMessage) {
        this.versandFailedMessage = responseData.versandFailedMessage;
        this.$refs.versandFailedModal.open();
      } else {
        this.$confirmModal({
          title: "Vermittlerwechsel durchgeführt",
          message:
            "<p>Der Vermittlerwechsel wurde durchgeführt. Das Ergebnis wird als PDF-Datei geöffnet.</p> Sie werden nun auf den Kunden weitergeleitet",
          labelButtonConfirm: "Ok",
          showCancelButton: false,
          showConfirmButton: true,
        }).finally(() => {
          this.loading.change = true;
          viewDocument({
            data: responseData.dataUebertrag,
            filename: responseData.filename,
            contentType: "application/pdf",
          });
          this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, {
            customerId: responseData.kundeNeu,
            skipNewTab: true,
          });
        });
      }
    },
    createTicket() {
      this.loading.init = true;

      const payload = {
        maklernr: this.targetMakler,
        fehlerText: this.versandFailedMessage,
      };

      axios
        .post(
          `${process.env.VUE_APP_API}/vermwechselintern/createTicket`,
          payload,
          config
        )
        .then(() => {
          this.$store.dispatch(
            LOG_TYPES.ACTIONS.ADD_MESSAGE,
            buildMessage("Das Ticket wurde erstellt.", "primary")
          );
          this.$router.push("/home");
        })
        .catch(() => (this.loading.init = false));
    },
  },
};
</script>