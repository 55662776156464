<template>
<div>
    <PageHeaderTitleNavigation 
        :actions="pageHeaderActions"
        @action-SHOW_BROKER_BLACKLIST="toggleBrokerList"
        :title="dynamicTitle">
        </PageHeaderTitleNavigation>

    <BaseFilter
        title="BiPRO Dokumente"
        filterId="BiPRO Dokumente"
        :configFilter="filterConfig"
        :metadata="metadata"
        :defaultOptions="defaultOptions"
        showSaveButton
        :immidiateSearch="true"
        @onFilter="onFilter"
        @onFilterZurucksetzen="onResetFilter"
    >
    </BaseFilter>

    <div class="box__container">
        <InputField  v-if="showbrokerBlacklist" label="Makler Sperrliste (Komma separierte Strukturleiter Maklernummern Liste. Die entsprechenden Makler Strukturen werden von der Vorschlagssuche ausgeschlossen)" v-model="brokerBlacklist" @change="updateBrokerBlacklist" type="text"/>
        <div style="clear: both"></div>
        <div v-if="loading || !rows.length">
            <span class="box__title">
                Posteingang
            </span>
            <br>
        </div>


        <GhostLoading v-if="loading" type="table"/>

        <big-table 
            v-else-if="rows.length"
            selectable
            :headers="tableHeadersForBackend" 
            :headerActions="headerActions"
            title="Posteingang"
            :tableId="tableId"
            :rows="rows" 
            :initialPageSize="minNumRows"
            :page="page"
            :sortingState="sortingState"
            @onClickHeader="onClickHeader"
            @selectedRow="onRowSelection"
            @selectedRows="onAllRowsSelection"
            @click-icon-iconColumn="iconColumnClicked"
            @action="executeAction"
            @headerAction-MARKIERTE_POSITIONEN_LOESCHEN="deleteRows(selected)"
            @headerAction-REASSIGN_UNASSIGNED_ROWS="reassignUnassignedRows(selected)"
            @click-icon-SHOW_AND_ASSIGN="actionAssign"
            >

            <template #fileName="row">
                <DownloadLink
                        :downloadServicePath="automatischZugeordnet ? '/bipro_dokument_zugeordnet' : '/bipro_dokument_nicht_zugeordnet'"
                        :filename="row.fileName"
                        :queryParams="{
                            id: row.id,
                        }"
                    >

                    {{row.fileName}}
            
                </DownloadLink>
            </template> 
            
            <template v-slot:comment="row">
                <InputField :value="row.comment" @change="saveComment(row, $event)"/>
            </template>        
      
        </big-table>

        <NoData v-if="!rows.length"/>
    </div>

    <PreviewPDF ref="resizablePopup" :pdfUrl="pdfUrl" id="a973ea87-9ce7-4201-a113-5f4c2ca5ddbf" fullClientHeight startPosition="TOP_RIGHT"></PreviewPDF>

</div>
</template>

<script>
import CORE_TYPES from '@/store/core/types'
import LOG_TYPES from '@/store/log/types';
import BIPRO_TYPES from '@/store/bipro/types'

import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue'
import BaseFilter from "@/components/core/BaseFilter.vue";
import Table from "@/components/table2/Table.vue";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';
import InputField from '@/components/core/forms/InputField.vue';
import BaseButton from '@/components/core/BaseButton.vue';

import {SimpleAction, DownloadLinkAction} from "@/components/table2/table_util.js";
import { mapGetters } from 'vuex';
import axios from 'axios';
import { DatePickerUtils } from '@/components/core/forms/DatePicker/date-picker-utils';
import {PhFile, PhPencilLine, PhPencil, PhArrowFatRight, PhArrowBendUpRight} from 'phosphor-vue';
import { buildMessage } from "@/helpers/log-message-helper";
import {ROLES} from '@/router/roles';
import DownloadLink from '@/components/core/download/DownloadLink.vue';
import { PageHeaderSimpleAction, PageHeaderConfirmedAction} from '@/components/core/header-title-navigation/page-header-utils';
import BigTable from '@/components/bigTable/BigTable.vue';
import {STRING_COLUMN, DATE_COLUMN, ACTION_COLUMN, SLOT_COLUMN, FIXED_LEFT, FIXED_RIGHT, Icon, ICON_COLUMN, NUMBER_COLUMN} from '@/components/bigTable/BigTable.vue';
import { downloadLinkMaker } from '@/helpers/utils-helper';
const ROOT_DOWNLOAD_SERVICE_PATH = '/download_service'
import BaseModal from '@/components/core/BaseModal.vue';
import PreviewPDF from '@/components/core/PreviewPDF.vue';

const PARAMETER_ALLE = "PARAMETER_ALLE";
const config = {
    defaultSpinner: true,
};

export default {
    created() {
        this.FIXED_LEFT = FIXED_LEFT;
        this.FIXED_RIGHT = FIXED_RIGHT;
    },        
    mixins: [],
    components: {
        PageHeaderTitleNavigation,
        OptionMenu,
        BaseFilter,
        Table, //needed to show content
        GhostLoading,
        NoData,
        InputField,
        BaseButton,
        DownloadLink,
        PhArrowBendUpRight,
        BigTable,
        BaseModal,
        PreviewPDF,
    },
    data() {
        const startDate = new Date();
        startDate.setMonth(startDate.getMonth() - 1);
        var self = this;
        return {
            loading: false,
            filterConfig: {
                placeholderForDefSearchInput: 'Dateiname',
                checkDefaultSearchInput: false,
                cbDefSearchInput: null,
                hideFirstColumn: true,
                filterZurucksetzen: null,
                noResetOnDefaultSearchInputExit: true,
                ignoreStoredFilter: true,
            },
            filterParams: {},
            files: null,
            selected: [],
            brokerBlacklist: "",
            showbrokerBlacklist: false,
            tableHeadersForBackend: [
                { label: "", key: "SHOW_AND_ASSIGN", width: 50, type: ICON_COLUMN, position: FIXED_LEFT },
                { label: "Dateiname", key: "fileName", width: 150, type: SLOT_COLUMN, visible: true },
                { label: "VSNR", key: "policeNr", width: 150, type: STRING_COLUMN, visible: true },
                { label: "Kundenname", key: "customerName", width: 150, type: STRING_COLUMN, visible: true },
                { label: "Kundenvorname", key: "customerSurname", width: 150, type: STRING_COLUMN, visible: true },
                { label: "Hinweis", key: "cause", width: 200, type: STRING_COLUMN, visible: true },
                { label: "Fremdnummer", key: "fremdnummer", width: 120, type: STRING_COLUMN, visible: true },
                { label: "Vermittlernummer", key: "vermittlernummer",width: 120, type: STRING_COLUMN, visible: true },
                { label: "MSC Kategorie", key: "category", width: 120, type: STRING_COLUMN, visible: true },
                { label: "GeVo-Art", key: "gevoArt", width: 120, type: NUMBER_COLUMN, visible: true},
                { label: "Erstelldatum", key: "creationDate", width: 120, type: DATE_COLUMN, visible: true},
                { label: "Bemerkung", key: "comment", width: 150, type: SLOT_COLUMN, visible: true },
                { label: "Gesellschaft", key: "biproGesellschaft", width: 150, type: STRING_COLUMN, visible: true },
                { label: "MSC Gesellschaft", key: "mscGesellschaft", width: 150, type: STRING_COLUMN, visible: true },
                { label: "MSC Datum", key: "mscDate", width: 120, type: DATE_COLUMN, visible: true },
                { label: "MSC Interne Nr", key: "internalId", width: 120, type: NUMBER_COLUMN, visible: true },
                { label: "Maklernr", key: "brokerId", width: 150, type: STRING_COLUMN, visible: true },
                { label: "Zugang ID", key: "accessId", width: 150, type: STRING_COLUMN, visible: true },
                { label: "Aktionen", key: "actions", width: 120, type: ACTION_COLUMN, visible: true },
            ],
            page: 0,
            rows: [],    
            minNumRows: 100,
            sortingState: { key: "customerName", direction: "desc" },   
            pdfUrl: '',
            tableId: '22127da3-5d18-4c43-a7cc-5036fa59e130',            
        };
    },
    computed: {
        ...mapGetters({
            hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
            gesellschaftOptions: BIPRO_TYPES.GETTERS.GESELLSCHAFT_OPTIONS,
            savedFilters: BIPRO_TYPES.GETTERS.SAVED_FILTERS,
            savedSorting: BIPRO_TYPES.GETTERS.SAVED_SORTING,
        }),
        filters() {
            return this.savedFilters || {};
        },        
        defaultOptions() {
            let defaultFilters = {filterMode: {value: "0"}};
            if (this.filters?.filters) {
                defaultFilters = {};
                Object.keys(this.filters?.filters)?.forEach(key => defaultFilters[key] = { value: this.filters?.filters[key]});
            }
            return defaultFilters;

        },  
        dynamicTitle() {
            let result = "BiPRO Dokumente";

            if (this.rows?.length) {
                result += ` | ${this.rows.length} Anzahl der angezeigten Dokumente`
            }

            return result;

        },
        pageHeaderActions() {
            const actions = [PageHeaderSimpleAction('SHOW_BROKER_BLACKLIST', 'Makler Sperrliste anzeigen')];

            return actions;
        },   
        headerActions() {
            // const actions = [PageHeaderConfirmedAction('MARKIERTE_POSITIONEN_LOESCHEN', 'Markierte Positionen löschen', '', 'Möchten Sie die ausgewählten Positionen wirklich löschen?', 'Ja').withDisabled(() => this.selected?.length == 0)];
            const actions = [PageHeaderConfirmedAction('MARKIERTE_POSITIONEN_LOESCHEN', 'Markierte Positionen löschen', '', 'Möchten Sie die ausgewählten Positionen wirklich löschen?', 'Ja').withDisabled(() => !this.rows.some(item => item.selected))];
            actions.push(PageHeaderConfirmedAction('REASSIGN_UNASSIGNED_ROWS', 'nicht zugeordnete Dokumente neu zuordnen', '', 'Sind Sie sicher, dass Sie diese Aktion ausführen möchten?', 'Ja'))

            return actions;
        },
        isBiproStruktur() {
            return this.hasRoles(ROLES.IS_BIPRO_STRUKTUR);
        },
        automatischZugeordnet() {
            return this.filterParams.filterMode == 2;
        },
        documentDownloadURL() {
            const { automatischZugeordnet, } = this;
            if (automatischZugeordnet) {
                return "/bipro_dokument_zugeordnet";
            }
            return "/bipro_dokument_nicht_zugeordnet";
        },
        metadata() {
            const result = [
                {
                    type: 'group',
                    key: 'allgemein',
                    label: 'Allgemein',
                    menuItems: [
                        {
                            type: 'combobox',
                            label: 'Versicherungsgesellschaft',
                            key: 'gesellschaftId',
                            comboOptions: this.gesellschaftOptions || [],
                        },
                        {
                            type: 'combobox',
                            label: 'Filter Modus',
                            key: 'filterMode',
                            comboOptions: [
                                {value: 0, label: "nicht zugeordnete Dokumente anzeigen"},
                                {value: 1, label: "nachträglich zugeordnete Dokumente anzeigen"},
                                {value: 2, label: "automatisch zugeordnete Dokumente anzeigen"},
                            ],
                        },
                        {
                            type: 'dateRange',
                            label: 'Zeitraum',
                            key: 'zeitraum',
                            emptyDenied: true,
                        },
                        {
                            type: 'text',
                            label: 'VSNR',
                            key: 'policeNr',
                            emptyDenied: true,
                        },
                        {
                            type: 'text',
                            label: 'Interne Nummer',
                            key: 'internalId',
                            emptyDenied: true,
                            inputmode: 'numeric',
                        },
                        {
                            type: 'text',
                            label: 'Kundenname',
                            key: 'customerName',
                            emptyDenied: true,
                        },
                        {
                            type: 'text',
                            label: 'Kundennummer',
                            key: 'customerId',
                            emptyDenied: true,
                            inputmode: 'numeric',
                        },
                        {
                            type: 'text',
                            label: 'GeVo-Art',
                            key: 'gevoArt',
                            emptyDenied: true,
                        },
                        {
                            type: 'text',
                            label: 'Fremdnummer',
                            key: 'fremdnummer',
                            emptyDenied: true,
                        },  
                        {
                            type: 'text',
                            label: 'Vermittlernummer',
                            key: 'vermittlernummer',
                            emptyDenied: true,
                        },                       
                    ],
                },
            ];
            if (this.isBiproStruktur) {
                result[0].menuItems.push({
                    type: 'default',
                    label: 'auch die nicht zugeordnete Dokumente der Struktur anzeigen',
                    key: 'mitStruktur',
                    emptyDenied: true,
                });
            }
            return result;
        },        
    },
    mounted() {
        if (!this.gesellschaftOptions)
            this.$store.dispatch(BIPRO_TYPES.ACTIONS.LOAD_GESELLSCHAFT_OPTIONS);
        axios.get(`${process.env.VUE_APP_API}/bipro_documents/broker_blacklist`, config)
        .then(response => {
            this.brokerBlacklist = response.data;
        });
        if (this.savedSorting) {
            this.sortingState = this.savedSorting;
        }
        this.adjustIframeHeight();
        window.addEventListener('resize', this.adjustIframeHeight);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.adjustIframeHeight);
    },    
    methods: {
        adjustIframeHeight() {
            const iframeContainer = this.$refs.iframeContainer;
            const pdfIframe = this.$refs.pdfIframe;
            if (pdfIframe && iframeContainer) {
                const containerHeight = iframeContainer.offsetHeight;
                pdfIframe.style.height = `${containerHeight}px`;
            }
        },        
        fetchPDF(row) {
            const {fileName} = row;
            const { automatischZugeordnet, } = this;

            const queryParams = {
                    id: row.id,
                };

            let downloadServicePath="/bipro_dokument_nicht_zugeordnet";

            if (automatischZugeordnet) {
                downloadServicePath =  "/bipro_dokument_zugeordnet";
            }

            const url = downloadLinkMaker(this.$store.getters, `${ROOT_DOWNLOAD_SERVICE_PATH}${downloadServicePath}`, fileName, queryParams);
            this.pdfUrl = url;
            this.$refs.resizablePopup.showPopup();

        },
        onClickHeader(headerMetadata) {
            this.sortingState = {key: headerMetadata?.header?.key, direction: headerMetadata?.sortingState?.direction };
            this.$store.commit(BIPRO_TYPES.MUTATIONS.SAVE_SORTING, this.sortingState);
        },
        iconColumnClicked(eventData) {
            // console.log("event for icon", eventData);
        },
        linkColumnClicked(eventData) {
            // console.log("event for link", eventData);
        },        
        onPageChange(event) {
            this.page = event;
        },    
        executeAction(actionData) {
            switch (actionData?.key) {
                case "ASSIGN":
                    this.actionAssign(actionData?.row);
                    break;
                case "CONTRACT":
                    this.actionContract(actionData?.row);
                    break;
                default:
                    break;
            }
        },    
        onRowSelection(payload) {
            if (payload?.action == "added") {
                // Check if the row is not already selected before adding
                if (!this.selected.some(item => item === payload.row)) {
                    this.selected.push(payload.row);
                }
            } else if (payload?.action == "removed") {
                // Find the index of the row in selected array
                const index = this.selected.findIndex(item => item === payload.row);
                // If found, remove it
                if (index !== -1) {
                    this.selected.splice(index, 1);
                }
            }
        },
        onAllRowsSelection(payload)  {
            this.selected = payload;
        },     
        saveFilters(filterArr = []) {
            const filters = {};
            filterArr.forEach(filter => filters[filter.key] = filter.value);
            this.$store.commit(BIPRO_TYPES.MUTATIONS.SAVE_FILTERS, { filters: filters });
        },        
        toggleBrokerList() {
            this.showbrokerBlacklist = !this.showbrokerBlacklist;
        },
        calculatedRowBackgroundColor(whatRow, index) {
			if (index % 2== 0 && !whatRow.clicked) {
				return ( { backgroundColor: '#e3e3e3', } );
			}
			return (whatRow.clicked ? { fontWeight: 'bold', backgroundColor: '#e3e3e3', } : {});
		},    
        assignWindow(row) {
            const { automatischZugeordnet, } = this;
            if (!automatischZugeordnet) {
                this.actionAssign(row);
            }
        },
        async onFilter(filterConfig) {
            this.saveFilters(filterConfig);

            let params = {};

            filterConfig.forEach(fc => {
                let value = fc.value;
                if (fc.key == 'showDeleted' || fc.key == 'showFilesOfStructure')
                    value = true; // the filters with above keys have no input field and should only be considered as boolean (true if added to the filter)
                if (fc.key == "zeitraum") {
                    params.fromDate = DatePickerUtils.toDateStr(value.find(v => v.key == 'min').value);
                    params.toDate = DatePickerUtils.toDateStr(value.find(v => v.key == 'max').value);
                } else {
                    params[fc.key] = value;
                }
            });
            if (!params.gesellschaftId)
                params.gesellschaftId = PARAMETER_ALLE;
            // set default time range of 1 month
            if (!params.fromDate) {
                const startDate = new Date();
                startDate.setMonth(startDate.getMonth() - 1);
                params.fromDate = DatePickerUtils.toDateStr(startDate);
                params.toDate = DatePickerUtils.toDateStr(new Date());
            }
            this.filterParams = params;
            this.loadRows();
        },
        onResetFilter() {
            this.$store.commit(BIPRO_TYPES.MUTATIONS.SAVE_FILTERS, {filterMode: {value: "0"}});
        },
        async loadRows() {
            this.loading = true;
            axios.post(`${process.env.VUE_APP_API}/bipro_documents/filter`, this.filterParams, config)
            .then(response => {
                this.files = response.data;

                this.rows = (this.files || []).map((row, index) => {
                const actions = [];
                if (!this.automatischZugeordnet) {
                    actions.push(DownloadLinkAction("DOWNLOAD", PhFile, "Anzeigen", row.fileName, this.documentDownloadURL, {id: row.id}));
                    actions.push(SimpleAction("ASSIGN", PhArrowFatRight, "Zuweisen"));
                }
                if (row.internalId != null) {
                    if (row.versSchadenId != null)
                        actions.push(SimpleAction("SHOW_DAMAGE", PhPencil, "Schadensfall öffnen"));
                    else
                        actions.push(SimpleAction("CONTRACT", PhPencilLine, "Versicherungsvertrag öffnen"));
                }
                return {
                    index: index + 1,
                    ...row,
                    visible: !row.invisible,
                    //customRowStyle: { ...this.calculatedRowBackgroundColor(row, index), }, 
                    iconColumn: Icon("PhArrowFatRight", "Interne Nr. übernehmen und Versicherungsdokument direkt zuordnen"),  
                    SHOW_AND_ASSIGN: Icon("PhArrowBendUpRight", "Zuweisen"),  
                    linkColumn: row.fileName,                
                    actions,
                };
            });


            }).catch(error => {
                this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage('Bei der Suche ist ein Fehler aufgetreten.', 'danger'));
            }).finally(() => {
                this.loading = false;
            })
        },
        saveComment(row, newValue) {
            const data = {
                id: row.id,
                comment: newValue,
            };
            axios.post(`${process.env.VUE_APP_API}/bipro_documents/edit_comment`, data, config)
            .then((response) => {
                this.loadRows();
            })
        },
        actionAssign(row) {
            this.$router.push({path: "/intern/bipro/documents/assign_insurance", query: {id: row.id, insuranceId: row.internalId, fileName: row.filename}});
        },
        async actionShowDamage(row) {
            let response = await axios.get(`${process.env.VUE_APP_API}/bipro_documents/vv_customer_id?id=${row.internalId}`, config);
            if (response.data) {
                this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, {
                    customerId: response.data,
                    insurances: true,
                    path: `/home/versicherungen/insurance-group/details/${row.internalId}`
                });
            } else {
                this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage('Vertrag wurde anscheinend gelöscht!', 'warning'));
            }
        },
        async actionContract(row) {
            let response = await axios.get(`${process.env.VUE_APP_API}/bipro_documents/vv_customer_id?id=${row.internalId}`, config);
            if (response.data) {
                this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, {
                    customerId: response.data,
                    insurances: true,
                    path: `/home/versicherungen/insurance-group/details/${row.internalId}`
                });
            } else {
                this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage('Vertrag wurde anscheinend gelöscht!', 'warning'));
            }
        },
        updateBrokerBlacklist(blacklist) {
            axios.post(`${process.env.VUE_APP_API}/bipro_documents/broker_blacklist?blacklist=${blacklist}`, null, config)
            .then(response => {
                this.brokerBlacklist = response.data;
            });
        },
        reassignUnassignedRows(rows) {
            if (rows.length == 0)
                return;
            const ids = rows.map(row => row.id);
            axios.post(`${process.env.VUE_APP_API}/bipro_documents/reassign_unassigned`, ids, config)
            .then(response => {
                this.selected = [];
                this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(`Anzahl der neu zugeordneten Dokumente: ${response.data || 0}`, 'info'));
                if (response.data > 0)
                    this.loadRows();
            });
        },
        deleteRows(rows) {
            if (rows.length == 0)
                return;
            const ids = rows.map(row => row.id);
            axios.post(`${process.env.VUE_APP_API}/bipro_documents/delete_rows`, ids, config)
            .then(response => {
                this.selected = [];
                this.loadRows();
            });
        },
  

    },
    async beforeRouteLeave(to, from, next) {
        this.$addBreadcrumb({
        label: 'zurück zu Bipro Übersicht', 
        fullPath: from.fullPath,
        breadcrumb: to?.meta?.breadcrumb?.label,
        });

        next();
    },

}
</script>


<style scoped>

</style>
