<template>
  <div>
    <GhostLoading v-if="loading" type="table" />
    <NoData v-else-if="!hasLinks" noIcon />
    <ul v-else class="m-0">
      <li v-for="link in deepLinks" :key="link.name">
        <a v-if="!link.clicked" :href="link.url" target="_blank" @click="onLinkClick(link)">{{ link.name }}</a>
        <span v-else v-fc-tooltip="'Link kann nicht erneut aufgerufen werden, bitte Deeplinks aktualisieren'">{{ link.name }}</span>
      </li>
    </ul>
  </div>
</template>

<script>


import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import NoData from '@/components/core/NoData.vue';
import DeepLinksMixin from '@/components/versicherungen/Deeplinks-mixin.js';

export default {
  mixins: [DeepLinksMixin],
  props: {
    vertragId: {},
  },
  watch: {
    'deepLinksGetter': {
      handler() {
        let newLinks = Object.keys(this.deepLinksGetter).map(link => {
          return {
            url: link,
            name: this.deepLinksGetter[link],
            clicked: false
          };
        });

        this.deepLinks = newLinks;
      },
      deep: true,
    }
  },
  components: {
    GhostLoading,
    NoData,
  },
  data() {
    return {
      deepLinks: [],
      loading: false,
      hover: false
    }
  },
  computed: {
    hasLinks() {
      return Object.keys(this.deepLinks).length > 0;
    },

  },
  methods: {
    onLinkClick(whatLink) {
      whatLink.clicked = true;
    },
  },
  mounted() {
    this.findDeeplinks(this.vertragId);
  },
}
</script>
