<template>
  <div class="customer-overview__container">
    <div class="customer-overview--image">
      <div class="img-wrap">
        <img :src="userPicture" />
      </div>
    </div>
    <div class="customer-overview--content">
      <div>
        <PersonOverview :person="person" :category="info.category" :anredePronomen="info.kundenAnredePronomen" isMainPerson />
        <div class="channel-status-container">
          <div v-for="(channel, index) in info.activeCommunicationChannels" :key="index">
            <span v-if="channel.value" class="color-success"><PhCheck :size="24" class="m-1" /></span>
            <span v-if="!channel.value" class="color-danger"><PhWarning :size="24" class="m-1" /></span>
            <span>{{channel.key}}</span>
          </div>
        </div>
        <div v-if="additionalPersons.length" class="mt-2">
          <div><b>zus. Depotinhaber</b></div>
          <PersonOverview v-for="(person, index) in additionalPersons" :key="index" :person="person" isCollapsable />
        </div>

        <div v-if="hasRoles([ROLES.SUPER_CUSTOMER]) && subCustomers.length" class="mt-2">
          <div><b>Gruppenmitglieder</b></div>
          <ul class="gruppenmitglieder__list mb-2">
            <li v-for="(subCustomer, index) in subCustomers" :key="index"><a @click="openCustomerNewTab(subCustomer)">{{ subCustomer.fullName }} ({{ subCustomer.customerID }})</a></li>
          </ul>
          <router-link to="/persoenlichedaten/customer-data/steps/gruppenmitglieder">weitere Gruppenmitglieder</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import STECKBRIEF_TYPES from '../store/types';
import CORE_TYPES from '@/store/core/types';
import CUSTOMERDATA_TYPES from '@/store/customerData/types';
import PersonOverview from './customerOverview/PersonOverview.vue';
import ROLES from '@/router/roles';
import { PhCheck, PhWarning } from 'phosphor-vue';
import CUSTOMER_SEARCH_TYPES from '@/store/customerSearch/types';

export default {
  computed: {
    ...mapGetters({
      info: STECKBRIEF_TYPES.GETTERS.STECKBRIEF_CUSTOMER_INFO,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      subCustomers: CUSTOMERDATA_TYPES.GETTERS.SUB_CUSTOMERS,
      apiAddressLegacy: CORE_TYPES.GETTERS.API_ADDRESS_LEGACY,
    }),
    userPicture() {
      return `${this.apiAddressLegacy}/${this.info.pictureUrl}`
    },
    person() {
      return this.info?.person || {};
    },
    additionalPersons() {
      return this.info?.additionalPersons || [];
    },
  },
  methods: {
    openCustomerNewTab(customerObject) {
      const customer = {
        data: customerObject
      }
      this.$store.commit(CUSTOMER_SEARCH_TYPES.MUTATIONS.OPEN_CUSTOMER, customer);
      this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, { customerId: customer.data.customerID })
    },
  },
  mounted() {
    if(this.hasRoles([this.ROLES.SUPER_CUSTOMER])) {
      this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.FIND_SUB_CUSTOMERS);
    }
  },
  components: {
    PersonOverview,
    PhCheck, 
    PhWarning
  },
}
</script>

<style src='../steckbrief.scss' lang='scss' scoped></style>

<style lang="scss" scoped>
.customer-overview__container {
  display: flex;

  .customer-overview--image {
    flex: 0 0 auto;
    margin: 0 16px 0 0;

    img {
      margin: 0;
    }
  }

  .customer-overview--content {
    flex: 1 1 auto;
  }

  .gruppenmitglieder__list {
    list-style: none;
    padding: 0;
    margin: 0 0 0 12px;
  }
}
.channel-status-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.5rem;
   > div {
     display: flex;
     flex: 0 0 auto;
     align-items: center;
     > span {
       margin-right: 0.5rem;
     }
   }
}

.circle {
  height: 16px;
  width: 16px;
  background-color: gray;
  border-radius: 50%;
  display: inline-block;
}

.enabled {
  background-color: var(--color-success) !important;
}

.disabled {
  background-color: var(--color-danger);
}

@media screen and (max-width: 500px) {
  .customer-overview__container {
    flex-flow: column;

    .customer-overview--image {
      align-self: center;
      margin: 0 0 16px 0;
    }
  }
}
</style>
