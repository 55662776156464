<template>
  <div class="html-editor__container" v-if="editor">
    <div class="html-editor__menu layout__negative-margin--8">
      <TBBold :editor="editor" :disabled="disableAllControls" />
      <TBItalic :editor="editor" :disabled="disableAllControls" />
      <TBUnderline :editor="editor" :disabled="disableAllControls" />

      <template v-if="showMoreToolbarControllers">
        <TBTextAlignLeft :editor="editor" class="hide-on-small-screen" :disabled="disableAllControls" />
        <TBTextAlignCenter :editor="editor" class="hide-on-small-screen" :disabled="disableAllControls" />
        <TBTextAlignRight :editor="editor" class="hide-on-small-screen" :disabled="disableAllControls" />
        <TBTextAlignJustify :editor="editor" class="hide-on-small-screen" :disabled="disableAllControls" />

        <TBUnorderedList :editor="editor" class="hide-on-small-screen" :disabled="disableAllControls" />
        <TBOrderedList :editor="editor" class="hide-on-small-screen" :disabled="disableAllControls" />
        <TBHorizontalRule :editor="editor" class="hide-on-small-screen" :disabled="disableAllControls" />
        <TBImage :editor="editor" class="hide-on-small-screen" :disabled="disableAllControls" />
        <TBImageEdit :editor="editor" class="hide-on-small-screen" :disabled="disableAllControls" />
        <TBLink :editor="editor" class="hide-on-small-screen" :disabled="disableAllControls" />
        <TBUnlink :editor="editor" class="hide-on-small-screen" :disabled="disableAllControls" />

        <TBAddTable :editor="editor" class="hide-on-small-screen" :disabled="disableAllControls" />
        <TBEditTable :editor="editor" class="hide-on-small-screen" :disabled="disableAllControls" />
        <TBUndo :editor="editor" class="hide-on-small-screen" :disabled="disableAllControls" />
        <TBRedo :editor="editor" class="hide-on-small-screen" :disabled="disableAllControls" />
        <TBIndent :editor="editor" class="hide-on-small-screen" :disabled="disableAllControls" />
        <TBOutdent :editor="editor" class="hide-on-small-screen" :disabled="disableAllControls" />

        <TBPlaceholderButton v-if="textPlaceholderOptions && textPlaceholderOptions.length"
          v-model="isPlaceholderVisible" :disabled="disableAllControls" />

        <TBPlaceholderOptions v-if="textPlaceholderOptions" :editor="editor"
          :textPlaceholderOptions="textPlaceholderOptions" :isPlaceholderVisible="isPlaceholderVisible && !disabled" @hide="isPlaceholderVisible = false"/>
      </template>

      <!-- <TBUndoShortcut :editor="editor" :disabled="disableAllControls" /> -->

      <template v-if="showFontParams">
        <TBFontSize :editor="editor" class="hide-on-small-screen" :disabled="disableAllControls" />
        <TBFontFamily :editor="editor" class="hide-on-small-screen" :disabled="disableAllControls" />
        <TBFontHeight :editor="editor" class="hide-on-small-screen" :disabled="disableAllControls" />
        <TBFontColor :editor="editor" class="hide-on-small-screen" :disabled="disableAllControls" />
      </template>

      <slot name="controlsMixInline" :editor="editor"></slot>
    </div>
    <div class="html-editor__menu layout__negative-margin--8">
      <TBPreviewerToggle v-if="isPreviewButton" v-model="isPreviewVisible" :disabled="isPreviewButtonDisabled"
        @input="handleTogglePreview" />

      <template v-if="isTest && false">
        <TBLayout :editor="editor" class="hide-on-small-screen" :disabled="disableAllControls" />
      </template>
      <slot name="controls" :editor="editor"></slot>
    </div>
    <div>
      <slot name="controlsLine" :editor="editor"></slot>
    </div>

    <!---<div class="html-editor__content mt-3" v-show="isPreviewVisible !== true">
      <editor-content ref="theEditor" :editor="editor" />
    </div>
    <template v-if="isValidationConfigured()">
      <div
        class="input-forms__errors-container"
        :key="validation.updated"
        v-if="!suppressValidationMessage && validation"
      >
        <div
          class="fc-form-danger"
          v-for="error in validation.getErrors(
            this.validationPath,
            this.validateUntouched
          )"
          :key="error"
        >
          {{ error }}
        </div>
      </div>
    </template>
    <div class="mt-3" v-if="isPreviewVisible === true"> TODO: (Alex Führer) Check if master way is better--->
    <div class="html-editor__content mt-3" v-show="!isPreviewVisible">
      <editor-content ref="theEditor" :editor="editor" />
    </div>
    <template v-if="editorEmpty && showEmptyValidation">
      <div class="input-forms__errors-container">
        <div class="fc-form-danger">
          {{ emptyValidationMessage }}
        </div>
      </div>
    </template>
    <div class="mt-3" v-if="isPreviewVisible">
      <div v-if="previewHTML" class="html-editor__preview html-editor__paragraph--clean-margin" v-html="previewHTML">
      </div>
    </div>
    <template v-if="cssStyle">
      <VStyle>
        {{ cssStyle }}
      </VStyle>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import BRIDGE_TYPES from "@/store/bridge/types";
import { Editor, EditorContent } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import { TextStyleFontSize } from "./custom-extensions/TextStyleFontSize";
import { LineHeight } from "./custom-extensions/LineHeight";
import Underline from "@tiptap/extension-underline";
import TextAlign from "@tiptap/extension-text-align";
import FontFamily from "@tiptap/extension-font-family";
import Link from "@tiptap/extension-link";
import Placeholder from "@tiptap/extension-placeholder";
import { CustomImage } from "./custom-extensions/CustomImage";
import { CustomTable } from "./custom-extensions/CustomTable";
import { Color } from "@tiptap/extension-color";
import TableRow from "@tiptap/extension-table-row";
import VStyle from "@/components/core/VStyle.vue";

import TBBold from "./toolbar-components/TBBold.vue";
import TBItalic from "./toolbar-components/TBItalic.vue";
import TBUnderline from "./toolbar-components/TBUnderline.vue";

import TBTextAlignCenter from "./toolbar-components/TBTextAlignCenter.vue";
import TBTextAlignJustify from "./toolbar-components/TBTextAlignJustify.vue";
import TBTextAlignLeft from "./toolbar-components/TBTextAlignLeft.vue";
import TBTextAlignRight from "./toolbar-components/TBTextAlignRight.vue";

import TBUnorderedList from "./toolbar-components/TBUnorderedList.vue";
import TBOrderedList from "./toolbar-components/TBOrderedList.vue";
import TBHorizontalRule from "./toolbar-components/TBHorizontalRule.vue";
import TBImage from "./toolbar-components/TBImage.vue";
import TBImageEdit from "./toolbar-components/TBImageEdit.vue";
import TBLink from "./toolbar-components/TBLink.vue";
import TBUnlink from "./toolbar-components/TBUnlink.vue";

import TBUndo from "./toolbar-components/TBUndo.vue";
import TBRedo from "./toolbar-components/TBRedo.vue";

import TBUndoShortcut from "./toolbar-components/TBUndoShortcut.vue";

import TBFontSize from "./toolbar-components/TBFontSize.vue";
import TBFontFamily from "./toolbar-components/TBFontFamily.vue";
import TBFontHeight from "./toolbar-components/TBFontHeight.vue";
import TBFontColor from "./toolbar-components/TBFontColor.vue";
import TBOutdent from "./toolbar-components/layout/TBOutdent.vue";
import TBIndent from "./toolbar-components/layout/TBIndent.vue";
import { Indent } from "./custom-extensions/TextIndent";

import TBPlaceholderButton from "./toolbar-components/placeholder/TBPlaceholderButton.vue";
import TBPlaceholderOptions from "./toolbar-components/placeholder/TBPlaceholderOptions.vue";
import TBPreviewerToggle from "./toolbar-components/previewer/TBPreviewerToggle.vue";
import TBLayout from "./toolbar-components/layout/TBLayout.vue";

import TBAddTable from "./toolbar-components/table/TBAddTable.vue";
import TBEditTable from "./toolbar-components/table/TBEditTable.vue";
import { CustomTableHeader } from "./custom-extensions/CustomTableHeader";
import { CustomTableCell } from "./custom-extensions/CustomTableCell";
import { MarkdownShortcuts } from "./custom-extensions/MarkdownShortcuts";
import { HtmlEditorContentAdapter } from "./adapter/HtmlEditorContentAdapter";
import CORE_TYPES from "@/store/core/types";
import TEMPLATES_EDIT_TYPES from "@/store/templatesEdit/types";
import validatorComponentUtils from "@/mixins/validator/validator-component-utils";

export default {
  mixins: [validatorComponentUtils],
  components: {
    EditorContent,
    VStyle,
    TBBold,
    TBFontSize,
    TBFontFamily,
    TBFontHeight,
    TBFontColor,
    TBItalic,
    TBUnderline,
    TBTextAlignCenter,
    TBTextAlignJustify,
    TBTextAlignLeft,
    TBTextAlignRight,
    TBUnorderedList,
    TBOrderedList,
    TBHorizontalRule,
    TBImage,
    TBImageEdit,
    TBLink,
    TBUnlink,
    TBUndo,
    TBRedo,
    TBUndoShortcut,
    TBPlaceholderButton,
    TBPlaceholderOptions,
    TBPreviewerToggle,

    TBEditTable,
    TBAddTable,
    TBOutdent,
    TBIndent,
  },

  props: {
    value: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "Text einfügen",
    },
    isFontParams: {
      type: Boolean,
      default: false,
    },
    isSimplified: {
      type: Boolean,
      default: false,
    },
    showEmptyValidation: {
      type: Boolean,
      default: false,
    },
    emptyValidationMessage: {
      type: String,
      default: 'Nachricht ist erforderlich',
    },
    isSmallTextarea: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    textPlaceholderOptions: {
      type: Array,
      default: null,
    },
    previewHTML: {
      type: String,
      default: "",
    },
    isPreviewButtonDisabled: {
      type: Boolean,
      default: false,
    },
    isPreviewButton: {
      type: Boolean,
      default: false,
    },
    applySavedSettings: {
      type: Boolean,
      default: false,
    },
    doNotAppendHtmlBody: {
      type: Boolean,
      default: false,
    },
    validateUntouched: {
      type: Boolean,
      default: false,
    },
    suppressValidationMessage: {
      type: Boolean,
      default: false,
    },
    startEditorFocused: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters({
      isMobileNativeContext: BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT,
      isTest: CORE_TYPES.GETTERS.IS_TEST,
      htmlShortcuts: TEMPLATES_EDIT_TYPES.GETTERS.HTML_SHORTCUTS,
      isCustomerOnly: CORE_TYPES.GETTERS.IS_CUSTOMER_ONLY,
      isGesellschaft: CORE_TYPES.GETTERS.IS_GESELLSCHAFT,
    }),
    disableAllControls() {
      return this.disabled || this.isPreviewVisible;
    },
    showMoreToolbarControllers() {
      return !this.isSimplified && !this.isMobileNativeContext;
    },
    showFontParams() {
      return this.isFontParams && !this.isMobileNativeContext;
    },
    unwrappedValue() {
      return this.adapter?.unwrapContent(this.value);
    },
    wrappedHTML() {
      return this.adapter?.wrapContent(this.editor ? this.editor.getHTML() : this.value);
    },
    shortcutsEnabled() {
      return !this.isCustomerOnly && !this.isGesellschaft
    },
  },

  watch: {
    value(value) {
      if (!this.editor || !this.adapter) {
        return;
      }

      const content = this.adapter.unwrapContent(value);
      const isSame = this.editor.getHTML() === content;

      if (!this.adapter.htmlHasBodyContent(value)) {
        this.$nextTick(() => this.$emit("input", this.wrappedHTML));
      }

      this.$runValidator(content);
      if (this.isValidationConfigured()) {
        this.$emit(
          "isEditorValid",
          this.validation?.isValid(this.validationPath, this.validateUntouched)
        );
      }

      if (isSame) {
        return;
      }

      this.editor.commands.setContent(content, false);
    },

    disabled(value) {
      if (!this.editor || !this.adapter) {
        return;
      }
      
      this.editor.setEditable(!value);
    },
    htmlShortcuts(shortcuts) {
      this.initEditor();
    },
  },

  data() {
    return {
      editor: null,
      isPlaceholderVisible: false,
      isPreviewVisible: false,
      adapter: null,
      cssStyle: "",
      editorEmpty: true,
      isEditorInited: false,
    };
  },

  methods: {
    initEditor(force = false) {
      if (
        this.isEditorInited ||
        (!force && !this.htmlShortcuts && !this.isCustomerOnly)
      ) {
        return;
      }
      this.editor = new Editor({
        editable: !this.disabled,
        content: this.unwrappedValue,
        parseOptions: {
          preserveWhitespace: false,
        },
        editorProps: {
          attributes: {
            class:
              "html-editor__text html-editor__paragraph--clean-margin " +
              (this.isSmallTextarea
                ? "html-editor__text--small"
                : "html-editor__text--large"),

            applySavedSettings: this.applySavedSettings,
          },
        },
        onTransaction: ({ editor }) => {
          const cursoPosition = editor.view.state.selection.$anchor.pos;
          this.$emit("cursorPosition", cursoPosition);
        },
        extensions: [
          StarterKit,
          Underline,
          TextStyleFontSize,
          LineHeight,
          FontFamily,
          Link,
          Color,
          CustomImage.configure({
            allowBase64: true,
            inline: true,
          }),
          Placeholder.configure({
            placeholder: this.placeholder,
            emptyEditorClass: "is-editor-empty",
          }),
          TextAlign.configure({
            types: ["heading", "paragraph"],
          }),
          CustomTable.configure({
            resizable: true,
          }),
          TableRow,
          CustomTableHeader,
          CustomTableCell,
          ...(!this.isCustomerOnly
            ? [MarkdownShortcuts(this.htmlShortcuts)]
            : []),
          //Indent,
        ],
        onUpdate: () => {
          if (
            this.isValidationConfigured() &&
            !this.validation?.isDirty(this.validationPath)
          ) {
            this.$setDirty();
          }
          this.$emit("input", this.wrappedHTML);
          this.$emit("change", this.wrappedHTML);
        },
        onBlur: () => {
          this.$setTouched();
        },
      });

      if (this.startEditorFocused) {
        this.editor.commands.focus();
      }

      this.$emit("editor", this.editor);

      this.isEditorInited = true;
    },
    handleTogglePreview(value) {
      const event = value
        ? "onTogglePreviewEnabled"
        : "onTogglePreviewDisabled";
      this.$emit(event, value);

      if (value) {
        this.isPlaceholderVisible = false;
      }
    },
  },

  mounted() {
    if (this.shortcutsEnabled) {
      this.$store.dispatch(TEMPLATES_EDIT_TYPES.ACTIONS.GET_HTML_SHORTCUTS);
    }
    this.adapter = new HtmlEditorContentAdapter({
      doNotAppendHtmlBody: this.doNotAppendHtmlBody,
      applySavedSettings: this.applySavedSettings,
      dataCallback: (data) => {
        for (const key in data) {
          this.$set(this, key, data[key]);
        }

        // after mounted, trigger the wrappedHTML to update the savedConfigs
        this.$emit("input", this.wrappedHTML);
      },
    });

    this.$runValidator(this.unwrappedValue, true);
    this.initEditor();
  },

  beforeDestroy() {
    this.editor.destroy();
  },
};
</script>

<style lang="scss">
/* General style */

.html-toolbar__button--active {
  color: var(--color-primary);
}

.html-editor__text {
  border: 1px solid;
  border-radius: 4px;
  border-color: var(--color-text);
  padding: 10px;
}

.html-editor__text[contenteditable="false"] {
  opacity: 0.4;
}

.html-editor__text--small {
  min-height: 3em;
}

.html-editor__text--large {
  min-height: 15em;
}

.html-editor__headers-label--bold {
  font-weight: 700;
}

/* remove paragraph margin only inside tables 
because this is what email readers do */
.html-editor__paragraph--clean-margin tbody p {
  margin: 0;
}

hr.ProseMirror-selectednode {
  border-top: 2px solid var(--color-primary);
  cursor: pointer;
}

img.ProseMirror-selectednode {
  outline: 2px solid var(--color-primary);
  cursor: pointer;
}

/* .is-empty, .is-editor-empty {
 // Add style when the editor is empty
} */

.html-editor__content p {
  margin: 0px;
}

.html-editor__preview p {
  margin: 0px;
}

</style>

<style lang="scss">
/* table style */
.ProseMirror {
  table {
    border-collapse: collapse;
    table-layout: fixed;
    margin: 0;
    overflow: hidden;

    td,
    th {
      min-width: 1em;
      border: 2px solid #ced4da;
      padding: 3px 5px;
      vertical-align: top;
      box-sizing: border-box;
      position: relative;

      >* {
        margin-bottom: 0;
      }
    }

    th {
      font-weight: bold;
      text-align: left;
      background-color: #f1f3f5;
    }

    .selectedCell:after {
      z-index: 2;
      position: absolute;
      content: "";
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(200, 200, 255, 0.4);
      pointer-events: none;
    }

    .column-resize-handle {
      position: absolute;
      right: -2px;
      top: 0;
      bottom: -2px;
      width: 4px;
      background-color: #adf;
      pointer-events: none;
    }

    p {
      margin: 0;
    }
  }
}

.tableWrapper {
  overflow-x: auto;

  table {
    th {
      text-align: left;
    }
  }
}

.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}
</style>

<style lang="scss" scoped>
.html-editor__menu {
  display: flex;
  align-items: center;
  cursor: pointer;
  flex-wrap: wrap;

  >* {
    flex: 0 0 auto;
  }
}

.html-editor__preview {
  border: 1px solid;
  border-radius: 4px;
  border-color: var(--color-text);
  padding: 10px;
}
</style>
