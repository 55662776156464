<template>
  <div class="footer">
    <div v-if="isInternalRemark"><b>Interne Bemerkung - nur f&uuml;r Sie selbst sichtbar</b></div>
    <div class="d-flex" style="gap: 8px" >
      <div class="chat-editor">
        <HtmlEditor v-if="richText" v-model="textInput" isFontParams isPreviewButton suppressValidationMessage
          startEditorFocused validateUntouched class="text-input" :textPlaceholderOptions="placeholdersOptions"
          :previewHTML="previewHtml.text" :disabled="isSending" @onTogglePreviewEnabled="handlePreview" @change="onChange"
          @isEditorValid="setEditorValid" />
      </div>
      <div class="chat-footer__send-button-container" v-if="richText && isSmallScreen || (richText && embedded)">
        <div>
          <BaseButton class="m-0" @click="onSendChat()" :disabled="!isEditorValid">
            <PhPaperPlaneRight :size="18" />
          </BaseButton>
        </div>
      </div>
    </div>

    <div v-if="(chatInfo && chatInfo.readonly) || disabled || chatId == 0"
      class="fc-alert fc-alert-primary mt-3 mb-0 text-centered text-small">
      Es muss zuerst ein Text erfasst und gesendet werden, bevor ein Dokument hinzugefügt werden kann.
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import WEBRTC_TYPES from "@/store/webrtc/types";
import LOG_TYPES from "@/store/log/types";
import CORE_TYPES from "@/store/core/types";
import HtmlEditor from '@/components/html-editor/HtmlEditor.vue';
import MAILCOMPOSER_TYPES from "@/store/mailcomposer/types";
import COMMUNICATION_TYPES from "@/store/communication/types";
import EVENT_BUS, { CHAT_MESSAGE_SENT, CHAT_SEND_MESSAGE, CHAT_ADD_DOCUMENT } from '@/event-bus';
import validator from "@/mixins/validator";
import { htmlEditorRequired } from "@/mixins/validator/rules";
import BaseButton from "@/components/core/BaseButton.vue";
import { PhPaperPlaneRight } from "phosphor-vue";

export default {
  mixins: [validator],
  components: {
    HtmlEditor,
    BaseButton,
    PhPaperPlaneRight,
  },
  props: {
    chatId: {
      type: [Number, String],
    },
    channelId: {
      type: [Number, String],
    },
    beteiligterId: {
      default: null,
    },
    emailId: {
      default: null,
    },
    disabled: {
      default: false,
    },
    thema: {
      default: null,
    },    
    fmaBeschwerde: {
      default: null,
    },
    bezugId: {
      default: null,
    },
    initialText: {
      default: "",
    },
    richTextInit: {
      default: true,
    },
    embedded: {
      type: Boolean,
      default: false,
    },
    isInternalRemark: {
      type: Boolean,
      default: false,
    }
  },
  validators: {
    textInput: [htmlEditorRequired()]
  },
  data() {
    return {
      textInput: this.initialText,
      initialContent: this.initialText,
      richText: true,
      isEditorValid: false,
      isSending: false,
    };
  },
  computed: {
    ...mapGetters({
      chatInfos: WEBRTC_TYPES.GETTERS.CHAT_INFO,
      placeholders: MAILCOMPOSER_TYPES.GETTERS.GET_PLACEHOLDERS,
      previewHtml: MAILCOMPOSER_TYPES.GETTERS.GET_PREVIEW,
      senders: MAILCOMPOSER_TYPES.GETTERS.GET_SENDERS,
      isSmallScreen: CORE_TYPES.GETTERS.IS_SMALL_SCREEN,
      unsentNachricht: COMMUNICATION_TYPES.GETTERS.UNSENT_NACHRICHT,
    }),
    chatInfo() {
      return this.chatInfos.find(
        (chatInfo) =>
          chatInfo.chatId == this.chatId && chatInfo.channelId == this.channelId
      );
    },
    placeholdersOptions() {
      const keys = this.placeholders && Object.keys(this.placeholders);
      return (
        (this.placeholders &&
          Object.values(this.placeholders).map((v, i) => ({
            name: keys[i],
            data: v.map((p) => ({
              value: Object.values(p)[0],
              label: Object.keys(p)[0],
            })),
          }))) ||
        []
      );
    },
  },
  mounted() {
    this.registerEventBus();
    this.initialContent = this.initialText;
    this.richText = true;
    this.textInput = this.unsentNachricht ? this.unsentNachricht : this.initialText;
    this.$store.dispatch(MAILCOMPOSER_TYPES.ACTIONS.GET_SENDERS);
    this.$store.dispatch(MAILCOMPOSER_TYPES.ACTIONS.GET_PLACEHOLDERS, {});
  },
  destroyed() {

    this.unregisterEventBus();
  },
  watch: {
    richTextInit(value) {
      this.textInput = this.initialText;
      this.richText = value;
    },
    initialText(value) {
      this.textInput = value;
    },
    unsentNachricht(value) {
      this.textInput = value;
    },
  },
  methods: {
    setEditorValid(isEditorValid) {
      this.isEditorValid = isEditorValid;
      this.$emit('isEditorValid', isEditorValid)
    },
    registerEventBus() {
      // Send message
      EVENT_BUS.$on(CHAT_SEND_MESSAGE, () => {
        this.onSendChat()
      });

      // Upload Document
      EVENT_BUS.$on(CHAT_ADD_DOCUMENT, (files) => {
        this.onUploadChat(files)
      });
    },
    unregisterEventBus() {
      EVENT_BUS.$off(CHAT_SEND_MESSAGE);
      EVENT_BUS.$off(CHAT_ADD_DOCUMENT);
    },
    toggleInput() {
      this.richText = !this.richText;
    },
    onSendChat() {
      let message = this.textInput;
      if (!this.richText) message = message.replaceAll("\n", "<br>");
      this.$store.dispatch(
        LOG_TYPES.ACTIONS.INFO,
        `sending chat message ${message}`
      );
      if (message.length > 0) {
        this.isSending = true

        this.handlePreview(true, false).then(() => {
          this.$store
            .dispatch(WEBRTC_TYPES.ACTIONS.SEND_CHAT_MESSAGE, {
              beteiligterId: this.beteiligterId,
              chatId: this.chatId,
              channelId: this.channelId,
              emailId: this.emailId,
              message: this.previewHtml.text || message,
              thema: this.thema,
              bezugId: this.bezugId,
              fmaBeschwerde: this.fmaBeschwerde,
              isInternalRemark: this.isInternalRemark,
            })
            .then(({ chatId, channelId }) => {
              this.$store.commit(COMMUNICATION_TYPES.MUTATIONS.SAVE_UNSENT_NACHRICHT,"");
              this.textInput = "";
              this.richText = false;
              this.$nextTick(() => {
                this.richText = true;
                this.setEditorValid(false);
              });

              if (
                chatId &&
                channelId &&
                (this.chatId != chatId || this.channelId != channelId)
              ) {
                this.$emit("changeChannel", { chatId, channelId });
              };
              EVENT_BUS.$emit(CHAT_MESSAGE_SENT);
            }).finaly(() => {
              this.isSending = false
            })
        }).catch(() => {
          this.isSending = false //Also reenable html editor in case of preview error
        })
      }
    },
    onUploadChat(files) {
      this.$store.dispatch(LOG_TYPES.ACTIONS.INFO, {
        message: "onUploadChat",
        files,
      });
      if (files && files.length) {
        files.forEach((file) => {
          if (!file.id) {
            this.$store
              .dispatch(WEBRTC_TYPES.ACTIONS.UPLOAD_CHAT, {
                chatId: this.chatId,
                channelId: this.channelId,
                file,
              })
              .then(({ chatId, channelId }) => {
                if (
                  chatId &&
                  channelId &&
                  (this.chatId != chatId || this.channelId != channelId)
                ) {
                  this.$emit("changeChannel", { chatId, channelId });
                }
              });
          } else {
            this.$store
              .dispatch(WEBRTC_TYPES.ACTIONS.ADD_FILE_CHAT, {
                chatId: this.chatId,
                channelId: this.channelId,
                id: file.id,
              })
              .then(({ chatId, channelId }) => {
                if (
                  chatId &&
                  channelId &&
                  (this.chatId != chatId || this.channelId != channelId)
                ) {
                  this.$emit("changeChannel", { chatId, channelId });
                }
              });
          }
        })
      }
    },
    handlePreview(value, setValue = true) {
      if (setValue) {
        this.isPreview = value;
        this.initialContent = this.textInput;
      }
      if (value) {
        const senders = this.senders.filter((v) => v.email === this.sender);
        const sender =
          (senders && senders[0]) || (this.senders && this.senders[0]);
        return this.$store.dispatch(MAILCOMPOSER_TYPES.ACTIONS.GET_PREVIEW, {
          briefInput: {
            html: this.textInput,
            vorlageTyp: "BRIEF",
            saveInArchive: false,
            queryFormat: false,
            hintergruend: false,
            worldId: null,
            fileName: '1',
            subject: '',
          },
          subject: '',
          text: this.textInput,
          modus: 'ONE_MAIL',
          confidential: false,
          userTypeSender: sender?.user?.type,
          userIdSender: sender?.user?.userId,
          emailSender: sender?.email,
          paramTextAlredyIncluded: true,
          participants: [],
        });
      } else {
        return Promise.resolve();
      }
    },
    onChange(){
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.$store.commit(
        COMMUNICATION_TYPES.MUTATIONS.SAVE_UNSENT_NACHRICHT,
        this.textInput
      );
      }, 2000)
      this.$emit('typing')
    }
  },
};
</script>

<style>
.chat-editor .ProseMirror {
  overflow: auto;
  border: 1px solid;
  border-radius: 4px;
  border-color: var(--color-text);
  padding: 10px;
}

.chat-editor {
  flex: 1;
}

.chat-editor .markup_editor_container {
  display: flex;
}

.chat-editor .editor {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.chat-editor .editor__content {
  /* flex: 0.8; */
}
</style>
<style scoped>
.footer {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.text-input {
  overflow: hidden;
  flex-grow: 1;
  flex-shrink: 100;
  padding-bottom: 0;
  margin-bottom: 0;
}

.chat-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

#file-upload-input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  z-index: -1;
  position: absolute;
}

.button__container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.nowrap {
  white-space: nowrap;
  padding: 0;
}

.placeholders {
  display: flex;
  width: 100%;
}

.placeholder-control {
  cursor: pointer;
  line-height: 1.5;
}

.input-forms-p .input-forms__container {
  padding-bottom: 0;
}

.chat-footer__send-button-container {
  display: flex;
  align-items: flex-end;
}
</style>
