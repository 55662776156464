import MAILCOMPOSER_TYPES from './types';
import LOG_TYPES from '@/store/log/types';
import CORE_TYPES from '@/store/core/types';
import axios from 'axios';
import { buildMessage } from '../../helpers/log-message-helper';
import { makeQueryParam } from '@/helpers/utils-helper';

const COMMUNICATION_MESSAGES_TIMEOUT = 20000

export default {
    [MAILCOMPOSER_TYPES.ACTIONS.GET_SENDERS]({ commit, rootState }, payload) {
        const config = {
            defaultSpinner: true
        };
        axios.get(rootState.core.apiAddress + '/mailComposer/senders', config).then(response => {
            commit(MAILCOMPOSER_TYPES.MUTATIONS.GET_SENDERS, response.data);
        });
    },
    [MAILCOMPOSER_TYPES.ACTIONS.TEST_SENDERS]({ commit, rootState }, payload) {
        const config = {
            defaultSpinner: true
        };
        axios.get(rootState.core.apiAddress + '/mailComposer/testsender', config).then(response => {
            commit(MAILCOMPOSER_TYPES.MUTATIONS.TEST_SENDERS, response.data);
        });
    },
    [MAILCOMPOSER_TYPES.ACTIONS.GET_PLACEHOLDERS]({ commit, rootState }, payload) {
        const config = {
            defaultSpinner: true,
            params: payload
        };
        axios.get(rootState.core.apiAddress + '/mailComposer/placeholders', config).then(response => {
            commit(MAILCOMPOSER_TYPES.MUTATIONS.GET_PLACEHOLDERS, response.data);
        });
    },
    [MAILCOMPOSER_TYPES.ACTIONS.GET_HTML_TEMPLATES]({ commit, rootState }, payload = 'EMAIL') {
        const config = {
            defaultSpinner: true,
            ...(payload ? {
                params: {
                    vorlageTyp: payload
                }
            } : {})
        };
        axios.get(rootState.core.apiAddress + '/mailComposer/HtmlTemplates', config).then(response => {
            commit(MAILCOMPOSER_TYPES.MUTATIONS.GET_HTML_TEMPLATES, {data: response.data, type: payload});
        });
    },
    [MAILCOMPOSER_TYPES.ACTIONS.WORD_TEMPLATES]({ commit, rootState }, payload) {
        const config = {
            defaultSpinner: true
        };
        axios.get(rootState.core.apiAddress + '/mailComposer/WorldTemplates', config).then(response => {
            commit(MAILCOMPOSER_TYPES.MUTATIONS.WORD_TEMPLATES, response.data);
        });
    },
    [MAILCOMPOSER_TYPES.ACTIONS.GET_HTML_TEMPLATE]({ commit, rootState }, payload) {
        const config = {
            defaultSpinner: true,
            params: {
                templateId: `${payload}`
            }
        };
        axios.get(rootState.core.apiAddress + '/mailComposer/HtmlTemplate', config).then(response => {
            commit(MAILCOMPOSER_TYPES.MUTATIONS.GET_HTML_TEMPLATE, response.data);
        });
    },
    [MAILCOMPOSER_TYPES.ACTIONS.GET_WORD_TEMPLATE]({ commit, rootState }, payload) {
        const config = {
            defaultSpinner: true,
            params: {
                templateId: `${payload}`
            }
        };

        commit(MAILCOMPOSER_TYPES.MUTATIONS.GET_WORD_TEMPLATE, {});
        axios.get(rootState.core.apiAddress + '/mailComposer/WordTemplate', config).then(response => {
            commit(MAILCOMPOSER_TYPES.MUTATIONS.GET_WORD_TEMPLATE, response.data);
        });
    },
    [MAILCOMPOSER_TYPES.ACTIONS.GET_HTML_TEXT]({ commit, rootState }, payload) {
        const config = {
            defaultSpinner: true,
            params: {
                templateId: `${payload}`
            }
        };
        axios.get(rootState.core.apiAddress + '/mailComposer/HtmlText', config).then(response => {
            commit(MAILCOMPOSER_TYPES.MUTATIONS.GET_HTML_TEXT, response.data);
        });
    },
    [MAILCOMPOSER_TYPES.ACTIONS.GET_PREVIEW]({ commit, rootState }, payload) {
        const config = {
            defaultSpinner: true,
        };
        commit(MAILCOMPOSER_TYPES.MUTATIONS.GET_PREVIEW, '');
        const data = payload
        data.text = data.text.replace(/'/g, '"')
        data.subject = data.subject.replace(/'/g, '"')
        return axios.post(rootState.core.apiAddress + '/mailComposer/preview', data, config).then(response => {
            commit(MAILCOMPOSER_TYPES.MUTATIONS.GET_PREVIEW, response.data);
            return response
        });
    },
    [MAILCOMPOSER_TYPES.ACTIONS.SEND_MAIL]({ commit, rootState, dispatch  }, payload) {
        const config = {
            defaultSpinner: true,
        };
        axios.post(rootState.core.apiAddress + '/mailComposer/sendMail', payload, config).then(response => {
            if (response.data.errors && Object.values(response.data.errors).length) {
                if (!payload.ignoreError) {
                    commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_ERROR, Object.keys(response.data.errors).map(k => `${k}: ${response.data.errors[k]}`));
                } else {
                    Object.values(response.data.errors).map(e => dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(e?.length ? e.join(' ') : e, 'danger', true, COMMUNICATION_MESSAGES_TIMEOUT)));
                }
            }
            if (response.data.message) {
                dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.data.message, 'primary', true, COMMUNICATION_MESSAGES_TIMEOUT))
            }
            commit(MAILCOMPOSER_TYPES.MUTATIONS.SEND_MAIL, response.data);
        });
    },
    [MAILCOMPOSER_TYPES.ACTIONS.SEND_TEST_MAIL]({ commit, rootState, dispatch }, payload) {
        const config = {
            defaultSpinner: true,
        };
        axios.post(rootState.core.apiAddress + '/mailComposer/sendMail', payload, config).then(response => {
            if (response.data.errors && Object.values(response.data.errors).length) {
                Object.values(response.data.errors).map(e => dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(e, 'danger', true, COMMUNICATION_MESSAGES_TIMEOUT)));
            }
            if (response.data.message) {
                dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.data.message, true, COMMUNICATION_MESSAGES_TIMEOUT))
            }
            commit(MAILCOMPOSER_TYPES.MUTATIONS.SEND_TEST_MAIL, response.data);

            this.$router.push(`/communication/postfach/overview`);
        });
    },
    [MAILCOMPOSER_TYPES.ACTIONS.APPZUSENDEN]({ commit, rootState }, payload) {
        const config = {
            defaultSpinner: true,
        };
        axios.get(rootState.core.apiAddress + '/mailComposer/kundenappzusendendaten', config).then(response => {
            const value = response.data
            if (value && value.html) {
                value.html = value.html
                    .replace('<html>', '').replace('</html>', '')
                    .replace('<body>', '').replace('</body>', '')
            }
            commit(MAILCOMPOSER_TYPES.MUTATIONS.APPZUSENDEN, value);
        });
    },
    [MAILCOMPOSER_TYPES.ACTIONS.SEND_FAX]({ commit, rootState, dispatch  }, payload) {
        const config = {
            defaultSpinner: true,
        };
        axios.post(rootState.core.apiAddress + '/mailComposer/sendFax', payload, config).then(response => {
            if (response.data.errors && Object.values(response.data.errors).length) {
                for (const [key, value] of Object.entries(response.data.errors)) {
                    dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(`${key}: ${value}`, 'danger', true, COMMUNICATION_MESSAGES_TIMEOUT))
                  }
            }
            if (response.data.message) {
                dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.data.message, true, COMMUNICATION_MESSAGES_TIMEOUT))
            }
            commit(MAILCOMPOSER_TYPES.MUTATIONS.SEND_FAX, response.data);
        });
    },
    [MAILCOMPOSER_TYPES.ACTIONS.SEND_BRIEF]({ commit, rootState, dispatch  }, payload) {
        const config = {
            defaultSpinner: true,
            responseType: 'arraybuffer'
        };
        axios.post(rootState.core.apiAddress + '/mailComposer/sendBrief', payload, config).then(response => {
            if (response.data.errors && Object.values(response.data.errors).length) {
                Object.values(response.data.errors).map(e => dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(e, 'danger', true, COMMUNICATION_MESSAGES_TIMEOUT)));
            }
            if (response.data.message) {
                dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.data.message, true, COMMUNICATION_MESSAGES_TIMEOUT))
            }
            commit(MAILCOMPOSER_TYPES.MUTATIONS.SEND_BRIEF, response.data);
        }).catch(error => {
            if(error.response.status === 404) {
                const decoder = new TextDecoder("utf-8");
                const unsetPlaceholders = decoder.decode(error.response?.data).split(',');
                commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_UNSET_PLACEHOLDERS, unsetPlaceholders);
                dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage("Die folgenden Platzhalter konnten nicht gesetzt werden: " + unsetPlaceholders.join(','), 'danger',
                 true, COMMUNICATION_MESSAGES_TIMEOUT))
            }
        });
    },

    [MAILCOMPOSER_TYPES.ACTIONS.GET_INIT]({ commit, rootState }, payload) {
        return new Promise((resolve, reject) => {
            const params = {
                defaultSpinner: true,
                params: payload
            };
            axios.get(rootState.core.apiAddress + '/mailComposer/init', params).then(response => {
                commit(MAILCOMPOSER_TYPES.MUTATIONS.GET_INIT, response.data);
                resolve(response);
            });

        });
    },
   async [MAILCOMPOSER_TYPES.ACTIONS.INIT_CANCEL_INSURANCE]({ commit, rootState }, payload) {
        const config = {
            defaultSpinner: true
        };
        await axios.post(rootState.core.apiAddress + '/mailComposer/initKuendiegungsMail', payload, config).then(response => {
          //  commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_ATTACHMENTS, {action: 'clear-cache'});
            commit(MAILCOMPOSER_TYPES.MUTATIONS.INIT_CANCEL_INSURANCE, response.data);
            commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_ATTACHMENTS, response.data.attachments);
        });
    },

    async [MAILCOMPOSER_TYPES.ACTIONS.INIT_CANCEL_INSURANCE1]({ commit, getters,rootState }, payload) {
        const config = {
            defaultSpinner: true
        };
        await axios.get(rootState.core.apiAddress + '/mailComposer/initKuendiegungsMail1?insurance='+ payload[0], config).then(response => {
           
            commit(MAILCOMPOSER_TYPES.MUTATIONS.INIT_CANCEL_INSURANCE, response.data);
            console.log('att',getters[MAILCOMPOSER_TYPES.GETTERS.SET_ATTACHMENTS]);
            let obj= getters[MAILCOMPOSER_TYPES.GETTERS.SET_ATTACHMENTS];
            /*if(!obj||
                (obj.attachments.length==0 )){
                commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_ATTACHMENTS, response.data.attachments);
            }*/
            commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_ATTACHMENTS, response.data.attachments);
            
           
        });
    },
    [MAILCOMPOSER_TYPES.ACTIONS.INIT_MAIL_ANTRAG]({ commit, rootState }, payload) {
        const params = {
            defaultSpinner: true,
            params: payload
        };
        axios.get(rootState.core.apiAddress + '/mailComposer/initAntragMail', params).then(response => {
            commit(MAILCOMPOSER_TYPES.MUTATIONS.INIT_MAIL_ANTRAG, response.data);
        });
    },
    [MAILCOMPOSER_TYPES.ACTIONS.INIT_MAIL_ANSPRECHPARTNER]({ commit, rootState }, payload) {
        const params = {
            defaultSpinner: true,
            params: payload
        };
        axios.get(rootState.core.apiAddress + '/mailComposer/initAnsprechpartnerMail', params).then(response => {
            commit(MAILCOMPOSER_TYPES.MUTATIONS.INIT_MAIL_ANSPRECHPARTNER, response.data);
        });
    },
    [MAILCOMPOSER_TYPES.ACTIONS.INIT_MAIL_APPOINTMENT]({ commit, rootState }, payload) {
        const params = {
            defaultSpinner: true
        };
        
        let dat=payload.data;
        if(!dat){
            dat=[];
        }
        axios.post(rootState.core.apiAddress + `/calendarService/getEmailData/${payload.appointmentId}`,dat, params).then(response => {
            commit(MAILCOMPOSER_TYPES.MUTATIONS.INIT_MAIL_APPOINTMENT, {data: response.data, selectedObject: payload.data});
        });
    },
    [MAILCOMPOSER_TYPES.ACTIONS.INIT_MAIL_SCHADENSMELDUNG]({ commit, rootState }, payload) {
        return new Promise((resolve, reject) => {
            const params = {
                defaultSpinner: true,
                params: payload
            };
            axios.get(rootState.core.apiAddress + '/mailComposer/initSchadensmeldungMail', params).then(response => {
                commit(MAILCOMPOSER_TYPES.MUTATIONS.INIT_MAIL_SCHADENSMELDUNG, response.data);
                resolve(response);
            });
        });
    },
    [MAILCOMPOSER_TYPES.ACTIONS.INIT_MAIL_BERATUNGSMAPPEN]({ commit, rootState }, payload) {
        return new Promise((resolve, reject) => {
            const params = {
                defaultSpinner: true,
                params: {
                    beratungsId: payload
                }
            };
            axios.get(rootState.core.apiAddress + '/mailComposer/iniBeratungsMappetMail', params).then(response => {
                commit(MAILCOMPOSER_TYPES.MUTATIONS.INIT_MAIL_BERATUNGSMAPPEN, response.data);
            });
        });
    },
    [MAILCOMPOSER_TYPES.ACTIONS.INIT_MAIL_VERSDOKU]({ commit, rootState }, payload) {
        const params = {
            defaultSpinner: true,
            params: payload
        };

        axios.get(rootState.core.apiAddress + '/mailComposer/initVersDokuMail', params).then(response => {
            commit(MAILCOMPOSER_TYPES.MUTATIONS.INIT_MAIL_VERSDOKU, response.data);
        });
    },
    [MAILCOMPOSER_TYPES.ACTIONS.INIT_MAIL_VERS_UEBERTRAG_BLOCK_GESELL]({ commit, rootState }, payload) {
        const params = {
            defaultSpinner: true,
            params: payload
        };

        axios.get(rootState.core.apiAddress + '/mailComposer/initVersUebertragBlockGesellMail', params).then(response => {
            commit(MAILCOMPOSER_TYPES.MUTATIONS.INIT_MAIL_VERS_UEBERTRAG_BLOCK_GESELL, response.data);
        });
    },
    [MAILCOMPOSER_TYPES.ACTIONS.INIT_MAIL_ANLAGEEMPFELUNG]({ commit, rootState }, payload) {
        const params = {
            defaultSpinner: true,
            params: payload
        };
        axios.get(rootState.core.apiAddress + '/mailComposer/initAnlageempfehlungEMail', params).then(response => {
            commit(MAILCOMPOSER_TYPES.MUTATIONS.INIT_MAIL_ANLAGEEMPFELUNG, response.data);
        });
    },
    [MAILCOMPOSER_TYPES.ACTIONS.INIT_FONDSHOP_ACTION_MAIL]({ commit, rootState }, payload) {
        const params = {
            defaultSpinner: true,
            params: payload
        };


        return new Promise((resolve, reject) => {
            axios.get(rootState.core.apiAddress + '/mailComposer/initFondshopActionMail', params).then(response => {
                commit(MAILCOMPOSER_TYPES.MUTATIONS.INIT_MAIL_FONDSHOP_ACTION, response.data);
                resolve(response)
            });

          })


    },
    [MAILCOMPOSER_TYPES.ACTIONS.GET_CONSTANTS]({ commit, rootState }, payload) {
        const config = {
            defaultSpinner: true
        };
        axios.get(rootState.core.apiAddress + '/mailComposer/constants', config).then(response => {
            commit(MAILCOMPOSER_TYPES.MUTATIONS.GET_CONSTANTS, response.data);
        });
    },
    [MAILCOMPOSER_TYPES.ACTIONS.GET_MAIL_MODUS]({ commit, rootState }, payload) {
        const config = {
            defaultSpinner: true,
            params: {mailType: payload || 'OTHER'}
        };
        
        axios.get(rootState.core.apiAddress + '/mailComposer/emailModus', config).then(response => {
            commit(MAILCOMPOSER_TYPES.MUTATIONS.GET_MAIL_MODUS, response.data);
        });
    },

    [MAILCOMPOSER_TYPES.ACTIONS.SEND_BERICHT_PDF]({ commit, getters, rootState }, payload) {
        return new Promise((resolve, reject) => {
            // const token = getters[CORE_TYPES.GETTERS.GET_TOKEN];
            const input = btoa(JSON.stringify({ concatIds: payload?.concatIds || [], antragsdatenId: payload?.antragsdatenId || ''}));
            const params = makeQueryParam({ input })

            const config = {
                defaultSpinner: true
            };
            axios.get(`${rootState.core.apiAddress}/antraegeService/getMailAuftragsDokumente?${params}`, config).then(response => {
                if(response && response.data) {
                    commit(MAILCOMPOSER_TYPES.MUTATIONS.SEND_BERICHT_PDF_SUCCESS, response.data);
                    resolve(response)
                }
            })
        })
      },

      [MAILCOMPOSER_TYPES.ACTIONS.INIT_MAIL_VERMITTLERNUMMERN]({ commit, rootState }, payload) {
          return new Promise((resolve, reject) => {
            const customCommit = payload?.customCommit;
            delete payload.customCommit;
              const params = {
                  defaultSpinner: true,
                  params: payload
              };
              axios.get(rootState.core.apiAddress + '/mailComposer/initVermittlernummernMail', params).then(response => {
                  if (!customCommit) {
                      commit(MAILCOMPOSER_TYPES.MUTATIONS.INIT_MAIL_VERMITTLERNUMMERN, response.data);
                  }
                  resolve(response);
              });
          });
      },

      async [MAILCOMPOSER_TYPES.ACTIONS.INIT_QUARTALSBERICHTE_BROKER_VIEW_EMAIL]({ commit, rootState }, payload) {
          return await new Promise((resolve, reject) => {
              const params = {
                  defaultSpinner: true,
                  params: payload
              };
              axios.get(rootState.core.apiAddress + '/mailComposer/initQuartalsberichteBrokerViewEmail', params).then(response => {
                commit(MAILCOMPOSER_TYPES.MUTATIONS.INIT_QUARTALSBERICHTE_BROKER_VIEW_EMAIL, response.data);
                resolve(response);
              })
              .catch(error => reject());
          });
      },    
      
      [MAILCOMPOSER_TYPES.ACTIONS.BESTAETIGTE_ABRECHNUNGEN_VERSCHICKEN]({ commit, rootState }, payload) {
        return new Promise((resolve, reject) => {
            const params = {
                defaultSpinner: true,
                params: payload
            };
            axios.get(rootState.core.apiAddress + '/mailComposer/bestaetigteAbrechnungenVerschicken', params).then(response => {
                commit(MAILCOMPOSER_TYPES.MUTATIONS.BESTAETIGTE_ABRECHNUNGEN_VERSCHICKEN, response?.data);
                resolve(response?.data);
            });
        }); 

    },
    async [MAILCOMPOSER_TYPES.ACTIONS.ANTRAG_CHECK_MAIL]({ commit, rootState ,state}, payload) {
        return new Promise((resolve, reject) => {
            const params = {
                defaultSpinner: true,
                params: payload
            };
             state.attachmentsCache=[];
             state.attachments=[];
             axios.get(rootState.core.apiAddress + '/mailComposer/initAntragChecks?antragCheckId='+payload, params).then(response => {
              commit(MAILCOMPOSER_TYPES.MUTATIONS.ANTRAG_CHECK_MAIL, response.data);
              resolve(response);
            });
        });
    },
    
    async [MAILCOMPOSER_TYPES.ACTIONS.ADD_VOLLMACHT]({ commit, rootState ,state}, payload) {

        commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_ATTACHMENTS,
            [payload]
          );
    },

    [MAILCOMPOSER_TYPES.ACTIONS.INIT_MAIL_VERTRAGSSPIEGEL]({ commit, rootState }, payload) {
        return new Promise((resolve, reject) => {
            const params = {
                defaultSpinner: true,
                params: {...payload}
            };
            axios.get(rootState.core.apiAddress + '/mailComposer/initVertragsspiegelMail', params).then(response => {
                commit(MAILCOMPOSER_TYPES.MUTATIONS.INIT_MAIL_VERTRAGSSPIEGEL, response.data);
                resolve(response);
            });
        });
    },
    [MAILCOMPOSER_TYPES.ACTIONS.CHAT_ATTACHMENT_TO_TEMP_FILE]({ commit, rootState }, payload){
        let attId = payload.id
        return new Promise((resolve, reject) => {
             axios.get(rootState.core.apiAddress + '/mailComposer/chatAttachmentToTempFile?attachmentId=123422222').then(response => {
              payload.tempFileId = response.data
              payload.id = response.data 
              commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_ATTACHMENTS,
                [{...payload, preloaded: true}]
              );
              resolve(response);
            });
        });
    }
};
